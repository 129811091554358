
import axios from 'axios';
import { CampaignModel, CustomerGroupModel, CustomerModel, CustomerUserModel, RouteLine, RouteModel, SegmentCustomerModel, SegmentModel, SegmentProductGroupModel, SegmentProductModel } from '../types/crm-contact';
import { getAppToken, getVendorId } from './sessions';
import { appInfo } from '../app-info';

// login isteği

const apiUrl = appInfo().url;

export async function loginRequest(userName : string, password : string, vendorId : string){
  var request = await  axios.post(apiUrl, {
        serviceId: '1515',
        vendorId: vendorId,
        userName: userName,
        password: password,
        lang: "tr",
        deviceManifacturer: "",
        deviceModel:"",
        deviceSerial:"",
        deviceOSName:"",
        deviceOSVersion:"",
      });
      return request;
}



// tüm müşteriler isteği

export async function customersRequest(ref){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '1020',
        vendorId: appVendorID,
        token: appToken,
        shopUserRef: ref
      });
      return request;
}



// müşterinin tüm kullancıları isteği

export async function customerUsersRequest(customerRef:number){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
    serviceId: "1030",
    vendorId: appVendorID,
    token: appToken,
    customerRef: customerRef,
      },);
      return request;
}


// kullanıcının tüm müşterileri isteği

export async function usersCustomersListRequest(shopUserRef:number){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
    serviceId: "1035",
    vendorId: appVendorID,
    token: appToken,
    shopUserRef: shopUserRef,
      },);
      return request.data;
}



// müşteri gruplarını getir

export async function getCustomerGroupsRequest(){
  var appVendorID = getVendorId();
  var appToken = getAppToken();
  var request = await axios.post(
    apiUrl,
    {
  serviceId: "1040",
  vendorId: appVendorID,
  token: appToken,
    },

  );
      return request;
}

// müşteri grubu ekle

export async function customerGroupUpdateRequest(customerGroup:CustomerGroupModel){
  var appVendorID = getVendorId();
  var appToken = getAppToken();
  var request = await axios.post(
    apiUrl,
    {
      serviceId: "1041",
      vendorId: appVendorID,
      token: appToken,
      customerGroupRef: customerGroup.customerGroupRef,
      productGroupRef: customerGroup.productGroupRef,
      customerActive: customerGroup.customerActive,
      customerGroupCode: customerGroup.customerGroupCode,
      customerGroupName: customerGroup.customerGroupName,
      erpFirmNr: customerGroup.erpFirmNr,
      erpBranch: customerGroup.erpBranch,
      erpWarehouse: customerGroup.erpWarehouse,
      erpSalesManCode: customerGroup.erpSalesManCode,
      comment: customerGroup.comment
    },
  );
      return request.data;
}

// müşteri grubu sil

export async function customerGroupDeleteRequest(customerGroup:CustomerGroupModel){
  var appVendorID = getVendorId();
  var appToken = getAppToken();
  var request = await axios.post(
    apiUrl,
    {
      serviceId: "1041",
      vendorId: appVendorID,
      token: appToken,
      customerGroupRef: customerGroup.customerGroupRef,
      productGroupRef: customerGroup.productGroupRef,
      customerActive: 2,
      customerGroupCode: customerGroup.customerGroupCode,
      customerGroupName: customerGroup.customerGroupName,
      erpFirmNr: customerGroup.erpFirmNr,
      erpBranch: customerGroup.erpBranch,
      erpWarehouse: customerGroup.erpWarehouse,
      erpSalesManCode: customerGroup.erpSalesManCode,
      comment: customerGroup.comment
    },
  );
      return request.data;
}


// müşteri güncelle

export async function customerUpdateRequest(
  customerRef:number,
  customerActive:number,
  customerCode:string,
  customerName:string,
  customerGroupRef:number,
  erpCustomerRef:number,
  erpShippingAdrRef:number,
  productGroupRef: number
) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();

  var request =  await axios.post(
    apiUrl,
    {
  serviceId: "1021",
  vendorId: appVendorID,
  token: appToken,
  customerRef: customerRef,
  customerActive: customerActive,
  customerCode: customerCode,
  customerName: customerName,
  customerGroupRef: customerGroupRef,
  erpCustomerRef: erpCustomerRef,
  erpShippingAdrRef: erpShippingAdrRef,
  productGroupRef: productGroupRef
    },
  );
  return request.data;
}


// müşteri toplu güncelle güncelle

export async function customerUpdateStatusRequest(customer:CustomerModel,status) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();

  var request =  await axios.post(
    apiUrl,
    {
  serviceId: "1021",
  vendorId: appVendorID,
  token: appToken,
  customerRef: customer.customerRef,
  customerActive: status,
  customerCode: customer.customerCode,
  customerName: customer.customerName,
  customerGroupRef: customer.customerGroupRef,
  erpCustomerRef: customer.erpCustomerRef,
  erpShippingAdrRef: customer.erpShippingAdrRef,
  productGroupRef: customer.productGroupRef
    },
  );
  return request.data;
}


//yeni customerUser ekle

export async function customerUserUpdateRequest(model:CustomerUserModel) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await axios.post(
    apiUrl,
    {
      serviceId: "1031",
      vendorId: appVendorID,
      token: appToken,
       comment: model.comment,
        customerRef: model.customerRef,
        customerUserRef: model.customerUserRef,
        userRef: model.userRef,
        userActive: model.userActive,
        userName: model.userName,
        userEmail: model.userEmail,
        userPassword: model.userPassword,

    }
  );
  return request.data;
}

// USERS tüm kullancılar isteği

export async function b2bUsersListRequest({customerRef}){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
    serviceId: "1025",
    vendorId: appVendorID,
    token: appToken,
    customerRef: customerRef
      },);
      return request.data;
}

// USERS add user isteği

export async function b2bUsersListUpdateRequest(user:CustomerUserModel){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
    serviceId: "1026",
    vendorId: appVendorID,
    token: appToken,
    "customerUserRef": 0,
    "customerRef": 0,
    "customerUserActive": 0,
    "customerUserComment": "",
    "shopUserRef": user.userRef,
    "shopUserActive": user.userActive,
    "shopUserName": user.userName,
    "shopUserEmail": user.userEmail,
    "shopUserPassword": user.userPassword,
    "shopUserComment": user.comment,
    "linkCustomer": 0
      },);
      return request.data;
}

// USERS toplu status update

export async function b2bUsersStatusUpdateRequest(user:CustomerUserModel,status){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
    serviceId: "1026",
    vendorId: appVendorID,
    token: appToken,
    "customerUserRef": 0,
    "customerRef": 0,
    "customerUserActive": 0,
    "customerUserComment": "",
    "shopUserRef": user.userRef,
    "shopUserActive": status,
    "shopUserName": user.userName,
    "shopUserEmail": user.userEmail,
    "shopUserPassword": user.userPassword,
    "shopUserComment": user.comment,
    "linkCustomer": 0
      },);
      return request.data;
}

// USERS add user isteği

export async function b2bUsersListDeleteRequest(user:CustomerUserModel){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
    serviceId: "1026",
    vendorId: appVendorID,
    token: appToken,
    "customerUserRef": 0,
    "customerRef": 0,
    "customerUserActive": 0,
    "customerUserComment": "",
    "shopUserRef": user.userRef,
    "shopUserActive": 2,
    "shopUserName": user.userName,
    "shopUserEmail": user.userEmail,
    "shopUserPassword": user.userPassword,
    "shopUserComment": user.comment,
    "linkCustomer": 0
      },);
      return request.data;
}

// USERS add user with customer isteği

export async function b2bUsersListUpdateWithCustomerRequest(user:CustomerUserModel,customer:CustomerUserModel){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
    serviceId: "1026",
    vendorId: appVendorID,
    token: appToken,
    "customerUserRef": 0,
    "customerRef": customer.customerRef,
    "customerUserActive": 1,
    "customerUserComment": customer.comment,
    "shopUserRef": 0,
    "shopUserActive": user.userActive,
    "shopUserName": user.userName,
    "shopUserEmail": user.userEmail,
    "shopUserPassword": user.userPassword,
    "shopUserComment": user.comment,
    "linkCustomer": 1
      },);
      return request.data;
}
// Customer delete user with user isteği

export async function b2bUsersListDeleteWithCustomerRequest(user:CustomerUserModel,customer:CustomerUserModel){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
    serviceId: "1026",
    vendorId: appVendorID,
    token: appToken,
    "customerUserRef": customer.customerUserRef,
    "customerRef": customer.customerRef,
    "customerUserActive": 2,
    "customerUserComment": customer.comment,
    "shopUserRef": user.userRef,
    "shopUserActive": user.userActive,
    "shopUserName": user.userName,
    "shopUserEmail": user.userEmail,
    "shopUserPassword": user.userPassword,
    "shopUserComment": user.comment,
    "linkCustomer": 1
      },);
      return request.data;
}
// Customer delete user with user isteği

export async function b2bUsersListDeleteWithCustomerRequest2(user:CustomerUserModel,customer:CustomerUserModel){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
    serviceId: "1026",
    vendorId: appVendorID,
    token: appToken,
    "customerUserRef": user.customerUserRef,
    "customerRef": customer.customerRef,
    "customerUserActive": 2,
    "customerUserComment": customer.comment,
    "shopUserRef": user.userRef,
    "shopUserActive": user.userActive,
    "shopUserName": user.userName,
    "shopUserEmail": user.userEmail,
    "shopUserPassword": user.userPassword,
    "shopUserComment": user.comment,
    "linkCustomer": 1
      },);
      return request.data;
}

export async function b2bUsersListUpdateWithCustomerRequest2(user:CustomerUserModel,customer:CustomerUserModel){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
    serviceId: "1026",
    vendorId: appVendorID,
    token: appToken,
    "customerUserRef": 0,
    "customerRef": customer.customerRef,
    "customerUserActive": 1,
    "customerUserComment": customer.comment,
    "shopUserRef": user.userRef,
    "shopUserActive": user.userActive,
    "shopUserName": user.userName,
    "shopUserEmail": user.userEmail,
    "shopUserPassword": user.userPassword,
    "shopUserComment": user.comment,
    "linkCustomer": 1
      },);
      return request.data;
}


//exports list

export async function exportsListRequest(beginDate,endDate,docType) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await axios.post(
    apiUrl,
    {
      serviceId: "5020",
      vendorId: appVendorID,
      token: appToken,
      beginDate: beginDate,
      endDate: endDate,
      docType: docType

    }
  );
  return request.data;
}


// dashboard1

export async function dashboard1(begin,end) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await axios.post(
    apiUrl,
    {
      serviceId: "5051",
      vendorId: appVendorID,
      token: appToken,
      beginDate: begin,
      endDate: end
    }
  );
  return request.data;
}




// dashboard2

export async function dashboard2Request(index) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await axios.post(
    apiUrl,
    {
      serviceId: "5050",
      vendorId: appVendorID,
      token: appToken,
      dashboardIndex: index
    }
  );
  return request.data.dataset;
}



// dashboard3

export async function dashboard3Request(index) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await axios.post(
    apiUrl,
    {
      serviceId: "5052",
      vendorId: appVendorID,
      token: appToken,
      dashboardIndex: index
    }
  );
  return request.data;
}



// müşteri grupları isteği

export async function customerGroupsRequest(){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '1040',
        vendorId: appVendorID,
        token: appToken,
      });
      return request;
}

// Sales/Routes isteği

export async function salesRoutesRequest(beginDate,endDate){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5002',
        vendorId: appVendorID,
        token: appToken,
        beginDate:beginDate,
        endDate:endDate
      });
      return request.data;
    }



// Sales/Routes UPDATE isteği

export async function salesRoutesUpdateRequest(route:RouteModel){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5003',
        vendorId: appVendorID,
        token: appToken,
        routeRef: route.routeRef,
        routeActive: route.routeActive,
        routeStatus: route.routeStatus,
        routeCode: route.routeCode,
        routeName: route.routeName,
        routePlanRef: route.routePlanRef,
        erpSlsManRef: route.erpSlsManRef,
        erpSlsManCode: route.erpSlsManCode,
        erpVehicleRef: route.erpVehicleRef,
        routeDate: route.routeDate,
        comment: route.comment
      });
      return request.data;
    }


// Sales/Routes DELETE isteği

export async function salesRoutesDeleteRequest(route:RouteModel){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5003',
        vendorId: appVendorID,
        token: appToken,
        routeRef: route.routeRef,
        routeActive: 2,
        routeStatus: route.routeStatus,
        routeCode: route.routeCode,
        routeName: route.routeName,
        routePlanRef: route.routePlanRef,
        erpSlsManRef: route.erpSlsManRef,
        erpSlsManCode: route.erpSlsManCode,
        erpVehicleRef: route.erpVehicleRef,
        routeDate: route.routeDate,
        comment: route.comment
      });
      return request.data;
    }


// Sales/Route Lines List isteği

export async function salesRouteLineListRequest(routeRef){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5004',
        vendorId: appVendorID,
        token: appToken,
        routeRef: routeRef
      });
      return request.data;
}


// Sales/Route Lines UPDATE isteği

export async function salesRouteLineUpdateRequest(routeLine){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5005',
        vendorId: appVendorID,
        token: appToken,
        routeRef: routeLine.routeRef,
        routeLineRef: routeLine.routeLineRef,
      //  routeLineActive: routeLine.routeLineActive,
        routeLineType: routeLine.routeLineType,
     //   routeLineNr: routeLine.routeLineNr,
        erpCustomerRef: routeLine.erpCustomerRef,
     //   erpCustomerCode: routeLine.erpCustomerCode,
     //   erpCustomerName: routeLine.erpCustomerName,
     //   erpCustomerAdr: routeLine.erpCustomerAdr,
     //   erpShipInfoRef: routeLine.erpShipInfoRef,
     //   erpShippingAdr: routeLine.erpShippingAdr,
        erpShippingAdrRef: routeLine.erpShippingAdrRef,
    //    formLineRef: routeLine.formLineRef,
        routeLineStatus: routeLine.routeLineStatus,
    //    beginDate: routeLine.beginDate,
     //   endDate: routeLine.endDate,
        newRouteLineNr: routeLine.newRouteLineNr??routeLine.routeLineNr,
        routeLineCloseReasonRef: 0,
        routeLineActive: routeLine.routeLineActive,
        comment: routeLine.comment
      });
      return request.data;
}

// Sales/Route Lines INSERT isteği

export async function salesRouteLineInsertRequest(route,customer){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5005',
        vendorId: appVendorID,
        token: appToken,
        routeRef: route.routeRef,
        routeLineRef: 0,
        routeLineType: 0,
        erpCustomerRef: customer.erpCustomerRef,
        erpShippingAdrRef: customer.erpShippingAdrRef,
        routeLineStatus: 0,
        routeLineActive:1,
        newRouteLineNr: 0,
        routeLineCloseReasonRef: 0,
        comment: ""
      });
      return request.data;
}

// Sales/Route Lines DELETE isteği

export async function salesRouteLineDeleteRequest(routeLine){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5005',
        vendorId: appVendorID,
        token: appToken,
        routeRef: routeLine.routeRef,
        routeLineRef: routeLine.routeLineRef,
        routeLineType: routeLine.routeLineType,
        erpCustomerRef: routeLine.erpCustomerRef,
        erpShippingAdrRef: routeLine.erpShippingAdrRef,
        routeLineActive: 2,
        routeLineStatus: routeLine.routeLineStatus,
        newRouteLineNr: 0,
        routeLineCloseReasonRef: 0,
        comment: ""
      });
      return request.data;
}


// Sales/Route Customer Search isteği

export async function routeCustomerSearchRequest(routeRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5006',
        vendorId: appVendorID,
        token: appToken,
        routeRef:routeRef
      });
      return request.data;
}


// Sales/Salesman List isteği

export async function salesUserRequest(){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5000',
        vendorId: appVendorID,
        token: appToken,
      });
      return request.data;
}


// Sales/Salesman Edit İsteği

export async function salesUserUpdateRequest(salesman) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();

  var request =  await axios.post(
    apiUrl,
    {
      serviceId: "5001",
      vendorId: appVendorID,
      token: appToken,

      salesManRef: salesman.salesManRef,
      salesManActive: salesman.salesManActive,
      salesManName: salesman.salesManName,
      salesManEMail: salesman.salesManEMail,
      salesManPassword: salesman.salesManPassword,
      erpSlsManRef: salesman.erpSlsManRef,
      erpSlsManCode: salesman.erpSlsManCode,
      erpSlsManName1: salesman.erpSlsManName1,
      erpFirmNr: salesman.erpFirmNr,
      erpBranch: salesman.erpBranch,
      erpWHCentral: salesman.erpWHCentral,
      erpWHReturn: salesman.erpWHReturn,
      erpWHScrap: salesman.erpWHScrap,
      erpCashBoxCode: salesman.erpCashBoxCode,
      erpDiscLimit: salesman.erpDiscLimit,
      erpSalesOrderStatus: salesman.erpSalesOrderStatus,
      rightSalesOrder: salesman.rightSalesOrder,
      rightSalesDispatch: salesman.rightSalesDispatch,
      rightSalesDispatchRt: salesman.rightSalesDispatchRt,
      rightSalesInvoice: salesman.rightSalesInvoice,
      rightSalesInvoiceRt: salesman.rightSalesInvoiceRt,
      rightPaymentCash: salesman.rightPaymentCash,
      rightGetPdf: salesman.rightGetPdf,
      rightGetAllProducts: salesman.rightGetAllProducts,
      routeActive: salesman.routeActive,
      routeChangeDataAfterSave: salesman.routeChangeDataAfterSave,
      routeSendDataAfterSave: salesman.routeSendDataAfterSave,
      comment: salesman.comment,

    },
  );
  return request.data;
}


// General/Campaigns isteği

export async function generalCampaignsRequest(){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5060',
        vendorId: appVendorID,
        token: appToken,
        beginDate:"" ,
      });
      return request.data;
}

// General/Campaign post isteği

export async function generalCampaignPostRequest(campaign:CampaignModel){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5061',
        vendorId: appVendorID,
        token: appToken,
        campaignRef: campaign.campaignRef,
        campaignCode: campaign.campaignCode,
        campaignName: campaign.campaignName,
        campaignStatus : campaign.campaignStatus,
        forCertainCustomers: campaign.forCertainCustomers,
        isDiscPercent: campaign.isDiscPercent,
        amountDepended: campaign.amountDepended,
        customerSelectionType: campaign.customerSelectionType,
        campaignGroupRef: campaign.campaignGroupRef,
        runManually: campaign.runManually,
        erpCostCode: campaign.erpCostCode,
        erpClSpeCode1: campaign.erpClSpeCode1,
        erpClSpeCode2: campaign.erpClSpeCode2,
        beginDate: campaign.beginDate,
        endDate: campaign.endDate,
        comment: campaign.comment,

      });
      return request.data;
}

// General/Campaign DELETE isteği

export async function generalCampaignDeleteRequest(campaign:CampaignModel){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5061',
        vendorId: appVendorID,
        token: appToken,
        campaignRef: campaign.campaignRef,
        campaignCode: campaign.campaignCode,
        campaignName: campaign.campaignName,
        campaignStatus : 2,
        forCertainCustomers: campaign.forCertainCustomers,
        customerSelectionType: campaign.customerSelectionType,
        campaignGroupRef: campaign.campaignGroupRef,
        isDiscPercent: campaign.isDiscPercent,
        amountDepended: campaign.amountDepended,
        runManually: campaign.runManually,
        erpCostCode: campaign.erpCostCode,
        erpClSpeCode1: campaign.erpClSpeCode1,
        erpClSpeCode2: campaign.erpClSpeCode2,
        beginDate: campaign.beginDate,
        endDate: campaign.endDate,
        comment: campaign.comment,

      });
      return request.data;
}



// General/Campaign Product List isteği

export async function generalCampaignProductListRequest(campaignRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5062',
        vendorId: appVendorID,
        token: appToken,
        campaignRef: campaignRef,

      });
      return request.data;
}

// General/Campaign Customer List isteği

export async function generalCampaignCustomerListRequest(campaignRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5064',
        vendorId: appVendorID,
        token: appToken,
        campaignRef: campaignRef,

      });
      return request.data;
}

// General/Campaign Product Search isteği

export async function campaignProductSearchRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5066',
        vendorId: appVendorID,
        token: appToken,
      });
      return request.data;
}

// General/Campaign Product Post isteği

export async function campaignProductSelectRequest(campaignRef, erpItemRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5063',
        vendorId: appVendorID,
        token: appToken,
        campaignProductRef:0,
        campaignRef: campaignRef,
        erpItemRef: erpItemRef,
        erpItemUnit:"PK",
        campaignPrice:0,
        campaignSRP:0,
        campaignPercent:0,
        campaignItemAmount:0,
        campaignMinAmount:0,
        campaignMaxAmount:0,
        campaignRunManually:0,
        erpItemPrice: 0,
        erpItemSRP: 0,
        campaignGroup: "",
        showOnStart: 1,
        comment:"",
        productStatus: 1,
        imported: 0
      });
      return request.data;
}

// General/Campaign Product Edit isteği

export async function campaignProductUpdateRequest(product){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5063',
        vendorId: appVendorID,
        token: appToken,
        campaignProductRef: product.campaignProductRef,
        campaignRef: product.campaignRef,
        erpItemRef: product.erpItemRef,
        erpItemUnit:"PK",
        erpItemPrice: product.erpItemPrice,
        erpItemSRP: product.erpItemSRP,
        campaignPrice: product.campaignPrice,
        campaignSRP: product.campaignSRP,
        campaignPercent: product.campaignPercent,
        campaignItemAmount: product.campaignItemAmount,
        campaignMinAmount: product.campaignMinAmount,
        campaignMaxAmount: product.campaignMaxAmount,
        campaignRunManually: product.campaignRunManually,
        campaignGroup: product.campaignGroup,
        showOnStart: product.showOnStart,
        comment: product.comment,
        productStatus: 1,
        imported: product.imported

      });
      return request.data;
}

// General/Campaign Product Delete isteği

export async function campaignProductDeleteRequest(product){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5063',
        vendorId: appVendorID,
        token: appToken,
        campaignProductRef: product.campaignProductRef,
        campaignRef: product.campaignRef,
        erpItemRef: product.erpItemRef,
        erpItemUnit:"PK",
        campaignPrice: product.campaignPrice,
        campaignSRP: product.campaignSRP,
        campaignPercent: product.campaignPercent,
        campaignItemAmount: product.campaignItemAmount,
        campaignMinAmount: product.campaignMinAmount,
        campaignMaxAmount: product.campaignMaxAmount,
        campaignRunManually: product.campaignRunManually,
        erpItemPrice: product.erpItemPrice,
        erpItemSRP: product.erpItemSRP,
        campaignGroup: product.campaignGroup,
        showOnStart: product.showOnStart,
        comment: "",
        productStatus: 2,
        imported: product.imported
      });
      return request.data;
}



// General/Campaign Customer Search isteği

export async function campaignCustomerSearchRequest(campaignRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5067',
        vendorId: appVendorID,
        token: appToken,
        campaignRef:campaignRef
      });
      return request.data;
}

// General/Campaign Customer Select isteği

export async function campaignCustomerSelectRequest(campaignRef, customer){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5065',
        vendorId: appVendorID,
        token: appToken,

        campaignCustomerRef: 0,
        campaignRef: campaignRef,
        customerRef: customer.customerRef,
        erpCustomerRef: customer.erpCustomerRef,
        erpShippingAdrRef: customer.erpShippingAdrRef,
        newErpShippingAdrRef:customer.erpShippingAdrRef,
        comment:"",
        status:1,
        imported: 0
      });
      return request.data;
}

// General/Campaign Customer DELETE isteği

export async function campaignCustomerDeleteRequest(campaignRef, customer){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5065',
        vendorId: appVendorID,
        token: appToken,

        campaignCustomerRef: customer.campaignCustomerRef,
        campaignRef: campaignRef,
        customerRef: customer.customerRef,
        erpCustomerRef: customer.erpCustomerRef,
        erpShippingAdrRef: customer.erpShippingAdrRef,
        newErpShippingAdrRef: customer.erpShippingAdrRef,
        comment:"",
        status:2,
        imported: customer.imported
      });
      return request.data;
}

// General/Campaign Customer UPDATE isteği

export async function campaignCustomerUpdateRequest(campaignRef, customer,old,neww){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5065',
        vendorId: appVendorID,
        token: appToken,

        campaignCustomerRef: customer.campaignCustomerRef,
        campaignRef: campaignRef,
        customerRef: customer.customerRef,
        erpCustomerRef: customer.erpCustomerRef,
        erpShippingAdrRef: old,
        newErpShippingAdrRef: neww,
        comment:  customer.comment,
        status:1,
        imported: customer.imported
      });
      return request.data;
}

// General/Campaign IMPORT Product CSV isteği

export async function campaignProductsImportRequest(campaignRef, list){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5068',
        vendorId: appVendorID,
        token: appToken,
        campaignRef: campaignRef,
        campaignProducts: JSON.stringify({campaignProducts: list})
      });
      return request.data;
}

// General/Campaign IMPORT Customer CSV isteği

export async function campaignCustomersImportRequest(campaignRef, list){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5069',
        vendorId: appVendorID,
        token: appToken,
        campaignRef: campaignRef,
        campaignCustomers: JSON.stringify({campaignCustomers: list})
      });
      return request.data;
}

// General/Campaign Customer Shipping Address List isteği

export async function campaignCustomerShippingAdressListRequest(erpCustomerRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5070',
        vendorId: appVendorID,
        token: appToken,
        erpCustomerRef: erpCustomerRef,
      });
      return request.data;
}


//Product Images List

export async function generalProductImagesListRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5080',
        vendorId: appVendorID,
        token: appToken,

      });
      return request.data;
}


//Segmentation List Request

export async function generalSegmentationListRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5090',
        vendorId: appVendorID,
        token: appToken,

      });
      return request.data;
}

//Segmentation Post Request

export async function generalSegmentationPostRequest(segment:SegmentModel){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5091',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: segment.segmentRef,
        segmentType: segment.segmentType,
        segmentStatus: segment.segmentStatus,
        segmentCode: segment.segmentCode,
        segmentName: segment.segmentName,
        speCode1: segment.speCode1,
        speCode2: segment.speCode2,
        productSelectionType: segment.productSelectionType,
        customerSelectionType: segment.customerSelectionType,
        brandSelectionType: segment.brandSelectionType,
        productGroupSelectionType: segment.productGroupSelectionType,
        comment: segment.comment


      });
      return request.data;
}

//Segmentation DELETE Request

export async function generalSegmentationDeleteRequest(segment:SegmentModel){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5091',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: segment.segmentRef,
        segmentType: segment.segmentType,
        segmentStatus: 2,
        segmentCode: segment.segmentCode,
        segmentName: segment.segmentName,
        speCode1: segment.speCode1,
        speCode2: segment.speCode2,
        productSelectionType: segment.productSelectionType,
        customerSelectionType: segment.customerSelectionType,
        brandSelectionType: segment.brandSelectionType,
        productGroupSelectionType: segment.productGroupSelectionType,
        comment: segment.comment

      });
      return request.data;
}


//Segmentation Product List Request

export async function generalSegmentationProductListRequest(segmentRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5110',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: segmentRef,
        segmentProductType:0

      });
      return request.data;
}

//Segmentation Customer List Request

export async function generalSegmentationCustomerListRequest(segmentRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5094',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: segmentRef
      });
      return request.data;
}


//Segmentation Product UPDATE Request

export async function generalSegmentationProductUpdateRequest(segmentProduct:SegmentProductModel){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5111',
        vendorId: appVendorID,
        token: appToken,
        segmentProductRef: segmentProduct.segmentProductRef,
        segmentProductType: segmentProduct.segmentProductType,
        segmentRef: segmentProduct.segmentRef,
        erpItemRef: segmentProduct.erpItemRef,
        customerRef: 0,
        erpCustomerRef:0,
        erpShippingAdrRef:0,
        comment: segmentProduct.comment,
        visibility:1,
        delete: 0
      });
      return request.data;
}


//Segmentation Product DELETE Request

export async function generalSegmentationProductDeleteRequest(segmentProduct:SegmentProductModel){

      var appToken = getAppToken();
      var appVendorID = getVendorId();
      var request = await  axios.post(apiUrl, {
            serviceId: '5111',
            vendorId: appVendorID,
            token: appToken,
            segmentRef: segmentProduct.segmentRef,
            segmentProductType: 0,
            segmentProductRef: segmentProduct.segmentProductRef,
            customerRef:0,
            erpCustomerRef:0,
            erpShippingAdrRef:0,
            erpItemRef:0,
            visibility:1,
            comment:"",
            delete:1

          });
          return request.data;
}


//Segmentation Product INSERT Request

export async function generalSegmentationProductInsertRequest(segmentRef,erpItemRef){

      var appToken = getAppToken();
      var appVendorID = getVendorId();
      var request = await  axios.post(apiUrl, {
        serviceId: '5111',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: segmentRef,
        segmentProductType: 0,
        segmentProductRef: 0,
        customerRef:0,
        erpCustomerRef:0,
        erpShippingAdrRef:0,
        erpItemRef:erpItemRef,
        visibility:1,
        comment:"",
        delete:0

      });
      return request.data;

    }


//Segmentation Customer INSERT Request

export async function generalSegmentationCustomerInsertRequest(segmentRef,customer){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5095',
        vendorId: appVendorID,
        token: appToken,
        segmentCustomerRef: 0,
        segmentCustomerType: 0,
        segmentRef: segmentRef,
        customerRef: 0,
        erpCustomerRef: customer.erpCustomerRef,
        erpShippingAdrRef: customer.erpShippingAdrRef,
        comment: "",
        delete: 0
      });
      return request.data;
}



//Segmentation Customer UPDATE Request

export async function generalSegmentationCustomerUpdateRequest(segmentCustomer:SegmentCustomerModel){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5095',
        vendorId: appVendorID,
        token: appToken,
        segmentCustomerRef: segmentCustomer.segmentCustomerRef,
        segmentCustomerType: segmentCustomer.segmentCustomerType,
        segmentRef: segmentCustomer.segmentRef,
        customerRef: segmentCustomer.customerRef,
        erpCustomerRef: segmentCustomer.erpCustomerRef,
        erpShippingAdrRef: segmentCustomer.erpShippingAdrRef,
        comment: segmentCustomer.comment,
        delete: 0
      });
      return request.data;
}

//Segmentation Customer DELETE Request

export async function generalSegmentationCustomerDeleteRequest(segmentCustomer:SegmentCustomerModel){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5095',
        vendorId: appVendorID,
        token: appToken,
        segmentCustomerRef: segmentCustomer.segmentCustomerRef,
        segmentCustomerType: segmentCustomer.segmentCustomerType,
        segmentRef: segmentCustomer.segmentRef,
        customerRef: segmentCustomer.customerRef,
        erpCustomerRef: segmentCustomer.erpCustomerRef,
        erpShippingAdrRef: segmentCustomer.erpShippingAdrRef,
        comment: segmentCustomer.comment,
        delete: 1
      });
      return request.data;
}

//Segmentation ProductGroups List Request

export async function generalSegmentationProductGroupsListRequest(segmentRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5096',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: segmentRef,
        segmentProductGroupType: 0
      });
      return request.data;
}


//Segmentation ProductGroups UPDATE Request

export async function generalSegmentationProductGroupsUpdateRequest(segmenProductGroup:SegmentProductGroupModel){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5097',
        vendorId: appVendorID,
        token: appToken,
        segmentProductGroupRef: segmenProductGroup.segmentProductGroupRef,
        segmentProductGroupType: 0,
        segmentRef: segmenProductGroup.segmentRef,
        productGroupCode: segmenProductGroup.productGroupCode,
        comment: segmenProductGroup.comment,
        delete: 0
      });
      return request.data;
}

//Segmentation ProductGroups INSERT Request

export async function generalSegmentationProductGroupsInsertRequest(segmentRef,segmentProductGroup:SegmentProductGroupModel){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5097',
        vendorId: appVendorID,
        token: appToken,
        segmentProductGroupRef: 0,
        segmentProductGroupType: 0,
        segmentRef: segmentRef,
        productGroupCode: segmentProductGroup["itemCategory"],
        comment: "",
        delete: 0
      });
      return request.data;
}

//Segmentation ProductGroups DELETE Request

export async function generalSegmentationProductGroupsDeleteRequest(segmenProductGroup:SegmentProductGroupModel){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5097',
        vendorId: appVendorID,
        token: appToken,
        segmentProductGroupRef: segmenProductGroup.segmentProductGroupRef,
        segmentProductGroupType: 0,
        segmentRef: segmenProductGroup.segmentRef,
        productGroupCode: segmenProductGroup.productGroupCode,
        comment: segmenProductGroup.comment,
        delete: 1
      });
      return request.data;
}



//Segmentation Customer Search List Request

export async function segmentCustomerSearchListRequest(segmentRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5099',
        vendorId: appVendorID,
        token: appToken,
        segmentRef:segmentRef
      });
      return request.data;
}

//Segmentation Product Group Search List Request

export async function segmentProductGroupSearchListRequest(segmentRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5100',
        vendorId: appVendorID,
        token: appToken,
        segmentRef:segmentRef,
        segmentProductGroupType: 0
      });
      return request.data;
}

//Segmentation Brands Search List Request

export async function segmentBrandsSearchListRequest(segmentRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5100',
        vendorId: appVendorID,
        token: appToken,
        segmentRef:segmentRef,
        segmentProductGroupType: 1
      });
      return request.data;
}

//Segmentation Brands List Request

export async function generalSegmentationBrandsListRequest(segmentRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5096',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: segmentRef,
        segmentProductGroupType: 1
      });
      return request.data;
}


//Segmentation Brands UPDATE Request

export async function generalSegmentationBrandsUpdateRequest(segmenProductGroup:SegmentProductGroupModel){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5097',
        vendorId: appVendorID,
        token: appToken,
        segmentProductGroupRef: segmenProductGroup.segmentProductGroupRef,
        segmentProductGroupType: 1,
        segmentRef: segmenProductGroup.segmentRef,
        productGroupCode: segmenProductGroup.productGroupCode,
        comment: segmenProductGroup.comment,
        delete: 0
      });
      return request.data;
}

//Segmentation Brands INSERT Request

export async function generalSegmentationBrandsInsertRequest(segmentRef,segmentProductGroup:SegmentProductGroupModel){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5097',
        vendorId: appVendorID,
        token: appToken,
        segmentProductGroupRef: 0,
        segmentProductGroupType: 1,
        segmentRef: segmentRef,
        productGroupCode: segmentProductGroup["itemSubCategory"],
        comment: "",
        delete: 0
      });
      return request.data;
}


//Segmentation Brands DELETE Request

export async function generalSegmentationBrandsDeleteRequest(segmenProductGroup:SegmentProductGroupModel){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5097',
        vendorId: appVendorID,
        token: appToken,
        segmentProductGroupRef: segmenProductGroup.segmentProductGroupRef,
        segmentProductGroupType: 1,
        segmentRef: segmenProductGroup.segmentRef,
        productGroupCode: segmenProductGroup.productGroupCode,
        comment: segmenProductGroup.comment,
        delete: 1
      });
      return request.data;
}

//Incentives List Request

export async function generalIncentivesListRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5110',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: 0,
        segmentProductType: 1
      });
      return request.data;
}


//Incentives INSERT Request

export async function generalIncentivesInsertRequest(erpItemRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5111',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: 0,
        segmentProductType: 1,
        segmentProductRef: 0,
        customerRef:0,
        erpCustomerRef:0,
        erpShippingAdrRef:0,
        erpItemRef:erpItemRef,
        visibility:1,
        comment:"",
        delete:0

      });
      return request.data;
}


//Incentives DELETE Request

export async function generalIncentivesDeleteRequest(segmentProductRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5111',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: 0,
        segmentProductType: 1,
        segmentProductRef: segmentProductRef,
        customerRef:0,
        erpCustomerRef:0,
        erpShippingAdrRef:0,
        erpItemRef:0,
        visibility:1,
        comment:"",
        delete:1

      });
      return request.data;
}

//New Products List Request

export async function generalNewProductsListRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5110',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: 0,
        segmentProductType: 3
      });
      return request.data;
}


//New Products INSERT Request

export async function generalNewProductsInsertRequest(erpItemRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5111',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: 0,
        segmentProductType: 3,
        segmentProductRef: 0,
        customerRef:0,
        erpCustomerRef:0,
        erpShippingAdrRef:0,
        erpItemRef:erpItemRef,
        visibility:1,
        comment:"",
        delete:0

      });
      return request.data;
}


//New Products DELETE Request

export async function generalNewProductsDeleteRequest(segmentProductRef){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5111',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: 0,
        segmentProductType: 3,
        segmentProductRef: segmentProductRef,
        customerRef:0,
        erpCustomerRef:0,
        erpShippingAdrRef:0,
        erpItemRef:0,
        visibility:1,
        comment:"",
        delete:1

      });
      return request.data;
}


//New Products List Request

export async function generalProductSearch(segmentRef,segmentProductType){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5112',
        vendorId: appVendorID,
        token: appToken,
        segmentRef: segmentRef,
        segmentProductType: segmentProductType
      });
      return request.data;
}


//Finance List Sell Request

export async function paymentsListSellRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5300',
        vendorId: appVendorID,
        token: appToken,
        cardtype: 1,
      });
      return request.data;
}

//Finance List Buy Request

export async function paymentsListBuyRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5300',
        vendorId: appVendorID,
        token: appToken,
        cardtype: 2,
      });
      return request.data;
}

//CampaignGroupsList

export async function campaignGroupsListRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5071',
        vendorId: appVendorID,
        token: appToken,
      });
      return request.data;
}

//CampaignGroups INSERT

export async function campaignGroupInsertRequest(group){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5072',
        vendorId: appVendorID,
        token: appToken,
        groupRef: group.groupRef,
        groupStatus: group.groupStatus,
        groupCode: group.groupCode,
        groupName: group.groupName,
        comment: group.comment
      });
      return request.data;
}

//CampaignGroups DELETE

export async function campaignGroupDeleteRequest(group){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5072',
        vendorId: appVendorID,
        token: appToken,
        groupRef: group.groupRef,
        groupStatus: 3,
        groupCode: group.groupCode,
        groupName: group.groupName,
        comment: group.comment
      });
      return request.data;
}


//UserGroupsList

export async function userGroupsListRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5073',
        vendorId: appVendorID,
        token: appToken,
      });
      return request.data;
}

//UserGroups INSERT

export async function userGroupInsertRequest(group){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5074',
        vendorId: appVendorID,
        token: appToken,
        groupRef: group.groupRef,
        groupStatus: group.groupStatus,
        groupCode: group.groupCode,
        groupName: group.groupName,
        comment: group.comment
      });
      return request.data;
}

//UserGroups DELETE

export async function userGroupDeleteRequest(group){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5074',
        vendorId: appVendorID,
        token: appToken,
        groupRef: group.groupRef,
        groupStatus: 3,
        groupCode: group.groupCode,
        groupName: group.groupName,
        comment: group.comment
      });
      return request.data;
}




//Registrants Get List

export async function registrantsListRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '1118',
        vendorId: appVendorID,
        token: appToken,
      });
      return request.data;
}

//Registrants Update Record

export async function registrantsUpdateRecordRequest(record){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '1119',
        vendorId: appVendorID,
        token: appToken,

        requestRef: record["requestRef"],
        requestStatus: record["requestStatus"],

        "shopUserFirstname":  record["shopUserFirstname"],
        "shopUserSurname":    record["shopUserSurname"],
        "shopUserEmail":      record["shopUserEmail"],
        "shopUserPhone":      record["shopUserPhone"],
        "customerName":           record["customerName"],
        "customerVATNr":          record["customerVATNr"]??"",
        "customerTaxNr":          record["customerTaxNr"]??"",
        "customerAddress":        record["customerAddress"],
        "customerPostCode":       record["customerPostCode"],
        "customerCity":           record["customerCity"],
        "customerCountry":        record["customerCountry"],
        "customerAdrInfo":        record["customerAdrInfo"],
        "shippingAddress":        record["shippingAddress"]??"",
        "shippingPostCode":   record["shippingPostCode"]??"",
        "shippingCity":       record["shippingCity"]??"",
        "shippingCountry":    record["shippingCountry"]??"",
        "shippingAdrInfo":    record["shippingAdrInfo"]??"",
        "requestNote" :       record["requestNote"]??""
      });
      return request.data;
}

// e-invoice

export async function IncomingInvoicesRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5201',
        vendorId: appVendorID,
        token: appToken,
        invoiceStatus: 0,
        cardType: 2
      });
      return request.data;
}

export async function OutgoingInvoicesRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5201',
        vendorId: appVendorID,
        token: appToken,
        invoiceStatus: 0,
        cardType: 1
      });
      return request.data;
}

export async function customerParametersRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5203',
        vendorId: appVendorID,
        token: appToken,
        cardType: 1
      });
      return request.data;
}

export async function supplierParametersRequest(){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5203',
        vendorId: appVendorID,
        token: appToken,
        cardType: 2
      });
      return request.data;
}

export async function parameterRequest(parameter,type){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '5204',
        vendorId: appVendorID,
        token: appToken,
        cardType: type,
        eInvoiceARPRef: parameter.eInvoiceARPRef,
        registrationName: parameter.registrationName,
        arpStatus: parameter.arpStatus,
        arpStreet: parameter.arpStreet,
        arpCity: parameter.arpCity,
        arpPostCode: parameter.arpPostCode,
        arpCountryCode: parameter.arpCountryCode,
        arpCountry: parameter.arpCountry,
        arpVatId: parameter.arpVatId,
        arpTaxNr: parameter.arpTaxNr,
        tradingGroup: parameter.tradingGroup,
        arpEMail: parameter.arpEMail,
        contactName: parameter.contactName,
        contactPhone: parameter.contactPhone,
        contactEMail: parameter.contactEMail,

        registerNumber: parameter.registerNumber ?? "",
        legalForm: parameter.legalForm ?? "",
        arpIban: parameter.arpIban ?? "",
        leitwegId: parameter.leitwegId ?? "",
        endpointId: parameter.endpointId ?? ""


      });
      return request.data;
}


export const getInvoice = async (invoiceRef) => {
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  try {

      const response = await fetch('https://api.fbsshop.de', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              serviceId: "5202",
              vendorId: appVendorID,
              token: appToken,
              erpInvoiceRef: invoiceRef
          })
      });

      if (!response.ok) {
          throw new Error(`HTTP hata! durum: ${response.status}`);
      }
      const data = await response.json();
      return data;
  } catch (error) {
      throw error;
  }
};



export const getPDF = async (invoiceRef) => {
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  try {
      console.log('getPDF çağrıldı, invoiceRef:', invoiceRef);

      const response = await fetch('https://api.fbsshop.de', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              serviceId: "5203", // PDF servisi için
              vendorId: appVendorID,
              token: appToken,
              invoiceRef: invoiceRef
          })
      });

      if (!response.ok) {
          throw new Error(`HTTP hata! durum: ${response.status}`);
      }

      const data = await response.json(); // PDF URL'sini al
      return data.pdfUrl; // PDF URL'sini döndür
  } catch (error) {
      console.error('PDF alınırken hata:', error);
      throw error;
  }
};



//Base 64 Upload

export async function base64Upload(fileFolder, fileName, fileBase64){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '3003',
        vendorId: appVendorID,
        token: appToken,
        fileName: fileName,
        fileFolder: fileFolder,
        fileBase64: fileBase64
      });
      return request.data;
}


//Base 64 Download

export async function base64Download(fileFolder, fileName){

  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '3004',
        vendorId: appVendorID,
        token: appToken,
        fileName: fileName,
        fileFolder: fileFolder,
      });
      return request.data;
}

// TRANSACTION

export async function financeTransactionRequest(){

  var request = await  axios.get("https://pay.mobiserver.de:4242/payments");
      return request.data;
}


