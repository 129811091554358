
import React, { useCallback, useState } from 'react';
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  ICommonSeriesSettingsProps,
  CommonAxisSettings,
  Grid,
  Export,
  Legend,
  Margin,
  Tooltip,
  Label,
  Format,
  ValueAxis,
  Size,
} from 'devextreme-react/chart';
import { CardAnalytics } from '../../library/card-analytics/CardAnalytics';
import { euroFormat } from '../../../utils/formats';


const SalesGraph = ({salesData1,salesData2,index,title}) => {
  const now = new Date();

     const architectureSources = [
        { value: 'currRevTotal', name: (now.getFullYear()).toString() },
        { value: 'prevRevTotal', name: (now.getFullYear() - 1).toString() },

      ];
    var salesData = [{}];
      salesData.pop()
      try{
        for (let i = 0; i < salesData1.length; i++) {
          salesData.push({
              "date": salesData1[i]["aggregatedDate"]??null,
              "prevRevTotal": salesData2[i]["lineTotal"]??null,
              "currRevTotal": salesData1[i]["lineTotal"]??null,
          });
      }
        // haftalık veya aylık gösterimde az veri gelirse 7 güne tamamlar
      if (index == 1 || index == 2) {
        for(let a=0; a<(8-salesData1.length);a++){
          const inputDate: string = salesData[salesData.length-1]["date"];
          const currentDate: Date = new Date(inputDate);
          currentDate.setDate(currentDate.getDate() + 1);
          const updatedDate: string = currentDate.toISOString().split('T')[0];
          salesData.push({
            "date":updatedDate,
            "prevRevTotal": null,
            "currRevTotal": null,
        });
        }

      }

      }catch(e){
        console.log(e)
      }
  return (
    <React.Fragment>
      <CardAnalytics title={title} contentClass='conversion-funnel'>
      <Chart
        palette="Violet"
        dataSource={salesData}
      >
        <CommonSeriesSettings
          argumentField="date"
          type={"spline"}
        />
        <Label>
          <Format type='currency' currency='EUR' />
          </Label>
        <CommonAxisSettings>
          <Grid visible={true} />
        </CommonAxisSettings>
        {
          architectureSources.map((item) => <Series
            key={item.value}
            valueField={item.value}
            name={item.name}
            color={item.name == (now.getFullYear()).toString() ? '#1E88E5' : '#FF5733'}
             />)
        }
        <Margin bottom={20} />
        <ArgumentAxis
          allowDecimals={false}
          axisDivisionFactor={60}
        >
          <Label>
          <Format type='currency' currency='EUR' />
          </Label>
        </ArgumentAxis>
        <ArgumentAxis argumentType='datetime' />
      <ValueAxis>
        <Label>
          <Format type='currency' currency='EUR' />
        </Label>
      </ValueAxis>
        <Legend
          verticalAlignment="top"
          horizontalAlignment="right"
        />
        <Tooltip format={euroFormat} enabled={true} />
        <Size height={270} />
      </Chart>
      </CardAnalytics>
    </React.Fragment>
  );
}

export default SalesGraph;

