import React, { useEffect, useState, useCallback } from 'react';

import './b2b-customer-details.scss';
import ScrollView from 'devextreme-react/scroll-view';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContactForm } from '../../components/library/b2b-users-list/ContactForm';
import { ContactCards } from '../../components';
import { ContactCards2 } from '../../components/utils/contact-cards/ContactCards2';

export const B2BUsersDetails = ({mainRefresh,rowData,closePopup,openNewRow}) => {
  const location = useLocation();
  const contact = rowData;
  const [data, setData] = useState(rowData);
  const [notes, setNotes] = useState();
  const [messages, setMessages] = useState([]);
  const [activeOpportunities, setActiveOpportunities] = useState();
  const [closedOpportunities, setClosedOpportunities] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
  }, []);

  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate('/b2b-customer-list');
  }, [contact]);

  const refresh = useCallback(() => {
    setIsLoading(true);
  }, []);

  return (
    <ScrollView className='view-wrapper-scroll'>
      <div className='view-wrapper view-wrapper-contact-details' style={{padding:"0px"}}>

        <div className='panels' style={{height:"75vh"}}>
          <div className='left'>
            <ContactForm
              mainRefresh={mainRefresh}
              closePopup={closePopup}
              isLoading={isLoading}
              data={data}
              openNewRow = {openNewRow}
            />
          </div>

          <div className='right'>
            <ContactCards2
              user={contact}
              isLoading={isLoading}
              activeOpportunities={activeOpportunities}
              closedOpportunities={closedOpportunities}
              notes={notes}
              messages={messages}
              tasks={null}
              activities={null}
              name={null} />
          </div>
        </div>
      </div>
    </ScrollView>
  );
};
