import { Button, DataGrid, DropDownButton } from "devextreme-react";
import { Column, ColumnChooser, DataGridRef, DataGridTypes, Editing, Export, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, LoadPanel, RowDragging, Scrolling, SearchPanel, Selection, Sorting, Toolbar } from "devextreme-react/cjs/data-grid";
import { appInfo } from "../../app-info";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { generalNewProductsDeleteRequest, generalNewProductsListRequest } from "../../api/requests";
import notify from "devextreme/ui/notify";
import './general-new-products.scss';
import NewProductsProductSearch from "../../components/library/general-new-products/searchProduct";
import { confirm } from "devextreme/ui/dialog";



export const GeneralNewProducts = () => {
  const gridRef = useRef<DataGridRef>(null);
  const {t} = useTranslation();
  const [products, setProducts] = useState([]);
  const [rowData, setRowData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);



  function getList(){
    gridRef.current?.instance().beginCustomLoading(t("loading"));
    generalNewProductsListRequest().then((res)=>{
      if(res.resCode == 200){
        setProducts(res.dataSet || []);
      }else{
        notify(res.resMessage);
      }
      gridRef.current?.instance().endCustomLoading();
    })
  }

  useEffect(()=>{
    getList();
  },[])

  const [isPanelOpened, setPanelOpened] = useState(false);

  const changeSelectProductPopup = useCallback(() => {
        setPanelOpened(!isPanelOpened);

}, [isPanelOpened]);

  const onRowClick = useCallback(({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
    setRowData(data);
  //  setPanelOpened(true);

  }, []);

  const onRowRemoving = (e) => {
    const product = e.data;
    generalNewProductsDeleteRequest(product.segmentProductRef).then((res)=>{
      if(res.resCode == 200){
        notify(t("successfull"),"success");
      }else{
        notify(res.resMessage,"error");
        getList();
      }
    });

  };

  const onSelectionChanged = (e) => {
    setSelectedRows(e.selectedRowsData);
};

async function deleteSelected(){
  const result = await confirm(t("areYouSureDeleting"),t("deleteProcess"));
    if (result) {
      gridRef.current?.instance().beginCustomLoading(t("loading"));
      var i = 0;
      selectedRows.forEach((row, index) => {

          generalNewProductsDeleteRequest(row["segmentProductRef"]).then((response)=>{
              if(response["resCode"]==200){
              }else{
                  notify(response["resMessage"],"error");
              }
              i++;
              if(i==selectedRows.length){
                  gridRef.current?.instance().endCustomLoading();
                  getList();
              }
          });
      });
    }
}

const handleContentReady = (e) => {
  setRowCount(e.component.totalCount());
};

const onReorder = (e) => {
  e.promise = processReorder(e);
};

const processReorder = async (e: any) => {

  const updatedProducts = [...products];

  const [movedItem] = updatedProducts.splice(e.fromIndex, 1);

  updatedProducts.splice(e.toIndex, 0, movedItem);

  setProducts(updatedProducts);

  /*

  movedItem["newRouteLineNr"] = e.toIndex + 1;

  updatedProducts.forEach((item, index) => {
      item["routeLineNr"] = index + 1;
  });

  const response = await salesRouteLineUpdateRequest(movedItem);

  if (response.resCode == 200) {

      setProducts(updatedProducts);
  } else {
      alert("olmadı");
  }

  */
  await e.component.refresh();
};

  return (
    <div className="view crm-contact-list">
      <div className="view-wrapper view-wrapper-contact-list list-page">
      <DataGrid
          className='grid theme-dependent'
          focusedRowEnabled
          dataSource={products}
          keyExpr="segmentProductRef"
          onRowClick={onRowClick}
          onExporting={onExporting}
          onRowRemoving={onRowRemoving}
          onSelectionChanged={onSelectionChanged}
          noDataText={t("noData")}
          onContentReady={handleContentReady}
          allowColumnReordering
          allowColumnResizing
          showBorders
          ref={gridRef}
          editing={{
            mode: 'row',
            allowDeleting: true,
        }}>
          <Editing texts={{
                        editRow: t("edit"),
                        deleteRow: t("delete"),
                        confirmDeleteMessage: t("confirmDeleteMessage"),
                        confirmDeleteYes: t("yes"),
                        cancelRowChanges: t("no"),
                    }}  />

          <LoadPanel />
          <SearchPanel width={250} visible placeholder={t("customerSearch")}  />
          <ColumnChooser title={t("columnChooser")} enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <RowDragging allowReordering={true} onReorder={onReorder} dropFeedbackMode="push" />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

          <Toolbar>


          <Item name='searchPanel' location='before'  />

          <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                hint={t("refresh")}
                stylingMode='text'
                onClick={getList}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before' />

            <Item location="before">
                        <DropDownButton
                            visible={selectedRows.length>0}
                            text={"("+ selectedRows.length+") "+ t("transactions")}
                            icon="menu"
                            width={"175"}
                            items={[
                            { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                            ]}
                            onItemClick={(e) => {
                            if(e.itemData.action=="deleteSelected"){
                                deleteSelected();
                            }
                            }}
                        />
                        </Item>

                        <Item location='center' locateInMenu='auto'>
                        {rowCount} {t("records")}
                        </Item>

            <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                type='default'
                stylingMode='contained'
                visible={true}
                onClick={changeSelectProductPopup}
              />
            </Item>

          </Toolbar>

          <Column dataField='itemCode' caption={t("productCode")} />
          <Column dataField='itemName' caption={t("productName")} />
          <Column dataField='comment' caption={t("comment")} />

        </DataGrid>
      </div>
      <NewProductsProductSearch visible={isPanelOpened} onHiding={changeSelectProductPopup} getList={getList} />
    </div>
  );
};

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('NewProductsProducts.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'NewProductsProducts.xlsx');
      });
    });
    e.cancel = true;
  }
};
