
import React, { useEffect, useState } from 'react';
import {  CustomerUserModel } from '../../../types/crm-contact';
import { Button, Popup, TextBox } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import { newCustomerUserModel } from '../../../shared/constants';
import { b2bUsersListUpdateWithCustomerRequest } from '../../../api/requests';
import notify from 'devextreme/ui/notify';

export const CustomerUserNewForm = ({visible, onHiding, customer, refresh}) => {
  const [newContactData, setNewContactData] = useState<CustomerUserModel>(newCustomerUserModel);
  const {t} = useTranslation();


  useEffect(() => {
    setNewContactData(newCustomerUserModel);
  }, [visible]);

  const updateField = (key) => (value) => {
    setNewContactData(prev => ({
      ...prev,
      [key]: value
    }));
  };

  function onSaveClick(){
    b2bUsersListUpdateWithCustomerRequest(newContactData,customer).then((res)=>{
      if(res.resCode == 200){
        refresh();
        notify({
          message: "Kullanıcı başarıyla eklendi",
          position: { at: 'bottom center', my: 'bottom center' }
        },'success');
        onHiding();

      }else{
        notify(res.resMessage,'error');
      }
    });
  }

  function onCancelClick(){
    onHiding();
  }

  return (
    <Popup
    visible={visible}
    onHiding={onHiding}
    width={500}
    height={"auto"}
    titleRender={()=>(
      <div className="flex flex-row justify-between">
        <div className="flex flex-row">
        <Button icon="close" stylingMode="text" onClick={onHiding}></Button>
        <p className="ml-2 mt-0.5 justify-center font-bold text-lg">{t("newUser")}</p>
        </div>

        <div className='flex flex-row gap-x-2'>

                  <Button text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
                  <Button text={t("cancel")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={onCancelClick}/>
                </div>

      </div>
    )}
    contentRender={()=>
    (
      <div className='flex flex-col gap-y-3'>



          <TextBox
            label={t("userName")}
            value={newContactData?.userName}
            onValueChange={updateField('userName')}
          />

          <TextBox
            value={newContactData?.userEmail}
            onValueChange={updateField('userEmail')}
            label={t("email")}
          />

          <TextBox
            label={t("password")}
            value={newContactData?.userPassword}
            onValueChange={updateField('userPassword')}

          />

          <TextBox
            value={newContactData?.comment}
            onValueChange={updateField('comment')}
            label={t("comment")}
          />
    </div>
    )}>

    </Popup>
  );
};
