import React, { useCallback, useEffect, useRef, useState } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import './general-product-images.scss';
import TreeView, { TreeViewRef, TreeView as TreeViewType } from 'devextreme-react/tree-view';
import DataGrid, {
  Column, ColumnChooser, Export, HeaderFilter, LoadPanel, SearchPanel,
  Selection, Sorting, Scrolling, Toolbar, Item,
  ToolbarItem,
  DataGridRef,
  DataGridTypes,
  FilterPanel,
  FilterBuilderPopup,
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { generalProductImagesListRequest } from '../../api/requests';
import { ImageStatus } from '../../components/utils/contact-status/ContactStatus';
import { ProductImageModel } from '../../types/crm-contact';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';
import { ContactStatus as ContactStatusType } from '../../types/crm-contact';
import ImageEditPopup from '../../components/library/general-product-images-detail/popup';
import uploadImage from "../../assets/upload-image-s.png";
import { appInfo } from '../../app-info';


const API_BASE_URL = appInfo().imageServerURL;
const exportFormats = ['xlsx', 'pdf'];
type FilterContactStatus = ContactStatusType | 'active';

export const GeneralProductImages = () => {
  const { t } = useTranslation();
  const [productImages, setProductImages] = useState<ProductImageModel[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null);
  const [rowData, setRowData] = useState<ProductImageModel | null>(null);
  const [isPanelOpened, setPanelOpened] = useState(false);
  const [itemActive, setItemActive] = useState(t("active"));
  const [filteredProducts, setFilteredProducts] = useState<ProductImageModel[]>([]);
  const gridRef = useRef<DataGridRef>(null);
  const filterStatusList = [t("all"), t("active"), t("passive")];
  const dropDownOptions = { width: 'auto' };
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [renamedFile, setRenamedFile] = useState<File | null>(null);
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);
  const treeViewRef = useRef<TreeViewRef | null>(null);
  const [isAllProductsVisible, setIsAllProductsVisible] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [images, setImages] = useState([]);


  useEffect(() => {
    getList();
  }, []);



  const getList = async () => {

    try {
      gridRef.current?.instance().beginCustomLoading(t("loading"));
      const response = await generalProductImagesListRequest();
      const data = response.dataSet || [];
      setProductImages(data);
      const categories = createCategories(data);
      setCategories(categories);
      // İlk kategoriye ait ürünleri ayarlama
      if (categories.length > 0) {
        const firstCategory = categories[0].text;
        const filtered = data.filter(item => item.itemCategory === firstCategory);
        setFilteredProducts(filtered);
        setSelectedCategory(firstCategory);
      }
      filterByStatus({item:t("active")});
    } catch (error) {
      console.error('Error fetching product list:', error);
    }finally{
      gridRef.current?.instance().endCustomLoading();
    }
  };

  const filterByStatus = useCallback((e) => {
    const { item } = e;
    if (item === t("all")) {
      gridRef.current?.instance().clearFilter();
      setItemActive(item);
    } else {
      const isActive = item === t("active") ? "0" : "1";
      gridRef.current?.instance().filter(['itemActive', '=', isActive]);
      setItemActive(item);
    }
  }, [t]);

  const onRowClick = useCallback((e) => {

    if (e.event.target.closest('button') || e.event.target.closest('.dx-button')) {
      e.event.stopPropagation();
      e.event.preventDefault();
      return;
    }
    setRowData(e.data);
    setPanelOpened(true);
  }, []);

  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
  }, [isPanelOpened]);

  const handleDownload = async (itemCode) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/download`, { itemCode }, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${itemCode}.jpg`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      notify('File downloaded successfully', 'success');
    } catch (error) {
      console.error('File download error:', error);
      notify('File download error: ' + error, 'error');
    }
  };

  const handleDelete = async (itemCode) => {
    const result = await confirm("Bu resmi silmek istediğinize emin misiniz?", "Silme Onayı");
    if (result) {
      try {
        await axios.post(`${API_BASE_URL}/delete`, { itemCode });
        if (gridRef.current) {
          gridRef.current.instance().refresh();
        }
        // window.location.reload();
        notify('File deleted successfully', 'success');
      } catch (error) {
        console.error('File delete error:', error);
        notify('File delete error: ' + error, 'error');
      }
    } else {
      notify('Silme işlemi iptal edildi', 'info', 2000);
    }
  };

  const handleUpload = async (file) => {
    gridRef.current?.instance().beginCustomLoading(t("loading"))
    if (!file) {
      notify('Please select a file!', 'warning');
      return;
    }
    const formData = new FormData();
    formData.append('image', file);
    try {
      const response = await axios.post(`${API_BASE_URL}/upload`, formData);
    if (gridRef.current) {
      gridRef.current.instance().refresh();
    }

    // window.location.reload();
      setRefreshKey(prevKey => prevKey + 1);
      notify(response.status === 200 ? 'File uploaded successfully' : 'Upload error occurred', response.status === 200 ? 'success' : 'error');
    } catch (error) {
      console.error('Upload error:', error);
      notify('Upload error occurred: ' + error, 'error');
    }
    finally{
      gridRef.current?.instance().endCustomLoading();

    }
  };
     // Dosya sürükleyip bırakma
     const onDragOver = (e) => {
      e.preventDefault();
    };
    // Butonla dosya seçme
    const handleImageButtonClick = (itemCode: string) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.style.display = 'none';
      document.body.appendChild(input);
      input.click();
      input.addEventListener('change', async (event) => {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
          const newFileName = `${itemCode}.${file.name.split('.').pop()}`;
          const newRenamedFile = new File([file], newFileName, { type: file.type });
          setRenamedFile(newRenamedFile);

          // Yükleme işlemi sadece dosya seçildikten sonra yapılır
          await handleUpload(newRenamedFile);
          setRenamedFile(null);  // Yükleme tamamlandıktan sonra state sıfırlanır
        }
        document.body.removeChild(input);
      });
    };
  const handleFileDrop = async (e, itemCode) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];
    if (file) {
      const newFileName = `${itemCode}.${file.name.split('.').pop()}`;
      const newFile = new File([file], newFileName, { type: file.type });
      await handleUpload(newFile);
    }
  };

  const createCategories = (data: Array<{ itemCategory: string, itemSubCategory: string }>) => {
    const categoryMap = new Map<
      string,
      { subCategories: Map<string, { count: number }>, count: number }
    >();

    data.forEach(item => {
      if (!categoryMap.has(item.itemCategory)) {
        categoryMap.set(item.itemCategory, { subCategories: new Map(), count: 0 });
      }

      const category = categoryMap.get(item.itemCategory)!; // Non-null assertion to avoid undefined
      category.count += 1;

      if (!category.subCategories.has(item.itemSubCategory)) {
        category.subCategories.set(item.itemSubCategory, { count: 0 });
      }

      const subCategory = category.subCategories.get(item.itemSubCategory)!; // Non-null assertion
      subCategory.count += 1;
    });

    return Array.from(categoryMap.entries())
      .sort(([a], [b]) => a.localeCompare(b, undefined, { numeric: true })) // Kategoriyi alfabetik ve sayısal sırayla sırala
      .map(([category, { subCategories, count }]) => ({
        id: category,
        text: `${category}`,
        count: `${count}`,
        hasChildren: subCategories.size > 0,
        img: `${appInfo().imageURL}/category/${category}.jpg`, // Kategori için img
        items: Array.from(subCategories.entries())
          .sort(([a], [b]) => a.localeCompare(b, undefined, { numeric: true })) // Alt kategoriyi alfabetik ve sayısal sırayla sırala
          .map(([subCategory, subCategoryData]) => ({
            id: `${category}-${subCategory}`,
            category:`${category}`,
            text: `${subCategory}`,
            count: `${subCategoryData.count}`,
            hasChildren: false,
            img: `${appInfo().imageURL}/subcategory/${subCategory}.jpg`, // Alt kategori için img
          })),
      }));
  };



  const handleCategorySelect = (e) => {
    const selectedCategory = e.node.itemData.text; // Seçilen kategori
    setSelectedCategory(selectedCategory); // Kategoriyi ayarlayın
    setSelectedSubCategory(null); // Alt kategoriyi sıfırlayın

    // Seçilen kategoriye ait tüm ürünleri göster
    const filtered = productImages.filter(item => item.itemCategory === selectedCategory);

    setFilteredProducts(filtered);
    setIsAllProductsVisible(false);


  };

  const handleSubCategorySelect = (e) => {
    const selectedItem = e.node.itemData; // Seçilen alt kategori
    if (selectedItem) {
      setSelectedSubCategory(selectedItem.text); // Alt kategoriyi güncelle
      const filtered = productImages.filter(item =>
        item.itemCategory ===selectedItem.category && // Kategori adı
        item.itemSubCategory === selectedItem.text // Alt kategori adı
      );

      setFilteredProducts(filtered); // Ürünleri filtrele
    }
  };

  //treeview resim ekleme
  const handleDrop = async (e, item) => {
    e.preventDefault();

      const file = e.dataTransfer.files[0];
      const isCategory = !item.hasChildren; // Eğer children yoksa, bu bir alt kategori demektir.
      const folder = isCategory ? 'subcategory' : 'category'; // Yükleme dizini
      const normalizedFileName = item.text;
      // const newFileName = `${item.text}.${file.name.split('.').pop()}`;
      const newFileName = `${normalizedFileName}.${file.name.split('.').pop()}`;
      const newFile = new File([file], newFileName, { type: file.type });
      await uploadImageFtp(newFile, folder);

  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const onItemClick = (e) => {
    const treeView = treeViewRef.current?.instance(); // Güvenli erişim
    const selectedItem = e?.node?.itemData;

    if (treeView && selectedItem) {
        // treeView.collapseAll(); // Tüm öğeleri kapat

        if (selectedItem.items && selectedItem.items.length > 0) {
            handleCategorySelect(e);
            treeView.expandItem(selectedItem.path); // Seçilen öğeyi genişlet
        } else {
            handleSubCategorySelect(e);
        }
    }
};
//treeview e image ekleme fonk
  const uploadImageFtp = async (file, folder) => {
    gridRef.current?.instance().beginCustomLoading(t("loading"))
    if (!file) {
      notify('Please select a file!', 'warning');
      return;
    }
    const formData = new FormData();
    formData.append('image', file);
    formData.append('folder', folder);
    try {
      const response = await axios.post(`${API_BASE_URL}/upload`, formData);
      notify(response.status === 200 ? 'File uploaded successfully' : 'Upload error occurred', response.status === 200 ? 'success' : 'error');
      setRefreshKey(prevKey => prevKey + 1);
    } catch (error) {
      console.error('Upload error:', error);
      notify('Upload error occurred: ' + error, 'error');
    }
    finally{
      gridRef.current?.instance().endCustomLoading();
    }
  };
  const handleShowAllProducts = useCallback(() => {
    // Clear selected category and subcategory
    setSelectedCategory(null);
    setSelectedSubCategory(null);

    // Show all products
    setFilteredProducts(productImages);
    setIsAllProductsVisible(true);
  }, [productImages]);

  const refreshImage = (imgSrc) => {
    const timestamp = new Date().getTime();
    return `${imgSrc}?timestamp=${timestamp}`;
  };
  const renderTitleHeader = (data) => {
    return <div style={{
      fontFamily:"sans-serif",
      fontSize: '13px',
      fontWeight:"400",
     }}>{data.column.caption}</div>;
};

  return (

    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
      <div style={{ display: 'flex', flex: 1 }}>
  {/* Sol Kısım: Kategori ve Alt Kategori Menüsü */}
 <div style={{ width: '300px', display: 'flex', flexDirection: 'column', height: '100%', marginRight:10 }}>
  {/* Toolbar alanı */}
  <div style={{height:"56px"}}>
  <DataGrid className='grid theme-dependent' showBorders   >
      <Toolbar>
        <Item location='before'>
          <Button
          icon='mediumiconslayout'
          text={t("allProducts")}
          stylingMode='contained'
          onClick={handleShowAllProducts}  />
        </Item>
      </Toolbar>
    </DataGrid>

    </div>

  {/* TreeView alanı */}
  <div className='treeview-container' style={{ flex: 1, marginTop: '26px', marginLeft: '0', paddingLeft: '0', overflowY: 'auto', borderRadius:"8px", overflow: 'hidden', backgroundColor: '#fff', border: '1px solid #ccc',zIndex:1 }}>
  <div className='menu-container theme-dependent' style={{ height: '100%', overflow: 'hidden' }}>
  <TreeView
        className="navigation-menu"
        noDataText=''
        selectionMode='single'
        focusStateEnabled={false}
        expandEvent='click'
        ref={treeViewRef}
        itemRender={(item) => (
          <div  className={`menu-item ${item.hasChildren ? 'category-item' : ''}`}
          onError={(e) => {
            const imgElement = e.target as HTMLImageElement;
            imgElement.src = uploadImage;
          }}
          // draggable
          onDrop={(e) => handleDrop(e, item)}  // 'item' nesnesini gönderiyoruz
          onDragOver={(e) => handleDragOver(e)}>
            <img
            src={refreshImage(item.img)}

              style={{ width: '25px',height:'25px', marginRight: '10px' }} />

            <span style={{flexGrow: 1,marginRight: '10px',}} > {item.text} </span>
             <span style={{ fontWeight: 'normal' }}>{item.count}</span>
          </div>
        )}
        items={categories}
        onItemClick={onItemClick}
      />
    </div>
  </div>
</div>
          {/* Sağ Kısım: DataGrid */}
          <div style={{ flex: 1 }}>
          <DataGrid
                dataSource={filteredProducts}
                // className='grid theme-dependent'
                className='custom-grid grid theme-dependent'
                height='100%'
                width='100%'
                allowColumnReordering
                allowColumnResizing={true}
                onRowClick={onRowClick}
                onExporting={onExporting}
                showBorders={true}
                ref={gridRef}
                columnAutoWidth={false}
                noDataText={t("noData")}
                keyExpr={'itemRef'}
                onRowPrepared={(e) => {
                  if (e.rowType === 'data') {
                    e.rowElement.addEventListener('drop', (event) => handleFileDrop(event, e.data.itemCode));
                    e.rowElement.addEventListener('dragover', onDragOver);
                  }
                }}

              >
                <LoadPanel showPane={false} />
                <SearchPanel visible placeholder={t('productSearch')} />
                <ColumnChooser enabled />
                <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
                <Selection mode='multiple' />
                <HeaderFilter visible />
                <Sorting mode='multiple' />
                <Scrolling mode='virtual' />
                <FilterPanel visible={true}/>
                <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

                <Toolbar>
                  <Item location='before'>
                    <DropDownButton
                      items={[t("all"), t("active"), t("passive")]}
                      stylingMode='text'
                      text={itemActive}
                      onSelectionChanged={filterByStatus}
                    />
                  </Item>
                  <Item name='searchPanel' location='before' />
                  <Item location='before'>
                    <Button
                      icon='refresh'
                      text=''
                      stylingMode='text'
                      onClick={getList}
                    />
                  </Item>
                  <Item location='before' name='columnChooserButton' />
                  <Item location='before' name='exportButton' />
                </Toolbar>

                <Column dataField='itemCode'  caption={t("productImage")} width={120} headerCellRender={renderTitleHeader} cellRender={(cellData) => (
                  <center>
                  <img src={refreshImage(`${appInfo().imageURL}/thumbs/TH${cellData.value}.jpg`)}
                  style={{ height: '50px', width: 'auto', objectFit: 'contain' }}

                  onError={(e) => {
                    const imgElement = e.target as HTMLImageElement;
                    imgElement.src = uploadImage;
                  }} />
                  </center>

                )} />
                <Column dataField='itemCode' caption={t("productCode")} width="120"  headerCellRender={renderTitleHeader}/>
                <Column dataField='itemActive' caption={t("status")} width={100} headerCellRender={renderTitleHeader} cellRender={ImageStatus} />
                <Column dataField='itemName' caption={t("productName")} headerCellRender={renderTitleHeader} />
                <Column dataField='itemCategory' caption={t("category")} width="10%"  headerCellRender={renderTitleHeader}/>
                <Column dataField='itemSubCategory' caption={t("subCategory")} width="10%" headerCellRender={renderTitleHeader}/>
                <Column dataField='producerCode' caption={t("producerCode")} visible={false} headerCellRender={renderTitleHeader}/>
                <Column dataField='speCode1' caption={t("specCode1")} visible={false} width="10%" headerCellRender={renderTitleHeader}/>
                <Column dataField='speCode2' caption={t("specCode2")} visible={false}  width="10%" headerCellRender={renderTitleHeader}/>
                <Column dataField='speCode3' caption={t("specCode3")} visible={false} headerCellRender={renderTitleHeader}/>
                <Column dataField='speCode4' caption={t("specCode4")} visible={false} headerCellRender={renderTitleHeader}/>
                <Column dataField='speCode5' caption={t("specCode5")} visible={false} headerCellRender={renderTitleHeader}/>
                <Column caption={t("actions")} alignment='center' width="140" headerCellRender={renderTitleHeader} cellRender={({ data }) => (
                  <div>
                  <Button
                    icon='upload'
                    onClick={() => handleImageButtonClick(data.itemCode)}
                    style={{ marginRight: '5px' }}
                  />
                  <Button
                    icon='download'
                    onClick={() => handleDownload(data.itemCode)}
                    style={{ marginRight: '5px' }}
                  />
                  <Button
                    icon='trash'
                    onClick={() => handleDelete(data.itemCode)}
                  />
                </div>
                )} />


              </DataGrid>

          </div>
        </div>
        <ImageEditPopup
            rowData={rowData}
            isPanelOpened={isPanelOpened}
            changePanelOpened={changePanelOpened}
          />
      </div>
    </div>
  );
};


const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Products.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Products.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto',  };
