
import React, { useEffect, useState } from 'react';
import { Button, DataGrid, Popup, SelectBox, TextBox } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { campaignGroupInsertRequest } from '../../../api/requests';
import { status_options, status_selectbox } from '../../../shared/status-selectbox';
import CampaignGroupTabView from './tabView';

export const CampaignGroupAddPopup = ({visible, onHiding, refresh, campaignGroup}) => {
  const [newCampaignGroup, setNewCampaignGroup] = useState(campaignGroup);
  const {t} = useTranslation();
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    setNewCampaignGroup(campaignGroup);
    setSelectedStatus(campaignGroup?.groupStatus);
    if(campaignGroup?.groupRef == 0){
      setReadOnly(false)
    }else{
      setReadOnly(true)
    }
  }, [visible]);

  const updateField = (key) => (value) => {
    setNewCampaignGroup(prev => ({
      ...prev,
      [key]: value
    }));
  };

  function onSaveClick(){
    campaignGroupInsertRequest(newCampaignGroup).then((res)=>{
      if(res.resCode == 200){
        refresh();
        notify(t("successfull"),'success');
        onHiding();

      }else{
        notify(res.resMessage,'error');
      }
    });
  }

  const [selectedStatus, setSelectedStatus] = useState<number>(0);

  const statusChange = (e: any) => {
    setSelectedStatus(e.value);
    updateField('groupStatus')(e.value);
  };

  return (
    <Popup
    visible={visible}
    onHiding={onHiding}
    height={"80%"}
    titleRender={() => (
        <div className="flex flex-row justify-between py-2">
            <div className="flex flex-row">
                <Button icon="close" stylingMode="text" onClick={onHiding}></Button>
                <p className="ml-2 mt-0.5 justify-center font-bold text-lg">
                    {campaignGroup.groupRef == 0 ? t("new") : campaignGroup.groupName}
                </p>
            </div>
        </div>
    )}
    contentRender={() => (
        <div className="flex flex-col lg:flex-row gap-4 p-4">
            {/* Sol taraf - Form */}
            <div className="flex flex-col gap-y-3 w-full lg:w-2/5 shadow-sm border border-gray-300 p-5 rounded-md">
                <div className="flex justify-between">
                    <div className="flex w-28 pr-4">
                        <SelectBox
                            dataSource={status_options}
                            value={selectedStatus}
                            defaultValue={1}
                            displayExpr={(item) => t(item.label)}
                            valueExpr="value"
                            onValueChanged={statusChange}
                            itemRender={status_selectbox}
                            searchEnabled={false}
                            readOnly={readOnly}
                        />
                    </div>
                    <div className="flex flex-row gap-x-2">
                        <Button
                            text={t("save")}
                            icon="save"
                            stylingMode="contained"
                            type="default"
                            onClick={onSaveClick}
                            visible={!readOnly}
                        />
                        <Button
                            text={t("cancel")}
                            icon="close"
                            stylingMode="contained"
                            style={{ backgroundColor: "gray" }}
                            type="default"
                            onClick={()=>{
                              if(campaignGroup.groupRef == 0){
                                onHiding();
                              }else{
                                setReadOnly(true)
                              }
                            }}
                            visible={!readOnly}
                        />
                        <Button visible={readOnly} text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={()=>setReadOnly(false)} />
                        <Button visible={readOnly} text={t("close")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={onHiding} />
                    </div>
                </div>
                <TextBox
                    value={newCampaignGroup?.groupCode}
                    onValueChange={updateField("groupCode")}
                    label={t("groupCode")}
                    readOnly={readOnly}
                />
                <TextBox
                    label={t("groupName")}
                    value={newCampaignGroup?.groupName}
                    onValueChange={updateField("groupName")}
                    readOnly={readOnly}
                />
                <TextBox
                    value={newCampaignGroup?.comment}
                    onValueChange={updateField("comment")}
                    label={t("comment")}
                    readOnly={readOnly}
                />
            </div>
            {/* Sağ taraf - DataGrid */}
            <CampaignGroupTabView groupRef={campaignGroup?.groupRef}></CampaignGroupTabView>
        </div>
    )}
>
</Popup>

  );
};
