

export const productSelectionTypes = (t) => [
    {productSelectionType: 0, title: t("allProducts"),},
    {productSelectionType: 1, title: t("asProductGroup"),},
    {productSelectionType: 2, title: t("onlySelectedProducts"),},
  ];

 export const customerSelectionTypes = (t) => [
    {customerSelectionType: 0, title: t("allCustomers"),},
    {customerSelectionType: 1, title: t("asCustomerGroup"),},
    {customerSelectionType: 2, title: t("onlySelectedCustomers"),},
  ];
