import React from 'react';
import Chart, {
  ArgumentAxis,
  Label,
  Legend,
  Series,
  Size,
} from 'devextreme-react/chart';
import { CardAnalytics } from '../../library/card-analytics/CardAnalytics';

const CitiesGraph = ({cityData,title})=> {
  return (
    <CardAnalytics title={title} contentClass='conversion-funnel'>
    <Chart
      dataSource={cityData}
      id="chart"

    >
      <ArgumentAxis tickInterval={10}>
        <Label format="decimal" />
      </ArgumentAxis>

      <Series
        type="bar"
      />

      <Legend
        visible={false}
      />
      <Size height={270} />
    </Chart>
    </CardAnalytics>
  );
}

export default CitiesGraph;
