
import React, { useState, useCallback, useEffect } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import classNames from 'classnames';
import { Button, ButtonTypes } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { useScreenSize } from '../../../utils/media-query';
import ValidationGroup from 'devextreme-react/validation-group';
import { useTranslation } from 'react-i18next';
import { CustomerGroupModel } from '../../../types/crm-contact';
import { TextBox } from 'devextreme-react';
import { customerGroupUpdateRequest } from '../../../api/requests';
import notify from 'devextreme/ui/notify';


export const CustomerGroupPanelDetails = ({mainRefresh, customerGroup, isOpened, changePanelOpened, onDataChanged, changePanelPinned } : {mainRefresh:() => void, customerGroup: CustomerGroupModel, isOpened: boolean, changePanelOpened:(value: boolean)=> void, onDataChanged:(data)=> void, changePanelPinned:() => void }) => {
  const [isPinned, setIsPinned] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const { isLarge, isMedium } = useScreenSize();
  const [data, setData] = useState(customerGroup);

  const updateField = (key) => (value) => {
    setData(prev => ({
      ...prev,
      [key]: value
    }));
  };

  useEffect(()=>{
    setData(customerGroup)
  },[customerGroup.customerGroupRef,isOpened])

  const onPinClick = useCallback(() => {
    setIsPinned(!isPinned);
  }, [isPinned]);

  function onClosePanelClick ()  {
    setIsPinned(false);
    changePanelOpened(false);
  };

  const onSaveClick = useCallback(({ validationGroup } : ButtonTypes.ClickEvent) => {
    customerGroupUpdateRequest(data).then((res)=>{
      if(res.resCode == 200){
        notify(t("successfull"),"success");
        mainRefresh();
        onClosePanelClick();
      }else{
        notify(res.resMessage,"error");
      }
    });
  }, [data]);

  const { t } = useTranslation();

  return (
    <div id='contact-panel' className={classNames({ 'panel': true, 'open': isOpened, 'pin': isPinned && (isLarge || isMedium) })}>
      <div className='data-wrapper'>
        <Toolbar className='panel-toolbar'>
          <ToolbarItem location='before'>
            <span className='contact-name value'>{data.customerGroupName}</span>
          </ToolbarItem>

          <ToolbarItem
            location='after'
            visible={isLarge || isMedium}
          >
            <Button
              icon={isPinned ? 'pin' : 'unpin'}
              stylingMode='text'
              onClick={onPinClick}
            />
          </ToolbarItem>
          <ToolbarItem location='after'>
            <Button
              icon='close'
              stylingMode='text'
              onClick={onClosePanelClick}
            />
          </ToolbarItem>
        </Toolbar>
        <ScrollView className='panel-scroll'>

          <ValidationGroup>
            <div className='data-part'>
              <div
                className={classNames({ 'plain-styled-form': true, 'view-mode': !isEditing })}
              >
                <ToolbarItem location='before'>

            <CheckBox
              value={data.customerActive==1}
              text={t("active")}
              onValueChanged={(e) => updateField('customerActive')(e.value ? 1 : 0)}
            />
          </ToolbarItem>


              <div className='p-2 my-4 w-full flex flex-col  gap-4'>

                  <div >
                    <TextBox
                      label={t("groupCode")}
                      value={data.customerGroupCode}
                      onValueChange={updateField('customerGroupCode')}
                    />
                  </div>

                  <div >
                    <TextBox
                      label={t("groupName")}
                      value={data.customerGroupName}
                      onValueChange={updateField('customerGroupName')}

                    />
                  </div>

                  <div >
                    <TextBox
                      label={t("salesmanCode")}
                      value={data["erpSalesManCode"]}
                      onValueChange={updateField('erpSalesManCode')}
                    />
                  </div>

                  <div >
                    <TextBox
                      label="ERP Warehouse"
                      value={data["erpWarehouse"].toString()}
                      onValueChange={updateField('erpWarehouse')}
                    />
                  </div>

                  <div >
                    <TextBox
                      label="ERP Firm No"
                      value={data["erpFirmNr"].toString()}
                      onValueChange={updateField('erpFirmNr')}
                    />
                  </div>

                  <div >
                    <TextBox
                      label="ERP Branch"
                      value={data["erpBranch"].toString()}
                      onValueChange={updateField('erpBranch')}
                    />
                  </div>

                  <div>
                    <TextBox
                      label={t("comment")}
                      value={data["comment"]}
                      onValueChange={updateField('comment')}
                    />
                  </div>

                  <div className='w-full flex'>
                    <div className='pt-3 ml-auto'>
                      <Button text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
                    </div>
                  </div>

              </div>
            </div>
          </div>



          </ValidationGroup>

        </ScrollView>
      </div>
    </div>
  );
};
