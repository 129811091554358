
import React, { useEffect, useState, useCallback } from 'react';

import './ContactPanel.scss';

import { withLoadPanel } from '../../../utils/withLoadPanel';
import { CustomerGroupPanelDetails } from './ContactPanelDetails';
import { CustomerGroupModel } from '../../../types/crm-contact';


const ContactPanelWithLoadPanel = withLoadPanel(CustomerGroupPanelDetails);

export const ContactPanel = ({mainRefresh, rowData, isOpened, changePanelOpened, changePanelPinned } ) => {

  const [data, setData] = useState<CustomerGroupModel>(rowData);



  const onDataChanged = useCallback(data => {
    setData(data);
  }, []);

  useEffect(() => {
    setData(rowData);
  }, [rowData]);

  return (
    <ContactPanelWithLoadPanel
      mainRefresh={mainRefresh}
      customerGroup={data}
      hasData={!!data}
      isOpened={isOpened}
      onDataChanged={onDataChanged}
      changePanelOpened={changePanelOpened}
      changePanelPinned={changePanelPinned}
      panelProps={{
        position: { of: '.panel' },
        container: '.panel'
      }}
    />
  );
};

