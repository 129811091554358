import React from "react";
import { formatPriceAmount } from "./formats";

const invoiceEmailBody = (data) => {
  const {
    invoiceGeneral,
    supplierData,
    buyerData,
    paymentInformation,
    invoiceHeader,
    InvoiceLines,
  } = data;

  const invoice = invoiceGeneral[0];
  const supplier = supplierData[0];
  const buyer = buyerData[0];
  const payment = paymentInformation[0];
  const header = invoiceHeader[0];

  return (
    <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6", }}>
      <h2 style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}>
        Fatura Detayları: {invoice.invoiceID}
      </h2>
      <b>{buyer.buyerRegistrationName}</b>
      <p>
        Aşağıda, {new Date(invoice.issueDate).toLocaleDateString()} tarihli{" "}
        {invoice.invoiceID} numaralı faturanıza ait detayları bulabilirsiniz.
      </p>

      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
  <div style={{ marginTop: "20px" }}>
    <h3 style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "underline" }}>Fatura Bilgileri</h3>
    <ul style={{ listStyleType: "none", padding: "0" }}>
      <li style={{ marginBottom: "10px" }}><strong>Fatura Tarihi:</strong> {new Date(invoice.issueDate).toLocaleDateString()}</li>
      <li style={{ marginBottom: "10px" }}><strong>Fatura Türü:</strong> {invoice.invoiceTypeCode}</li>
      <li style={{ marginBottom: "10px" }}><strong>Para Birimi:</strong> {invoice.invoiceCurrency}</li>
    </ul>
  </div>

  <div style={{ marginTop: "20px" }}>
    <h3 style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "underline" }}>Tedarikçi Bilgileri</h3>
    <ul style={{ listStyleType: "none", padding: "0" }}>
      <li style={{ marginBottom: "10px" }}><strong>Tedarikçi Adı:</strong> {supplier.supplierName}</li>
      <li style={{ marginBottom: "10px" }}><strong>Adres:</strong> {supplier.supplierStreetName}, {supplier.supplierCityName}, {supplier.supplierPostalZone}, {supplier.supplierCountryCode}</li>
      <li style={{ marginBottom: "10px" }}><strong>Vergi Numarası:</strong> {supplier.supplierVATID}</li>
    </ul>
  </div>

  <div style={{ marginTop: "20px" }}>
    <h3 style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "underline" }}>Alıcı Bilgileri</h3>
    <ul style={{ listStyleType: "none", padding: "0" }}>
      <li style={{ marginBottom: "10px" }}><strong>Alıcı Adı:</strong> {buyer.buyerRegistrationName}</li>
      <li style={{ marginBottom: "10px" }}><strong>Adres:</strong> {buyer.buyerStreetName}, {buyer.buyerCityName}, {buyer.buyerPostalZone}</li>
    </ul>
  </div>

  <div style={{ marginTop: "20px" }}>
    <h3 style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "underline" }}>Ödeme Bilgileri</h3>
    <ul style={{ listStyleType: "none", padding: "0" }}>
      <li style={{ marginBottom: "10px" }}><strong>Ödeme Yöntemi:</strong> {payment.paymentMeansCode}</li>
      <li style={{ marginBottom: "10px" }}><strong>IBAN:</strong> {payment.supplierIBAN}</li>
    </ul>
  </div>
</div>


      <h3 style={{ fontSize: "16px", fontWeight: "bold", marginTop: "20px" }}>
        Fatura Kalemleri
      </h3>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid ",
          marginTop: "10px",
        }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid ", padding: "8px" }}>Sıra No</th>
            <th style={{ border: "1px solid ", padding: "8px" }}>Ürün Kodu</th>
            <th style={{ border: "1px solid ", padding: "8px" }}>Ürün Adı</th>
            <th style={{ border: "1px solid ", padding: "8px" }}>Miktar</th>
            <th style={{ border: "1px solid ", padding: "8px" }}>Birim Fiyat</th>
            <th style={{ border: "1px solid ", padding: "8px" }}>KDV Oranı</th>
            <th style={{ border: "1px solid ", padding: "8px" }}>KDV Dahil Tutar</th>
          </tr>
        </thead>
        <tbody>
          {InvoiceLines.map((line, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid ", padding: "8px" }}>{line.lineNr}</td>
              <td style={{ border: "1px solid ", padding: "8px" }}>{line.itemCode}</td>
              <td style={{ border: "1px solid ", padding: "8px" }}>{line.itemName}</td>
              <td style={{ border: "1px solid ", padding: "8px" }}>{line.lineQuantity}</td>
              <td style={{ border: "1px solid ", padding: "8px" }}>
                {formatPriceAmount(line.itemPrice)}
              </td>
              <td style={{ border: "1px solid ", padding: "8px" }}>
                {line.itemTaxPercent}%
              </td>
              <td style={{ border: "1px solid ", padding: "8px" }}>
                {formatPriceAmount(line.lineExtensionsAmount)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px", marginTop: "20px"  }}>

      <div>
        <p style={{ marginTop: "20px" }}>{supplier.supplierName}</p>
        <p>{supplier.supplierEndpointID}</p>
      </div>

      <div>
        <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
          <li style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
            <strong>Vergi Hariç Tutar:</strong>
            <span>{formatPriceAmount(header.taxExclusiveAmount)} {invoice.invoiceCurrency}</span>
          </li>
          <li style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
            <strong>KDV:</strong>
            <span>{formatPriceAmount(header.invoiceTaxAmount)} {invoice.invoiceCurrency}</span>
          </li>
          <li style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
            <strong>Genel Toplam (KDV Dahil):</strong>
            <span>{formatPriceAmount(header.taxInclusiveAmount)} {invoice.invoiceCurrency}</span>
          </li>
        </ul>
      </div>



      </div>
    </div>
  );
};

export default invoiceEmailBody;
