import React, { useState, useEffect, useCallback, useRef } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import './sales-routes.scss';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes,
  Editing,
  FilterPanel,
  FilterBuilderPopup,
  DataGridRef
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { ContactStatus as ContactStatusType, CustomerModel, RouteModel } from '../../types/crm-contact';
import { ContactStatus } from '../../components';
import {  salesRoutesDeleteRequest, salesRoutesRequest} from '../../api/requests';
import { useTranslation } from 'react-i18next';
import { DateBox } from 'devextreme-react';
import { newRouteModel } from '../../shared/constants';
import { routesListStatusFromSession, routesListStatusToSession } from '../../api/sessions';
import { formatDate } from 'devextreme/localization';
import { RoutesPopup } from '../../components/library/sales-routes/popup';
import { RouteStatus } from '../../components/utils/contact-status/ContactStatus';
import { appInfo } from '../../app-info';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';



export const SalesRoutes = () => {
  const { t } = useTranslation();
  const filterStatusList = ["all", "active", "passive"];
  const [routesList, setRoutesList] = useState([]);
  const [rowData, setRowData] = useState<RouteModel>(newRouteModel);
  const [routeActive, setRouteActive] = useState("active");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isPanelOpened, setPanelOpened] = useState(false);
  const [isNewRecord, setIsNewRecord] = useState(false);
  const gridRef = useRef<DataGridRef>(null);
  const [rowCount, setRowCount] = useState(0);

  useEffect(()=>{
    mainRefresh();
  },[]);

  function getTranslatedStatusList() {
    return filterStatusList.map((status) => t(status));
  }

  function mainRefresh(){
    salesRoutesRequest(startDate,endDate).then((res)=>{
      setRoutesList(res.dataSet || []);
      gridRef.current?.instance().endCustomLoading();
      setTimeout(() => {
        updateFilter();
      }, 50);
    })

  }

  function updateFilter(){
    if(routesListStatusFromSession()=="0"){
      gridRef.current?.instance().filter(['routeActive', '=', "0"]);
    }else if(routesListStatusFromSession()=="1"){
      gridRef.current?.instance().filter(['routeActive', '=', "1"]);
    }else{
      gridRef.current?.instance().clearFilter()
    }
    if(routesListStatusFromSession()=="0"){
      setRouteActive("passive")
    }else if(routesListStatusFromSession()=="1"){
      setRouteActive("active")
    }else{
      setRouteActive("all")
    }
  }

  const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {
    const { item: selected }: { item: FilterContactStatus } = e;
    if (selected == t("all")) {
      routesListStatusToSession("2")
      setRouteActive("all");
      gridRef.current?.instance().clearFilter();
    } else if(selected==t('active')){
      setRouteActive("active");
      gridRef.current?.instance().filter(['routeActive', '=', "1"]);
      routesListStatusToSession("1")
    }else if(selected == t('passive')){
      setRouteActive("passive");
      gridRef.current?.instance().filter(['routeActive', '=', "0"]);
      routesListStatusToSession("0")
    }

  }, []);

  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
  }, [isPanelOpened]);

  // Yeni rut eklendiğinde listeyi tazeleyip yeni rutu açar
  const openNewRow = useCallback((id) => {
    salesRoutesRequest(startDate,endDate).then((res)=>{
      setRoutesList(res.dataSet || []);
      gridRef.current?.instance().endCustomLoading();
      setRowData(res.dataSet.filter(item => item["routeRef"] == id)[0])
      setIsNewRecord(false);
      setPanelOpened(true)
      setTimeout(() => {
        updateFilter();
      }, 50);
    })
  }, []);


  const onAddContactClick =()=> {
    setIsNewRecord(true);
    var rut = {
      routeRef: 0,
      routeActive: 1,
      routeStatus: 0,
      routeCode: "",
      routeName: "",
      routePlanRef: 0,
      erpSlsManRef: 0,
      erpSlsManCode: "",
      erpVehicleRef: 0,
      routeDate: "",
      beginTime: "",
      endTime: "",
      comment: "",
      isNew: true
    }
    setRowData(rut)
    setPanelOpened(true);
  };

  const onRowClick = useCallback(({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
    setIsNewRecord(false);
    setRowData(data);
    setRowData(prev => ({
      ...prev,
      ["isNew"]: false
    }));
    setPanelOpened(true);

  }, []);


  const today = new Date();

  // İki hafta (14 gün) önceki tarih
  const pastDate = new Date(today);
  pastDate.setDate(today.getDate());

  // İki hafta (14 gün) sonraki tarih
  const futureDate = new Date(today);
  futureDate.setDate(today.getDate());

  // bDate için yıl, ay ve günü alıyoruz
  const bYear = pastDate.getFullYear();
  const bMonth = String(pastDate.getMonth() + 1).padStart(2, '0');
  const bDay = String(pastDate.getDate()).padStart(2, '0');

  // eDate için yıl, ay ve günü alıyoruz
  const eYear = futureDate.getFullYear();
  const eMonth = String(futureDate.getMonth() + 1).padStart(2, '0');
  const eDay = String(futureDate.getDate()).padStart(2, '0');

  // Tarihleri formatlıyoruz
  const bDate = `${bYear}-${bMonth}-${bDay}`;
  const eDate = `${eYear}-${eMonth}-${eDay}`;

  const [startDate, setStartDate] = useState(bDate);
  const [endDate, setEndDate] = useState(eDate);

  const formatDate2 = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const onRowRemoving = (e) => {
    const _route = e.data;

    return salesRoutesDeleteRequest(_route)
        .then((response) => {
            if (response.resCode == 200) {
                notify(t("successfull"), "success", 1000);
            } else {
                notify(response.resMessage, "error", 2500);
                mainRefresh()
                throw new Error(response.resMessage);
            }
        })
        .catch((error) => {
            console.error('Error deleting product:', error);
        });
};

const onSelectionChanged = (e) => {
  setSelectedRows(e.selectedRowsData);
};

async function deleteSelected(){
const result = await confirm(t("areYouSureDeleting"),t("deleteProcess"));
  if (result) {
    gridRef.current?.instance().beginCustomLoading(t("loading"));
    var i = 0;
    selectedRows.forEach((row, index) => {

      salesRoutesDeleteRequest(row).then((response)=>{
            if(response["resCode"]==200){
            }else{
                notify(response["resMessage"],"error");
            }
            i++;
            if(i==selectedRows.length){
                gridRef.current?.instance().endCustomLoading();
                mainRefresh();
            }
        });
    });
  }
}

const handleContentReady = (e) => {
  setRowCount(e.component.totalCount());
};

  return (
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          className='grid theme-dependent'
          height='100%'
          focusedRowEnabled
          dataSource={routesList}
          keyExpr="routeRef"
          onRowClick={onRowClick}
          onExporting={onExporting}
          onRowRemoving={onRowRemoving}
          onSelectionChanged={onSelectionChanged}
          noDataText={t("noData")}
          onContentReady={handleContentReady}
          allowColumnReordering
          allowColumnResizing
          showBorders
          ref={gridRef}>

          <Editing
                    mode="row"
                    allowDeleting
                    texts={{
                        editRow: t("edit"),
                        deleteRow: t("delete"),
                        confirmDeleteMessage: t("confirmDeleteMessage"),
                        confirmDeleteYes: t("yes"),
                        cancelRowChanges: t("no"),
                    }}
            />
          <LoadPanel showPane={false}  />
          <SearchPanel visible placeholder={t("customerSearch")}  />
          <ColumnChooser enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <Selection
            selectAllMode='allPages'
            showCheckBoxesMode='always'
            mode='multiple'
          />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />


          <Toolbar>
          <Item location='before' locateInMenu='auto'>
              <DropDownButton
                items={getTranslatedStatusList()}
                stylingMode='text'
                text={t(routeActive)}
                dropDownOptions={dropDownOptions}
                useSelectMode
                onSelectionChanged={filterByStatus}
              />
          </Item>


          <Item name='searchPanel' location='before' />

          <Item location='before' locateInMenu='auto'>
          <DateBox defaultValue={startDate} onValueChanged={(e) => setStartDate(formatDate2(e.value))} displayFormat="yyyy-MM-dd" />
          </Item>

          <Item location='before' locateInMenu='auto'>
          <DateBox defaultValue={endDate} onValueChanged={(e) => setEndDate(formatDate2(e.value))} displayFormat="yyyy-MM-dd" />
          </Item>

          <Item
              location='before'
              showText='inMenu'
            >
              <Button
                icon='refresh'
                hint={t("refresh")}
                stylingMode='text'
                onClick={mainRefresh}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before' />

            <Item location="before">
                <DropDownButton
                    visible={selectedRows.length>0}
                    text={"("+ selectedRows.length+") "+ t("transactions")}
                    icon="menu"
                    width={"175"}
                    items={[
                        { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                      ]}
                    onItemClick={(e) => {
                        if(e.itemData.action=="deleteSelected"){
                            deleteSelected();
                        }
                      }}
                />
            </Item>

            <Item location='center' locateInMenu='auto'>
                {rowCount} {t("records")}
            </Item>

            <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                text={t("new")}
                type='default'
                stylingMode='contained'
                visible={true}
                onClick={onAddContactClick}
              />
            </Item>



          </Toolbar>

          <Column
            dataField='routeStatus'
            caption={t("routeStatus")}
            hidingPriority={18}
            width={150}
            cellRender={RouteStatus}
            alignment='left'
          />

          <Column
            dataField='routeActive'
            caption={t("status")}
            hidingPriority={17}
            width={150}
            cellRender={ContactStatus}
            alignment='left'
          />

          <Column
            dataField='routeName'
            caption={t("routeName")}
            hidingPriority={19}
          />

          <Column
            dataField='erpSlsManCode'
            caption={t("erpSlsManCode")}
            hidingPriority={16}
            visible={false}
          />



          <Column
            dataField='routeCode'
            caption={t("routeCode")}
            hidingPriority={15}
            visible={false}
          />

          <Column
            dataField='routeDate'
            caption={t("routeDate")}
            hidingPriority={11}
          />

          <Column
            dataField="beginTime"
            caption={t("beginDate")}
            hidingPriority={10}
            customizeText={(cellInfo) => {
              const date = new Date(cellInfo.value);
              const hours = date.getHours().toString().padStart(2, '0');
              const minutes = date.getMinutes().toString().padStart(2, '0');
              const seconds = date.getSeconds().toString().padStart(2, '0');
              return `${hours}:${minutes}:${seconds}`;
            }}
          />


          <Column
            dataField='endTime'
            caption={t("endDate")}
            hidingPriority={10}
            customizeText={(cellInfo) => {
              const date = new Date(cellInfo.value);
              const hours = date.getHours().toString().padStart(2, '0');
              const minutes = date.getMinutes().toString().padStart(2, '0');
              const seconds = date.getSeconds().toString().padStart(2, '0');
              return `${hours}:${minutes}:${seconds}`;
            }}
          />

          <Column
            dataField='comment'
            caption={t("comment")}
            hidingPriority={9}
            visible={false}
          />



        </DataGrid>
        <RoutesPopup openNewRow={openNewRow} isNewRecord={isNewRecord} rowData={rowData} isPanelOpened={isPanelOpened} changePanelOpened={changePanelOpened} mainRefresh={mainRefresh}></RoutesPopup>
      </div>
    </div>
  );
};


type FilterContactStatus = ContactStatusType | 'active';

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Routes.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Routes.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto' };