import React, { useEffect, useRef, useState } from "react";
import { TabPanel } from "devextreme-react/tab-panel";
import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { TextArea } from "devextreme-react/text-area";
import Button from "devextreme-react/button";
import { generalCampaignsRequest } from "../../../api/requests";
import { Lookup } from "devextreme-react";
import { useTranslation } from "react-i18next";
import { ContactStatus } from "../../utils/contact-status/ContactStatus";

const CampaignGroupTabView = ({groupRef}) => {
    const [note, setNote] = useState<string>("");
    const [campaignsList, setCampaignsList] = useState([]);
    const {t} = useTranslation();

    // Örnek veri kaynağı

    useEffect(()=>{
        mainRefresh();
    },[groupRef])

    function mainRefresh() {
        setCampaignsList([])
        if(groupRef != 0){
            generalCampaignsRequest().then((res) => {
                const filteredCampaigns = (res.dataSet || [])
                .filter( (item) => item.campaignGroupRef == groupRef );
                setCampaignsList(filteredCampaigns);
            });
        }
    }


    return (
        <TabPanel
            height={"100%"}
            width={"100%"}
            deferRendering={false} // Sekmeler arası hızlı geçiş sağlar
            dataSource={[
                { title: "Kampanyalar", content: "campaigns" },
                { title: "Notlar", content: "notes" },
            ]}
            itemRender={(item) => {
                if (item.content === "campaigns") {
                    return (
                        <div className="p-4">
                            <DataGrid
                                dataSource={campaignsList}
                                showBorders={true}
                                columnAutoWidth={true}
                                noDataText={t("noData")}
                                allowColumnReordering
                                allowColumnResizing
                                keyExpr={"campaignRef"}
                            >

                                    <Column
                                        dataField='campaignStatus'
                                        caption={t("status")}
                                        width={100}
                                        cellRender={ContactStatus}
                                    />

                                    <Column
                                        dataField='campaignCode'
                                        caption={t("campaignCode")}
                                        width={175}
                                    />

                                        <Column
                                        dataField='campaignName'
                                        caption={t("campaignName")}
                                    />

                                        <Column
                                        dataField='beginDate'
                                        caption={t("beginDate")}
                                    />

                                        <Column
                                        dataField='endDate'
                                        caption={t("endDate")}
                                    />

                                        <Column
                                        dataField='comment'
                                        caption={t("comment")}
                                        visible={false}
                                    />
                            </DataGrid>
                        </div>
                    );
                } else if (item.content === "notes") {
                    return (
                        <div className="flex flex-col gap-y-4 p-4 w-full">
                            <TextArea
                                value={note}
                                onValueChange={(value) => setNote(value)}
                                placeholder=""
                                height={100}
                            />
                            <Button
                                text="Kaydet"
                                icon="save"
                                width={200}
                                stylingMode="contained"
                                type="default"
                            />
                        </div>
                    );
                }
                return null;
            }}
        />
    );
};

export default CampaignGroupTabView;
