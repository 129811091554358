import React, { useCallback, useEffect, useRef, useState } from "react";
import { getInvoice, getPDF, IncomingInvoicesRequest } from "../../../api/requests";
import notify from "devextreme/ui/notify";
import { Button, DataGrid, DropDownButton, FileUploader, LoadPanel, Popup, ProgressBar, } from "devextreme-react";
import { useTranslation } from "react-i18next";
import { Column, ColumnChooser, DataGridRef, DataGridTypes,Toolbar, Export, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, Scrolling, SearchPanel, Selection, Sorting } from "devextreme-react/cjs/data-grid";
import { appInfo } from "../../../app-info";
import "../../../theme/grid.scss";
import { convertJSONtoXML, openPDFInNewTab } from "../../../utils/xmlConverter";
import { xmlToCustomJson } from "../../../utils/xmlCorvertSetting.js";
import { formatCurrency, formatDateDMY, formatPriceAmount } from "../../../utils/formats";
import invoiceEmailBody from "../../../utils/invoiceMailDesign";


export const IncomingInvoices = () => {
    const gridRef = useRef<DataGridRef>(null);
    const {t} = useTranslation();
    const [invoices, setInvoices] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const [importVisible, setImportVisible] = useState(false)
    const [previewVisible, setPreviewVisible] = useState(false)

    const [progressVisible, setProgressVisible] = useState(false);
    const [progressValue, setProgressValue] = useState(0);
    const [isDropZoneActive, setIsDropZoneActive] = useState(false);
    const [xmlData, setXmlData] = useState(null);

    function onHidingImport(){
        setXmlData(null)
        setImportVisible(!importVisible);
    }

    function onHidingPreview(){
        setXmlData(null)
        setPreviewVisible(!previewVisible);
    }

    function getList(){
        IncomingInvoicesRequest().then((res)=>{
          if(res.resCode == 200){
            setInvoices(res.dataset || []);
          }else{
            notify(res.resMessage);
          }
        })
      }

      useEffect(()=>{
        getList();
      },[])


    const handleContentReady = (e) => {
        setRowCount(e.component.totalCount());
      };

    const allowedFileExtensions = ['.xml'];

    const onDropZoneEnter = () => setIsDropZoneActive(true);
  const onDropZoneLeave = () => setIsDropZoneActive(false);

  // Yükleme Başladı
  const onUploadStarted = (e) => {
    setProgressVisible(true);
    setProgressValue(0);
    handleFileProcessing(e.file)
  };

  // Yükleme İlerleme
  const onProgress = (e: any) => {
    setProgressValue(e.bytesLoaded / e.bytesTotal * 100);
  };


   // Dosya İşleme
   const handleFileProcessing = (file: File) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target && event.target.result) {
        const xmlContent = event.target.result; // XML içeriği
        const parser = new DOMParser();

        if (typeof xmlContent === "string") {
          // XML'i Parse Et
          const xmlDoc = parser.parseFromString(xmlContent, "text/xml");

          // JSON'a Çevir
            const json = xmlToCustomJson(xmlDoc);
            const str = JSON.stringify(json);
            const jsn = JSON.parse(str);
            setXmlData(jsn);
            console.log(jsn)

          setProgressVisible(false); // Yükleme işlemi tamamlandığında ilerleme çubuğunu gizle
        }
      }
    };

    // Dosyayı metin olarak oku
    reader.readAsText(file);
  };

  const openPDFnewTab = async (iRef) => {
    try {
        const response = await getInvoice(iRef);

        if (response && response.resCode == 200) {
            await openPDFInNewTab(response);
        } else {
            console.error(response.resMessage);
            notify(response.resMessage,"error")
        }


    } catch (error) {
        console.error('PDF indirme hatası:', error);
        notify('PDF indirme hatası',"error")
    }
};

const previewInvoice = async (iRef) => {

  try {
    const response = await getInvoice(iRef);

    if (response && response.resCode == 200) {
      onHidingPreview();
        setXmlData(response)
    } else {
        console.error(response.resMessage);
        notify(response.resMessage,"error")
    }


} catch (error) {
    console.error('PDF indirme hatası:', error);
    notify('PDF indirme hatası',"error")
}
}

    return(<>
    <div className="view crm-contact-list">
    <div className="view-wrapper view-wrapper-contact-list list-page">
    <DataGrid
          className='grid theme-dependent'
          focusedRowEnabled
          dataSource={invoices}
          keyExpr="invoiceRef"
          noDataText={t("noData")}
          onContentReady={handleContentReady}
          allowColumnReordering
          allowColumnResizing
          showBorders
          ref={gridRef}
          >


          <LoadPanel />
          <SearchPanel width={250} visible placeholder={t("customerSearch")}  />
          <ColumnChooser title={t("columnChooser")} enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

          <Toolbar>


          <Item name='searchPanel' location='before'  />

          <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                hint={t("refresh")}
                stylingMode='text'
                onClick={getList}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before' />

            <Item location="before">
                <DropDownButton
                    visible={selectedRows.length>0}
                    text={"("+ selectedRows.length+") "+ t("transactions")}
                    icon="menu"
                    width={"175"}
                    items={[
                    { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                    ]}
                    onItemClick={(e) => {
                    if(e.itemData.action=="deleteSelected"){
                    //    deleteSelected();
                    }
                    }}
                />
            </Item>

            <Item location='center' locateInMenu='auto'>
            {rowCount} {t("records")}
            </Item>

            <Item location='after' >
                <Button
                    icon="import"
                    type="default"
                    hint={t("importFolder")}
                    stylingMode="contained"
                    onClick={onHidingImport}
                />
            </Item>



          </Toolbar>


          <Column dataField="invoiceDate" hidingPriority={7} width={150} cellRender={(cell)=> formatDateDMY(cell.value)}/>
          <Column dataField="invoiceNo" hidingPriority={8} width={200}/>
          <Column dataField="invoiceType" hidingPriority={6} width={200} />
          <Column dataField="clientName" hidingPriority={10} />
          <Column dataField="invoiceNetTotal" hidingPriority={5} width={200} cellRender={(cell)=>formatPriceAmount(cell.value)} />
          <Column dataField="invoiceVatTotal" hidingPriority={4} width={200} cellRender={(cell)=>formatPriceAmount(cell.value)}/>
          <Column dataField="invoiceGrossTotal" hidingPriority={3} width={200} cellRender={(cell)=>formatPriceAmount(cell.value)}/>
          <Column  hidingPriority={9} alignment='center' width={80} cellRender={({ data }) => (
                            <div className="flex gap-2 items-center">
                            <Button
                              hint="Önizle"
                              icon='eyeopen'
                              onClick={() => previewInvoice(data.invoiceRef)}
                            />
                          </div>
                          )} />

        </DataGrid>

        <Popup
            visible={importVisible}
            onHiding={onHidingImport}
            height={xmlData?"90%":400}
            width={xmlData?900:600}
            hideOnOutsideClick
            title="XML Import"
            titleRender={()=>(
                        <div className="flex flex-row justify-between">
                          <div className="flex flex-row">
                          <Button icon="close" stylingMode="text" onClick={onHidingImport}></Button>
                          <p className="ml-2 mt-0 justify-center font-bold text-lg">{"XML Import"}</p>
                          </div>

                          <div className='flex flex-row gap-x-2'>
                            <Button visible={xmlData!=null} text={t("save")} icon='save' stylingMode='contained' type='default'  />
                            <Button text={t("close")} icon='close' stylingMode='contained' type='default' style={{backgroundColor:"gray"}} onClick={onHidingImport}  />

                          </div>

                        </div>
                      )}
            contentRender={()=>
            <div className="flex justify-center h-full">
                 {!xmlData && (
                  <div
                    id="dropzone-external"
                    className={`widget-container flex border-2 border-dashed h-full w-full items-center ${
                      isDropZoneActive ? "dropzone-active" : ""
                    }`}
                    style={{
                      backgroundColor: isDropZoneActive ? "#cccccc" : "", // Dosya alanına sürüklendiğinde arka plan değişir
                      borderColor: isDropZoneActive ? "#00796b" : "gray", // Kenarlık rengi değişir
                    }}
                  >
                    <div className="flex-box w-full">
                      <div id="dropzone-text" className="flex flex-col items-center gap-2">
                        <span>Tıklayıp dosya seçin</span>
                        <span>veya</span>
                        <span>Sürükleyip bırakın</span>
                      </div>

                      <div className="flex items-center w-full justify-center pt-4">
                        <ProgressBar
                          id="upload-progress"
                          min={0}
                          max={100}
                          width="30%"
                          showStatus={false}
                          visible={progressVisible}
                          value={progressValue}
                        ></ProgressBar>
                      </div>
                    </div>
                    <FileUploader
                      id="file-uploader"
                      dialogTrigger="#dropzone-external"
                      dropZone="#dropzone-external"
                      multiple={false}
                      allowedFileExtensions={allowedFileExtensions}
                      uploadMode="instantly"
                      visible={false}
                      onDropZoneEnter={onDropZoneEnter}
                      onDropZoneLeave={onDropZoneLeave}
                      onProgress={onProgress}
                      onUploadStarted={onUploadStarted}
                    ></FileUploader>
                  </div>
                )}


                  {xmlData&&invoiceEmailBody(xmlData)}
            </div>
            }
        ></Popup>

          <Popup
            visible={previewVisible}
            onHiding={onHidingPreview}
            height={xmlData?"95%":400}
            width={xmlData?900:600}
            hideOnOutsideClick
            title="XML Preview"
            titleRender={()=>(
                        <div className="flex flex-row justify-between">
                          <div className="flex flex-row">
                          <Button icon="close" stylingMode="text" onClick={onHidingPreview}></Button>
                          <p className="ml-2 mt-0 justify-center font-bold text-lg">{"XML Preview"}</p>
                          </div>

                          <div className='flex flex-row gap-x-2'>
                            <Button text={t("close")} icon='close' stylingMode='contained' type='default' style={{backgroundColor:"gray"}} onClick={onHidingPreview}  />
                          </div>
                        </div>
                      )}
            contentRender={()=>
            <div className="flex justify-center">
                  {xmlData&&invoiceEmailBody(xmlData)}
            </div>
            }
        ></Popup>
        </div>
        </div>
    </>);
}