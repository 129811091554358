import React from 'react';
import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';

import {
  CardActivities,
  CardNotes,
  CardMessages,
  CardTasks,
  CardOpportunities
} from '../..';
import { CustomerUserList } from '../../library/customer-users/CustomerUser';
import { Height } from 'devextreme-react/cjs/chart';
import { useTranslation } from 'react-i18next';
import { UsersCustomersList } from '../../library/b2b-users-list/UsersCustomersList';

export const ContactCards2 = ({
  user,
  isLoading,
  tasks,
  activities,
  name,
  activeOpportunities,
  closedOpportunities,
  notes,
  messages,
}) => {
  const {t} = useTranslation();
  return (
    <div className='dx-card details-card'>
      <TabPanel
        disabled={user.userRef == 0}
        showNavButtons
        focusStateEnabled={false}
        deferRendering={false}
      >
        <TabPanelItem  title={t("customers")}>
          <UsersCustomersList  user={user}></UsersCustomersList>
          <div style={{height: "50vh",color:"red"}}></div>
        </TabPanelItem>
        <TabPanelItem title={t("notes")}>
          <CardNotes items={notes} user={name} />
        </TabPanelItem>
      </TabPanel>
    </div>
  );
};
