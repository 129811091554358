import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button,  } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import { RouteModel } from '../../../types/crm-contact';
import ValidationGroup from 'devextreme-react/validation-group';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { DateBox, Lookup, SelectBox, TextBox } from 'devextreme-react';
import { salesRoutesUpdateRequest, salesUserRequest } from '../../../api/requests';
import { newRouteModel } from '../../../shared/constants';
import { status_options, status_selectbox } from '../../../shared/status-selectbox';


export const RouteInfoUpdate = (
  { mainRefresh, route: initialRoute ,readOnlyTrue, readOnlyFalse, readOnly,changePanelOpened, isNewRecord,isPanelOpened,openNewRow } :
  { mainRefresh: () => void, route: RouteModel, readOnlyTrue: () => void, readOnlyFalse: () => void, readOnly: boolean, changePanelOpened: () => void, isNewRecord: boolean, isPanelOpened: boolean, openNewRow: (id: string) => void }
) => {

  const [route, setRoute] = useState(initialRoute);
  const [routeState, setRouteState] = useState(initialRoute);
  const [salesmanList, setSalesmanList] = useState([]);

    useEffect(() => {
      salesUserRequest().then((response)=>{
        setSalesmanList(response.dataSet);
      })
    }, []);

    useEffect(() => {
      if(isNewRecord){
        setRoute(newRouteModel);
      }
    }, [isPanelOpened]);


  // route prop değiştiğinde state'i güncellemek için useEffect kullanımı
  useEffect(() => {
    setRoute(initialRoute);
    setRouteState(initialRoute);
    setSelectedStatus(initialRoute.routeActive)
    if(initialRoute["isNew"]){
      readOnlyFalse();
    }else{
      readOnlyTrue();
    }
    setSelectedRouteStatus(route.routeStatus)
  }, [route.routeRef]);

  function onCancelClick(){
    if(isNewRecord){
      changePanelOpened();
    }else{
      readOnlyTrue();
      setRoute(routeState)
      statusChange({"value":routeState.routeActive})
    }
  }

  function onSaveClick () {
    salesRoutesUpdateRequest(route).then((response)=>{
      if(response.resCode == 200){
        notify(t("successfull"),"success");
        setRouteState(route)
        if(isNewRecord){
          const ref = response.dataSet[0]["routeRef"];
         // changePanelOpened();
          openNewRow(ref);

        }else{
          mainRefresh();
          updateField('routeCode')(response.dataSet[0]["routeCode"]);
          //updateField('routeRef')(response.dataSet[0]["routeRef"]);
          updateField('routeName')(response.dataSet[0]["routeName"]);
          updateField('beginTime')(response.dataSet[0]["beginTime"]);
          updateField('endTime')(response.dataSet[0]["endTime"]);
          readOnlyTrue();
        }
      }else{
        notify(response.resMessage,"error");
      }
    })

  }

  const { t } = useTranslation();

  const updateField = (key) => (value) => {
    setRoute(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const [selectedStatus, setSelectedStatus] = useState<number>(0);

  const statusChange = (e: any) => {
    setSelectedStatus(e.value);
    updateField('routeActive')(e.value);
  };

  const [selectedRouteStatus, setSelectedRouteStatus] = useState<number>(0);

  const statusRouteOptions = [
    { value: 0, label: t("waiting"), color:'red' },
    { value: 1, label: t("started"), color: 'rgb(198, 185, 52)' },
    { value: 2, label: t("completed"), color: 'green' },
  ];

  const handleChange = (e: any) => {
    setSelectedRouteStatus(e.value);
    route.routeStatus = e.value;
  };

  const itemTemplate = (data) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: data.color,
            marginRight: '8px',
          }}
        ></span>
        {data.label}
      </div>
    );
  };

  function hourFormat(datem){
    if(datem == ""){
      return "";
    }else{
      const date = new Date(datem);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    }

  }

  return (
    <>
      <ScrollView className='panel-scroll'>
        <ValidationGroup>
          <div className='w-full'>
            <div className='flex flex-col'>
              <div className='flex justify-between'>
                <div className='flex '>
                <SelectBox
                      dataSource={status_options}
                      value={selectedStatus}
                      defaultValue={0}
                      displayExpr={(item) => t(item.label)}
                      valueExpr="value"
                      onValueChanged={statusChange}
                      itemRender={status_selectbox}
                      searchEnabled={false}
                      readOnly={readOnly}
                      />
                  </div>


                <div className='flex flex-row gap-x-2'>
                  <Button visible={readOnly} text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={readOnlyFalse} />
                  <Button visible={readOnly} text={t("close")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={changePanelOpened} />
                  <Button visible={!readOnly} text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
                  <Button visible={!readOnly} text={t("cancel")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={onCancelClick} />
                </div>

              </div>

              <div className='flex gap-x-3 justify-between'>

                <div className='p-5 mt-3 w-full rounded-md shadow-sm border flex flex-col border-gray-300 gap-4'>

                <div>
                  <SelectBox
                    dataSource={statusRouteOptions}
                    value={selectedRouteStatus}
                    defaultValue={0}
                    displayExpr="label"
                    valueExpr="value"
                    label={t("routeStatus")}
                    onValueChanged={handleChange}
                    itemRender={itemTemplate}
                    searchEnabled={false}
                    readOnly={isNewRecord||readOnly}

                    />
                  </div>

                  <div >
                    <TextBox
                      label={t("routeCode")}
                      value={route.routeCode}
                      readOnly={true}
                      onValueChange={updateField('routeCode')}
                    />
                  </div>


                </div>


                <div className='p-5 mt-3 w-full rounded-md shadow-sm border flex flex-col border-gray-300 gap-4'>
                <div >
                    <TextBox
                      label={t("routeName")}
                      value={route.routeName}
                      readOnly={readOnly}
                      onValueChange={updateField('routeName')}
                    />
                  </div>

                  <div >
                  <Lookup
                    label={t("erpSlsManCode")}
                    readOnly={readOnly}
                    dataSource={salesmanList}
                    value={route.erpSlsManCode}
                    displayExpr={"erpSlsManCode"}
                    valueExpr={"erpSlsManCode"}
                    onValueChange={updateField('erpSlsManCode')}
                    searchPlaceholder={t("search")}
                    placeholder={t("search")}
                    cancelButtonText={t("cancel")}
                    />
                  </div>

                </div>


                <div className='p-5 mt-3 w-full rounded-md shadow-sm border flex flex-col border-gray-300 gap-4'>
                <div >
                    <DateBox
                      label={t("routeDate")}
                      value={route.routeDate}
                      onValueChanged={(e) => updateField('routeDate')(e.value)}
                      displayFormat="dd-MM-yyyy"
                      readOnly={readOnly}
                    />
                  </div>

                  <div >
                    <TextBox
                      label={t("comment")}
                      value={route.comment}
                      readOnly={readOnly}
                      onValueChange={updateField('comment')}
                    />
                  </div>

                </div>


                <div className='p-5 mt-3 w-full rounded-md shadow-sm border flex flex-col border-gray-300 gap-4'>
                  <div >
                  <TextBox
                      label={t("beginDate")}
                      value={hourFormat(route.beginTime)}
                      readOnly={true}
                      onValueChange={updateField('beginTime')}

                    />

                  </div>

                  <div >
                  <TextBox
                      label={t("endDate")}
                      value={hourFormat(route.endTime)}
                      readOnly={true}
                      onValueChange={updateField('endTime')}
                    />

                  </div>

                </div>

              </div>
            </div>
          </div>

        </ValidationGroup>
      </ScrollView>
    </>
  );
};
