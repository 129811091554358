import React, { useState, useEffect } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import { Button, } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import {  SalesManModel } from '../../../types/crm-contact';
import ValidationGroup from 'devextreme-react/validation-group';
import notify from 'devextreme/ui/notify';
import {  salesUserUpdateRequest } from '../../../api/requests';
import { useTranslation } from 'react-i18next';
import { Popup, SelectBox, TextBox } from 'devextreme-react';
import { Eye, EyeOff } from 'react-feather';
import { status_selectbox, status_options } from '../../../shared/status-selectbox';

export const SalesmanEditPopup = (
  { mainRefresh, rowData , isPanelOpened,changePanelOpened } :
  { mainRefresh: () => void, rowData:SalesManModel, isPanelOpened,changePanelOpened }
) => {

  const [salesman, setSalesman] = useState(rowData);
  const [salesmanState, setSalesmanState] = useState(rowData);
  const [readOnly, setReadOnly] = useState(true);


  // campaign prop değiştiğinde state'i güncellemek için useEffect kullanımı
  useEffect(() => {
    setSalesman(rowData);
    setSalesmanState(rowData)
    setSelectedStatus(rowData.salesManActive)
  }, [rowData]);

  useEffect(() => {
    setReadOnly(true)
    if(rowData.erpSlsManCode==""){
      setReadOnly(false)
    }
  }, [isPanelOpened]);


  function onSaveClick () {
    try {
        salesUserUpdateRequest(salesman).then(function(res){
            if(res.resCode == 200){
              notify(t("updateSuccessfull"), "success", 1500);
              mainRefresh();
              changePanelOpened();
              setSalesmanState(salesman)
            }else{
              notify(res.resMessage,"error",3000);
            }
          });

        } catch (error) {
          console.error('API hatası:', error);
        }
  }

  const { t } = useTranslation();

  const updateField = (key) => (value) => {
    setSalesman(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const [selectedStatus, setSelectedStatus] = useState<number>(0);

  const statusChange = (e: any) => {
    setSelectedStatus(e.value);
    updateField('salesManActive')(e.value);
  };

  function onclose(){
    if(salesman.erpSlsManCode == ""){
      changePanelOpened();
    }else{
      setReadOnly(true)
      setSalesman(salesmanState)
      statusChange({"value":salesmanState.salesManActive})
    }
  }

  return (
    <>
    <Popup
                key={rowData.salesManRef}
                visible={isPanelOpened}
                hideOnOutsideClick={true}
                title={rowData?.salesManName}
                showCloseButton={true}
                defaultHeight={"auto"}
                maxHeight={"95%"}
                width={"auto"}
                resizeEnabled
                restorePosition
                onHiding={changePanelOpened}
                titleRender={()=>(
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-row">
                    <Button icon="close" stylingMode="text" onClick={changePanelOpened}></Button>
                    <p className="ml-2 mt-0 justify-center font-bold text-lg">{`${rowData?.salesManName}`}</p>
                    </div>

                  </div>
                )}
                contentRender={() => (
      <ScrollView className='panel-scroll' >
        <ValidationGroup>
          <div className=''>
            <div className='flex flex-col '>
              <div className='flex justify-between p-2'>

              <div className='pr-3'>
              <SelectBox
                    dataSource={status_options}
                    value={selectedStatus}
                    defaultValue={0}
                    displayExpr={(item) => t(item.label)}
                    valueExpr="value"
                    onValueChanged={statusChange}
                    itemRender={status_selectbox}
                    searchEnabled={false}
                    readOnly={readOnly}
                    />
                </div>


                <div className='flex gap-2'>
                  <Button visible={readOnly} text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={()=>setReadOnly(false)} />
                  <Button visible={readOnly} text={t("close")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={changePanelOpened} />
                  <Button visible={!readOnly} text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
                  <Button visible={!readOnly} text={t("cancel")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={onclose} />

                </div>

              </div>

              <div className='flex flex-col lg:flex-row justify-between'>

                     <div className='m-2 rounded-md shadow-sm border flex flex-col lg:flex-row border-gray-300 '>
                     <div className='p-5  w-72 sm:w-96 lg:w-72 xl:w-96 flex flex-col gap-4'>
                      <div >
                    <TextBox
                      label={t("salesManName")}
                      value={salesman.salesManName}
                      onValueChange={updateField('salesManName')}
                      readOnly={readOnly}
                    />
                  </div>

                  <div>
                    <TextBox
                      label={t("erpSlsManName1")}
                      value={salesman.erpSlsManName1}
                      onValueChange={updateField('erpSlsManName1')}
                      readOnly={readOnly}
                    />
                  </div>



                  <div>
                    <TextBox
                      label={t("erpSlsManCode")}
                      value={salesman.erpSlsManCode}
                      onValueChange={updateField('erpSlsManCode')}
                      readOnly={readOnly}
                    />
                  </div>

                    <div>
                    <TextBox
                      label={t("erpFirmNr")}
                      value={salesman.erpFirmNr}
                      onValueChange={updateField('erpFirmNr')}
                      readOnly={readOnly}
                    />
                  </div>

                  <div>
                    <TextBox
                      label={t("erpCashBoxCode")}
                      value={salesman.erpCashBoxCode}
                      onValueChange={updateField('erpCashBoxCode')}
                      readOnly={readOnly}
                    />
                  </div>

                  <div>
                    <TextBox
                      label={t("salesManEMail")}
                      value={salesman.salesManEMail}
                      onValueChange={updateField('salesManEMail')}
                      readOnly={readOnly}
                      inputAttr={{ autocomplete: 'off' }}
                    />
                  </div>

                    <div>
                      <div style={{ position: 'relative' }}>
                          <TextBox
                            label={t("salesManPassword")}
                            mode={isPasswordVisible ? 'text' : 'password'}
                            value={salesman.salesManPassword}
                            onValueChange={updateField('salesManPassword')}
                            inputAttr={{ autocomplete: 'new-password' }}
                            readOnly={readOnly}
                          />
                          <div
                          onClick={togglePasswordVisibility}
                          style={{
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer'
                          }}
                          >
                          {isPasswordVisible ? <EyeOff size={"18px"} /> : <Eye size={"18px"} />}
                          </div>
                      </div>
                    </div>
                      </div>



                      <div className='p-5 w-72 sm:w-96 lg:w-72 xl:w-96 flex flex-col gap-4'>
                  <div>
                    <TextBox
                      label={t("erpBranch")}
                      value={salesman.erpBranch}
                      readOnly={readOnly}
                      onValueChange={updateField('erpBranch')}

                    />
                  </div>

                  <div>
                    <TextBox
                      label={t("erpDiscLimit")}
                      value={salesman.erpDiscLimit}
                      onValueChange={updateField('erpDiscLimit')}
                      readOnly={readOnly}
                    />
                  </div>

                  <div>
                    <TextBox
                      label={t("erpWHCentral")}
                      value={salesman.erpWHCentral}
                      onValueChange={updateField('erpWHCentral')}
                      readOnly={readOnly}
                    />
                  </div>

                  <div>
                    <TextBox
                      label={t("erpWHReturn")}
                      value={salesman.erpWHReturn}
                      onValueChange={updateField('erpWHReturn')}
                      readOnly={readOnly}
                    />
                  </div>

                  <div>
                    <TextBox
                      label={t("erpWHScrap")}
                      value={salesman.erpWHScrap}
                      readOnly={readOnly}
                      onValueChange={updateField('erpWHScrap')}
                    />
                  </div>

                  <div>
                    <TextBox
                      label={t("comment")}
                      value={salesman.comment}
                      onValueChange={updateField('comment')}
                      readOnly={readOnly}
                    />
                  </div>
                      </div>
                     </div>


                        <div className='p-5 m-2 w-72 sm:w-96 lg:w-72 xl:w-96 rounded-md shadow-sm border flex flex-col border-gray-300 gap-4'>
                        <div>
                    <CheckBox
                    value={salesman.erpSalesOrderStatus == 1}
                    text={t("erpSalesOrderStatus")}
                    readOnly={readOnly}
                    onValueChanged={(e) => {
                        updateField('erpSalesOrderStatus')(e.value ? 1 : 0);
                    }}
                    />
                </div>
                <div>
                    <CheckBox
                    value={salesman.rightSalesOrder == 1}
                    text={t("rightSalesOrder")}
                    readOnly={readOnly}
                    onValueChanged={(e) => {
                        updateField('rightSalesOrder')(e.value ? 1 : 0);
                    }}
                    />
                </div>
                <div>
                    <CheckBox
                    value={salesman.rightSalesDispatch == 1}
                    text={t("rightSalesDispatch")}
                    readOnly={readOnly}
                    onValueChanged={(e) => {
                        updateField('rightSalesDispatch')(e.value ? 1 : 0);
                    }}
                    />
                </div>
                <div>
                    <CheckBox
                    value={salesman.rightSalesDispatchRt == 1}
                    text={t("rightSalesDispatchRt")}
                    readOnly={readOnly}
                    onValueChanged={(e) => {
                        updateField('rightSalesDispatchRt')(e.value ? 1 : 0);
                    }}
                    />
                </div>
                <div>
                    <CheckBox
                    value={salesman.rightSalesInvoice == 1}
                    text={t("rightSalesInvoice")}
                    readOnly={readOnly}
                    onValueChanged={(e) => {
                        updateField('rightSalesInvoice')(e.value ? 1 : 0);
                    }}
                    />
                </div>
                <div>
                    <CheckBox
                    value={salesman.rightSalesInvoiceRt == 1}
                    text={t("rightSalesInvoiceRt")}
                    readOnly={readOnly}
                    onValueChanged={(e) => {
                        updateField('rightSalesInvoiceRt')(e.value ? 1 : 0);
                    }}
                    />
                </div>

                        <div>
                    <CheckBox
                    value={salesman.rightPaymentCash == 1}
                    text={t("rightPaymentCash")}
                    readOnly={readOnly}
                    onValueChanged={(e) => {
                        updateField('rightPaymentCash')(e.value ? 1 : 0);
                    }}
                    />
                </div>
                <div>
                    <CheckBox
                    value={salesman.rightGetPdf == 1}
                    text={t("rightGetPdf")}
                    readOnly={readOnly}
                    onValueChanged={(e) => {
                        updateField('rightGetPdf')(e.value ? 1 : 0);
                    }}
                    />
                </div>
                <div>
                    <CheckBox
                    value={salesman.rightGetAllProducts == 1}
                    text={t("rightGetAllProducts")}
                    readOnly={readOnly}
                    onValueChanged={(e) => {
                        updateField('rightGetAllProducts')(e.value ? 1 : 0);
                    }}
                    />
                </div>
                <div>
                    <CheckBox
                    value={salesman.routeActive == 1}
                    text={t("routeActive")}
                    readOnly={readOnly}
                    onValueChanged={(e) => {
                        updateField('routeActive')(e.value ? 1 : 0);
                    }}
                    />
                </div>
                <div>
                    <CheckBox
                    value={salesman.routeChangeDataAfterSave == 1}
                    text={t("routeChangeDataAfterSave")}
                    readOnly={readOnly}
                    onValueChanged={(e) => {
                        updateField('routeChangeDataAfterSave')(e.value ? 1 : 0);
                    }}
                    />
                </div>
                <div>
                    <CheckBox
                    value={salesman.routeSendDataAfterSave == 1}
                    text={t("routeSendDataAfterSave")}
                    readOnly={readOnly}
                    onValueChanged={(e) => {
                        updateField('routeSendDataAfterSave')(e.value ? 1 : 0);
                    }}
                    />
                </div>
                        </div>

               </div>
               </div>
          </div>

        </ValidationGroup>
      </ScrollView>)} />
    </>
  );
};
