import React, { useState, useEffect, useCallback, useRef } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import './general-segmentation.scss';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes,
  Editing,
  Lookup,
  FilterPanel,
  FilterBuilderPopup,
  DataGridRef,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';
import {  ContactStatus as ContactStatusType, SegmentModel } from '../../types/crm-contact';
import { ContactStatus } from '../../components';
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { appInfo } from '../../app-info';
import { newSegmentModel } from '../../shared/constants';
import { generalSegmentationDeleteRequest, generalSegmentationListRequest } from '../../api/requests';
import SegmentPopup from '../../components/library/general-segmentations/popup';
import { segmentsListStatusFromSession, segmentsListStatusToSession } from '../../api/sessions';
import { customerSelectionTypes, productSelectionTypes } from '../../shared/select-boxes';
import { confirm } from 'devextreme/ui/dialog';



export const GeneralSegments = () => {
  const { t } = useTranslation();
  const filterStatusList = ["all", "active", "passive"];
  const [segmentStatus, setSegmentStatus] = useState("active");
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [segmentsList, setSegmentsList] = useState([]);

  const [isPanelOpened, setPanelOpened] = useState(false);
  const [isNewRecord, setIsNewRecord] = useState(false);
  const gridRef = useRef<DataGridRef>(null);

  const [rowData, setRowData] = useState<SegmentModel>(newSegmentModel);


  useEffect(() => {
    mainRefresh();
  }, []);

  function mainRefresh(){
    generalSegmentationListRequest().then((res)=>{
      setSegmentsList(res.dataSet || []);
      gridRef.current?.instance().endCustomLoading();
      setTimeout(() => {
        updateFilter();
      }, 50);
    })
  }

  function updateFilter(){

    if(segmentsListStatusFromSession()=="0"){
      gridRef.current?.instance().filter(['segmentStatus', '=', "0"]);
    }else if(segmentsListStatusFromSession()=="1"){
      gridRef.current?.instance().filter(['segmentStatus', '=', "1"]);
    }else{
      gridRef.current?.instance().clearFilter()
    }

    if(segmentsListStatusFromSession()=="0"){
      setSegmentStatus("passive")
    }else if(segmentsListStatusFromSession()=="1"){
      setSegmentStatus("active")
    }else{
      setSegmentStatus("all")
    }

  }

  const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {

    const { item: selected }: { item: FilterContactStatus } = e;
    if (selected == t("all")) {
      segmentsListStatusToSession("2")
      setSegmentStatus("all");
      gridRef.current?.instance().clearFilter();
    } else if(selected==t('active')){
      setSegmentStatus("active");
      gridRef.current?.instance().filter(['segmentStatus', '=', "1"]);
      segmentsListStatusToSession("1")
    }else if(selected == t('passive')){
      setSegmentStatus("passive");
      gridRef.current?.instance().filter(['segmentStatus', '=', "0"]);
      segmentsListStatusToSession("0")
    }


  }, []);

  function getTranslatedStatusList() {
    return filterStatusList.map((status) => t(status));
  }


  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
  }, [isPanelOpened]);


  const addSegment = useCallback(() => {
    setIsNewRecord(true);
    setRowData(newSegmentModel)
    setPanelOpened(true);
  },[]);

  // Yeni segment eklendiğinde listeyi tazeleyip yeni segmenti açar
  const openNewRow = useCallback((id) => {
    generalSegmentationListRequest().then((res)=>{
      setSegmentsList(res.dataSet || []);
      setRowData(res.dataSet.filter(item => item["segmentRef"] == id)[0])
      setIsNewRecord(false);
      gridRef.current?.instance().endCustomLoading();
      setPanelOpened(true)
      setTimeout(() => {
        updateFilter();
      }, 50);
    })

  }, []);

  var rowDataUpdate = (key) => (value) => {
    setRowData(prev => ({
      ...prev,
      [key]: value
    }));
  };

const onRowClick = useCallback(({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
  setIsNewRecord(false);
  setRowData(data);
  setPanelOpened(true);

}, []);

const onRowRemoving = (e) => {
  const segment = e.data;

  return generalSegmentationDeleteRequest(segment)
      .then((response) => {
          if (response.resCode == 200) {
              notify(t("successfull"), "success", 1000);
          } else {
              notify(response.resMessage, "error", 2500);
              mainRefresh();
              throw new Error(response.resMessage);

          }
      })
      .catch((error) => {
          console.error('Error deleting product:', error);
      });

};

const onSelectionChanged = (e) => {
  setSelectedRows(e.selectedRowsData);
};

async function deleteSelected(){
const result = await confirm(t("areYouSureDeleting"),t("deleteProcess"));
  if (result) {
    gridRef.current?.instance().beginCustomLoading(t("loading"));
    var i = 0;
    selectedRows.forEach((row, index) => {

      generalSegmentationDeleteRequest(row).then((response)=>{
            if(response["resCode"]==200){
            }else{
                notify(response["resMessage"],"error");
            }
            i++;
            if(i==selectedRows.length){
                gridRef.current?.instance().endCustomLoading();
                mainRefresh();
            }
        });
    });
  }
}

const handleContentReady = (e) => {
  setRowCount(e.component.totalCount());
};
  return (
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          className='grid theme-dependent'
          height='100%'
          focusedRowEnabled
          dataSource={segmentsList}
          keyExpr="segmentRef"
          onRowClick={onRowClick}
          onExporting={onExporting}
          onRowRemoving={onRowRemoving}
          onSelectionChanged={onSelectionChanged}
          noDataText={t("noData")}
          onContentReady={handleContentReady}
          allowColumnReordering
          allowColumnResizing
          showBorders
          remoteOperations
          ref={gridRef}
          editing={{
            mode: 'row',
            allowDeleting: true,
        }}>
          <Editing texts={{
                        editRow: t("edit"),
                        deleteRow: t("delete"),
                        confirmDeleteMessage: t("confirmDeleteMessage"),
                        confirmDeleteYes: t("yes"),
                        cancelRowChanges: t("no"),
                    }}  />

          <LoadPanel />
          <SearchPanel visible placeholder={t("customerSearch")}  />
          <ColumnChooser title={t("columnChooser")} enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <FilterPanel visible={true}/>
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

          <Toolbar>

          <Item location='before' locateInMenu='auto'>
              <DropDownButton
                items={getTranslatedStatusList()}
                stylingMode='text'
                text={t(segmentStatus)}
                dropDownOptions={dropDownOptions}
                useSelectMode
                onSelectionChanged={filterByStatus}
              />
          </Item>
          <Item name='searchPanel' location='before'  />

          <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                hint={t("refresh")}
                stylingMode='text'
                onClick={mainRefresh}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before' />

            <Item location="before">
                <DropDownButton
                    visible={selectedRows.length>0}
                    text={"("+ selectedRows.length+") "+ t("transactions")}
                    icon="menu"
                    width={"175"}
                    items={[
                        { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                      ]}
                    onItemClick={(e) => {
                        if(e.itemData.action=="deleteSelected"){
                            deleteSelected();
                        }
                      }}
                />
            </Item>

            <Item location='center' locateInMenu='auto'>
            {rowCount} {t("records")}
            </Item>

            <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                text={t("new")}
                type='default'
                stylingMode='contained'
                visible={true}
                onClick={addSegment}
              />
            </Item>

          </Toolbar>

          <Column
            dataField='segmentStatus'
            caption={t("status")}
            width={100}
            cellRender={ContactStatus}
          />
          <Column dataField='segmentName' caption={t("segmentName")} />
          <Column dataField='segmentCode' caption={t("segmentCode")} />
          <Column dataField='productSelectionType' caption={t("productSelectType")}>
          <Lookup dataSource={productSelectionTypes(t)} displayExpr="title" valueExpr="productSelectionType" ></Lookup>
          </Column>
          <Column dataField='customerSelectionType' caption={t("customerSelectionType")}>
          <Lookup dataSource={customerSelectionTypes(t)} displayExpr="title" valueExpr="customerSelectionType" ></Lookup>
          </Column>
          <Column dataField='speCode1' caption={t("speCode1")} visible={false} />
          <Column dataField='speCode2' caption={t("speCode2")} visible={false} />
          <Column dataField='comment' caption={t("comment")} />

        </DataGrid>

        <SegmentPopup openNewRow={openNewRow} rowData={rowData} rowDataUpdate={rowDataUpdate}  isPanelOpened={isPanelOpened} isNewRecord={isNewRecord} changePanelOpened={changePanelOpened} mainRefresh={mainRefresh}/>



      </div>

    </div>
  );
};



type FilterContactStatus = ContactStatusType | 'active';


const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Segments.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Segments.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto',  };
