import { Button, Popup, SelectBox, TextBox } from "devextreme-react";
import { useTranslation } from "react-i18next";
import { RegistrationStatus } from "../../utils/contact-status/ContactStatus";
import { useEffect, useState } from "react";
import { registrantsUpdateRecordRequest } from "../../../api/requests";
import notify from "devextreme/ui/notify";

export const RegistrantPopup = ({ registrant, visible, onHiding, refresh }) => {
    const {t} = useTranslation();
    const [user, setUser] = useState(registrant);
    const [selectedStatus, setSelectedStatus] = useState<number>(0);
    const [readOnly, setReadOnly] = useState(true);

    useEffect(()=>{
      setUser(registrant);
    },[visible])

  const statusOptions= [
    { value: 0, label: t("requested"), color:'rgb(78, 78, 193)' },
    { value: 1, label: t("approved"), color: 'green' },
    { value: 2, label: t("rejected"), color: 'rgb(208, 6, 6)' },
  ];

  const handleChange = (e: any) => {
    setSelectedStatus(e.value);
    updateField("requestStatus")(e.value);
  };

  const itemTemplate = (data) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: data.color,
            marginRight: '8px',
          }}
        ></span>
        {data.label}
      </div>
    );
  };

  const updateField = (key) => (value) => {
    setUser(prev => ({
      ...prev,
      [key]: value
    }));
  };

  function onSave(){
    registrantsUpdateRecordRequest(user).then((response)=>{
      if(response.resCode == 200){
        setReadOnly(true);
        notify(t("successfull"),"success");
        refresh();
      }else{
        notify(response.resMessage,"error");
      }
    })
  }

    return (
        <Popup
            visible={visible}
            onHiding={onHiding}
            title={`${registrant?.shopUserEmail || 'Kayıt Bilgisi'}`}
            height="auto"
            maxHeight={"95%"}
            minWidth={"50%"}
            maxWidth={1000}
            hideOnOutsideClick
            titleRender={()=>(
              <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                <Button icon="close" stylingMode="text" onClick={onHiding}></Button>
                <p className="ml-2 mt-0 justify-center font-bold text-lg">{`${registrant?.shopUserEmail || 'Kayıt Bilgisi'}`}</p>
                </div>

              </div>
            )}
            contentRender={() => (
                <div>
                    <div className="flex flex-row justify-between">
                        <div className="">
                            <SelectBox
                                dataSource={statusOptions}
                                value={selectedStatus}
                                defaultValue={0}
                                displayExpr="label"
                                valueExpr="value"
                                onValueChanged={handleChange}
                                itemRender={itemTemplate}
                                searchEnabled={false}
                                readOnly={readOnly}
                            ></SelectBox>
                        </div>

                        <div className="flex flex-row gap-x-1">
                        <Button
                            text={t("edit")}
                            icon='edit'
                            stylingMode='contained'
                            type='default'
                            onClick={()=>{setReadOnly(false)}}
                            visible={readOnly}
                         />
                        <Button
                            text={t("save")}
                            icon='save'
                            stylingMode='contained'
                            type='default'
                            visible={!readOnly}
                            onClick={onSave}
                         />

                        <div className="hidden sm:block">
                        <Button
                              text={t("cancel")}
                              icon='close'
                              stylingMode='contained'
                              style={{backgroundColor:"gray"}}
                              type='default'
                              onClick={()=>{setReadOnly(true)}}
                              visible={!readOnly}
                            />
                        </div>

                        <div className="hidden sm:block">
                        <Button
                              text={t("close")}
                              icon='close'
                              stylingMode='contained'
                              style={{backgroundColor:"gray"}}
                              type='default'
                              onClick={onHiding}
                              visible={readOnly}
                            />
                        </div>
                        </div>

                    </div>


                    <div className="flex flex-col md:flex-row gap-2">

                    <div className="p-5 my-2 w-full md:w-1/2 rounded-md shadow-sm border border-gray-300">
                  <p className="font-bold py-1 ">{t("firmInfo")}</p>
                  <hr className="p-1"></hr>
                    <div className="py-1">
                      <TextBox
                      label={t("firmName")}
                      value={user?.customerName}
                      readOnly={readOnly}
                      onValueChange={updateField('customerName')}
                    />
                    </div>

                    <div className="grid grid-cols-2 gap-2 pt-2">
                    <div>
                      <TextBox
                      label={t("taxID")}
                      value={user?.customerTaxNr}
                      readOnly={readOnly}
                      onValueChange={updateField('customerTaxNr')}
                    />
                    </div>
                    <div>
                     <TextBox
                      label={t("taxNumber")}
                      value={user?.customerVATNr}
                      readOnly={readOnly}
                      onValueChange={updateField('customerVATNr')}
                    />
                    </div>
                    </div>
                  </div>

                  <div className="p-5 my-2 w-full md:w-1/2 rounded-md gap-2 shadow-sm border border-gray-300">
                  <p className="font-bold py-1">{t("userInfo")}</p>
                  <hr className="p-1"></hr>

                      <div className="grid grid-cols-2 gap-2 py-1">

                      <div>
                      <TextBox
                          label={t("userName")}
                          value={user?.shopUserFirstname}
                          readOnly={readOnly}
                          onValueChange={updateField('shopUserFirstname')}
                        />
                      </div>

                      <div>
                       <TextBox
                          label={t("surname")}
                          value={user?.shopUserSurname}
                          readOnly={readOnly}
                          onValueChange={updateField('shopUserSurname')}
                        />
                      </div>

                      </div>

                      <div className="grid grid-cols-2 gap-2 pt-2">

                      <div>
                      <TextBox
                          label={t("phone")}
                          value={user?.shopUserPhone}
                          readOnly={readOnly}
                          onValueChange={updateField('shopUserPhone')}
                        />
                      </div>

                      <div>
                       <TextBox
                          label={t("email")}
                          value={user?.shopUserEmail}
                          readOnly={readOnly}
                          onValueChange={updateField('shopUserEmail')}
                        />
                      </div>



                      </div>
                </div>


                </div>


                <div className="flex flex-col md:flex-row gap-2">

                <div className="p-5 my-2 w-full md:w-1/2 rounded-md gap-2 shadow-sm border border-gray-300">
                  <p className="font-bold py-1">{t("firmAddress")}</p>
                  <hr className="p-1"></hr>
                      <div className="">
                       <TextBox
                          label={t("address")}
                          value={user?.customerAddress}
                          readOnly={readOnly}
                          onValueChange={updateField('customerAddress')}
                        />
                      </div>

                      <div className="grid grid-cols-5 gap-2 pt-2">

                      <div className="col-span-2">
                         <TextBox
                            label={t("postCode")}
                            value={user?.customerPostCode}
                            readOnly={readOnly}
                            onValueChange={updateField('customerPostCode')}
                          />
                      </div>

                      <div className="col-span-3">
                      <TextBox
                            label={t("city")}
                            value={user?.customerCity}
                            readOnly={readOnly}
                            onValueChange={updateField('customerCity')}
                          />
                      </div>



                      </div>

                      <div className="grid grid-cols-2 gap-2 pt-2">
                      <div>
                      <TextBox
                            label={t("country")}
                            value={user?.customerCountry}
                            readOnly={readOnly}
                            onValueChange={updateField('customerCountry')}
                          />
                      </div>

                      <div>
                         <TextBox
                            label={t("moreInfo")}
                            value={user?.customerAdrInfo}
                            readOnly={readOnly}
                            onValueChange={updateField('customerAdrInfo')}
                          />
                      </div>
                      </div>
                </div>

                <div className="p-5 my-2 w-full md:w-1/2 rounded-md gap-2 shadow-sm border border-gray-300">
                  <p className="font-bold py-1">{t("shippingAddress")}</p>
                  <hr className="p-1"></hr>
                      <div className="gap-2">
                      <TextBox
                            label={t("address")}
                            value={user?.shippingAddress}
                            readOnly={readOnly}
                            onValueChange={updateField('shippingAddress')}
                          />
                      </div>

                      <div className="grid grid-cols-5 gap-2 pt-2">

                      <div className="col-span-2">
                         <TextBox
                            label={t("postCode")}
                            value={user?.shippingPostCode}
                            readOnly={readOnly}
                            onValueChange={updateField('shippingPostCode')}
                          />
                      </div>

                      <div className="col-span-3">
                      <TextBox
                            label={t("city")}
                            value={user?.shippingCity}
                            readOnly={readOnly}
                            onValueChange={updateField('shippingCity')}
                          />
                      </div>
                      </div>

                      <div className="grid grid-cols-2 gap-2 pt-2">
                      <div>
                      <TextBox
                            label={t("country")}
                            value={user?.shippingCountry}
                            readOnly={readOnly}
                            onValueChange={updateField('shippingCountry')}
                          />
                      </div>

                      <div>
                         <TextBox
                            label={t("moreInfo")}
                            value={user?.shippingAdrInfo}
                            readOnly={readOnly}
                            onValueChange={updateField('shippingAdrInfo')}
                          />
                      </div>
                      </div>

                </div>

                </div>

                <div className="p-5 my-2 w-full rounded-md gap-2 shadow-sm border border-gray-300">
                  <p className="font-bold py-1">{t("comment")}</p>
                  <hr className="p-1"></hr>
                      <div className="gap-2">
                      <TextBox
                            value={user?.requestNote}
                            readOnly={readOnly}
                            onValueChange={updateField('requestNote')}
                          />
                      </div>
                </div>



                </div>
            )}
        />
    );
};
