import {
  AnalyticsDashboard,
  ERPExports,
  AnalyticsSalesReport,
  AnalyticsGeography,
  SignInPage,
  SignUpPage,
  ResetPasswordPage,
  UserProfile,
  B2BCustomerList,
  B2BCustomerGroups,
  B2BCustomerDetails,
  SalesUsers,
  SalesRoutes,
  GeneralCampaigns,
  GeneralProductImages,
  GeneralSegments,
  GeneralIncentives,
  GeneralNewProducts,
  FinanceTransaction,
  FinancePayments,
  B2BCustomerUsersList,
  Transactions,
  Registrations,
  GeneralCampaignGroups,
  B2BUserGroups,
  IncomingInvoices,
  OutgoingInvoices,
  CustomerParameters,
  SupplierParameters,
  OurParameters
} from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
  {
    path: '/b2b-customer-details',
    element: B2BCustomerDetails,
  },
  {
    path: '/b2b-customer-list',
    element: B2BCustomerList,
  },
  {
    path: '/b2b-customer-groups',
    element: B2BCustomerGroups,
  },
  {
    path: '/b2b-customer-users',
    element: B2BCustomerUsersList,
  },
  {
    path: '/b2b-user-groups',
    element: B2BUserGroups,
  },
  {
    path: '/sales-persons',
    element: SalesUsers,
  },
  {
    path: '/sales-routes',
    element: SalesRoutes,
  },
  {
    path: '/general-campaigns',
    element: GeneralCampaigns,
  },
  {
    path: '/general-campaign-groups',
    element: GeneralCampaignGroups,
  },
  {
    path: '/general-segmentations',
    element: GeneralSegments,
  },
  {
    path: '/general-incentives',
    element: GeneralIncentives,
  },
  {
    path: '/general-new-products',
    element: GeneralNewProducts,
  },
  {
    path: '/general-images',
    element: GeneralProductImages,
  },
  {
    path: '/finance-transaction',
    element: FinanceTransaction,
  },
  {
    path: '/b2b-registrations',
    element: Registrations,
  },
  {
    path: '/finance-payments',
    element: FinancePayments,
  },
  {
    path: '/incoming-invoices',
    element: IncomingInvoices,
  },
  {
    path: '/outgoing-invoices',
    element: OutgoingInvoices,
  },
  {
    path: '/customer-parameters',
    element: CustomerParameters,
  },
  {
    path: '/supplier-parameters',
    element: SupplierParameters,
  },
  {
    path: '/our-parameters',
    element: OurParameters,
  },
  {
    path: '/finance-finapi',
    element: Transactions,
  },
  {
    path: '/erp-exports',
    element: ERPExports,
  },
  {
    path: '/analytics-dashboard',
    element: AnalyticsDashboard,
  },
  {
    path: '/analytics-sales-report',
    element: AnalyticsSalesReport,
  },
  {
    path: '/analytics-geography',
    element: AnalyticsGeography,
  },
  {
    path: '/sign-in-form',
    element: SignInPage,
  },
  {
    path: '/sign-up-form',
    element: SignUpPage,
  },
  {
    path: '/reset-password-form',
    element: ResetPasswordPage,
  },
  {
    path: '/user-profile',
    element: UserProfile,
  },
];

export const appRoutes = routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
