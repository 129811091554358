import React, { useState } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';

import { Template } from 'devextreme-react/core/template';

import { UserPanel } from '../user-panel/UserPanel';
import { ThemeSwitcher } from '../theme-switcher/ThemeSwitcher';

import type { AppHeaderProps } from '../../../types';

import './AppHeader.scss';
import { DropDownButton } from 'devextreme-react';
import { UserMenuSection } from '../user-menu-section/UserMenuSection';
import i18next from 'i18next';
import { getLanguageFromCookie, setLanguageCookie } from '../../../api/cookies';
import { LanguageSelect } from '../language/select';
import { getFirmName, getVendorId } from '../../../api/sessions';
import logo2 from "../../../assets/logo2.png"

export const AppHeader = ({ menuToggleEnabled, title, toggleMenu, className }: AppHeaderProps) => {


  return (
    <header className={`header-component ${className}`}>
      <Toolbar className='header-toolbar'>
        <Item visible={menuToggleEnabled} location='before' widget='dxButton' cssClass='menu-button'>
          <Button icon='menu' stylingMode='text' onClick={toggleMenu} />
        </Item>
        <Item location='before' cssClass='header-title' text={title} visible={!!title} />


        <Item location="after">
          <div style={{ textAlign: 'right',marginRight:10 }}>
            <div>{getFirmName()}</div>
            <div style={{fontSize:11}}>{getVendorId()}</div>
          </div>
        </Item>

        <Item location='after' locateInMenu='auto'>
              <div className='separator' />
            </Item>

        <Item location='after' locateInMenu='never'>
          <ThemeSwitcher />
        </Item>
        <Item location='after'>
          <LanguageSelect/>
        </Item>

        <Item location='after' locateInMenu='auto' menuItemTemplate='userPanelTemplate'>
          <UserPanel menuMode='context' />
        </Item>
        <Template name='userPanelTemplate'>
          <UserPanel menuMode='list' />
        </Template>
      </Toolbar>
    </header>
  );
};
