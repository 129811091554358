import React, { useState, useEffect, useCallback, useRef } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import '../../theme/grid.scss';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes,
  Editing,
  Lookup,
  FilterPanel,
  FilterBuilderPopup,
  DataGridRef
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';
import { CampaignModel, ContactStatus as ContactStatusType } from '../../types/crm-contact';
import { ContactStatus } from '../../components';
import { newCampaignModel} from '../../shared/constants';
import {  campaignGroupsListRequest, generalCampaignDeleteRequest, generalCampaignPostRequest, generalCampaignsRequest,} from '../../api/requests';
import { useTranslation } from 'react-i18next';
import CampaignPopup from '../../components/library/general-campaigns-detail/popup';
import notify from 'devextreme/ui/notify';
import { DateBox } from 'devextreme-react';
import { campaignListStatusFromSession, campaignListStatusToSession } from '../../api/sessions';
import { appInfo } from '../../app-info';
import { customerSelectionTypes } from '../../shared/select-boxes';
import { confirm } from 'devextreme/ui/dialog';



export const GeneralCampaigns = () => {
  const { t } = useTranslation();
  const filterStatusList = ["all", "active", "passive"];
  const [campaignStatus, setCampaignStatus] = useState("active");
  const [rowCount, setRowCount] = useState(0);

  const [campaignsList, setCampaignsList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isPanelOpened, setPanelOpened] = useState(false);
  const [isNewRecord, setIsNewRecord] = useState(false);
  const gridRef = useRef<DataGridRef>(null);

  const [rowData, setRowData] = useState<CampaignModel>(newCampaignModel);
  const [campaignGroups, setCampaignGroups] = useState();


  useEffect(() => {
    mainRefresh();
    campaignGroupsListRequest().then((res) => {
      const filteredGroups = res.dataSet??[];
      setCampaignGroups(filteredGroups);
    });
  }, []);

  function mainRefresh(){
    generalCampaignsRequest().then((res)=>{
      setCampaignsList(res.dataSet || []);
      gridRef.current?.instance().endCustomLoading();
      setTimeout(() => {
        updateFilter();
      }, 50);
    })
  }

  function updateFilter(){
    if(campaignListStatusFromSession()=="0"){
      gridRef.current?.instance().filter(['campaignStatus', '=', "0"]);
    }else if(campaignListStatusFromSession()=="1"){
      gridRef.current?.instance().filter(['campaignStatus', '=', "1"]);
    }else{
      gridRef.current?.instance().clearFilter()
    }

    if(campaignListStatusFromSession()=="0"){
      setCampaignStatus("passive")
    }else if(campaignListStatusFromSession()=="1"){
      setCampaignStatus("active")
    }else{
      setCampaignStatus("all")
    }
  }

  const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {
    const { item: selected }: { item: FilterContactStatus } = e;
    if (selected == t("all")) {
      campaignListStatusToSession("2")
      setCampaignStatus("all");
      gridRef.current?.instance().clearFilter();
    } else if(selected==t('active')){
      setCampaignStatus("active");
      gridRef.current?.instance().filter(['campaignStatus', '=', "1"]);
      campaignListStatusToSession("1")
    }else if(selected == t('passive')){
      setCampaignStatus("passive");
      gridRef.current?.instance().filter(['campaignStatus', '=', "0"]);
      campaignListStatusToSession("0")
    }

  }, []);

  function getTranslatedStatusList() {
    return filterStatusList.map((status) => t(status));
  }

  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
  }, [isPanelOpened]);


  const addCampaign = useCallback(() => {
    setIsNewRecord(true);
    setRowData(newCampaignModel);
    changePanelOpened();
  },[]);


 const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${year}-${month}-${day}`;
};

 // Yeni kampanya eklendiğinde listeyi tazeleyip yeni kampanyayı açar
 const openNewRow = useCallback((id) => {
  generalCampaignsRequest().then((res)=>{
    setCampaignsList(res.dataSet || []);
    setRowData(res.dataSet.filter(item => item["campaignRef"] == id)[0])
    setIsNewRecord(false);
    setPanelOpened(true)
    setTimeout(() => {
      updateFilter();
    }, 50);
  })

}, []);

const onRowClick = useCallback(({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
  setIsNewRecord(false);
  setRowData(data);
  setPanelOpened(true);

}, []);

const onRowRemoving = (e) => {
  const campaign = e.data;

  return generalCampaignDeleteRequest(campaign)
      .then((response) => {
          if (response.resCode == 200) {
              notify(t("successfull"), "success", 1000);
          } else {
              notify(response.resMessage, "error", 2500);
              mainRefresh();
              throw new Error(response.resMessage);
          }
      })
      .catch((error) => {
          console.error('Error deleting product:', error);
      });
};

const onSelectionChanged = (e) => {
  setSelectedRows(e.selectedRowsData);
};

async function deleteSelected(){
const result = await confirm(t("areYouSureDeleting"),t("deleteProcess"));
  if (result) {
    gridRef.current?.instance().beginCustomLoading(t("loading"));
    var i = 0;
    selectedRows.forEach((row, index) => {

      generalCampaignDeleteRequest(row).then((response)=>{
            if(response["resCode"]==200){
            }else{
                notify(response["resMessage"],"error");
            }
            i++;
            if(i==selectedRows.length){
                gridRef.current?.instance().endCustomLoading();
                mainRefresh();
            }
        });
    });
  }
}

const handleContentReady = (e) => {
  setRowCount(e.component.totalCount());
};

  return (
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          className='grid theme-dependent'
          height='100%'
          focusedRowEnabled
          dataSource={campaignsList}
          keyExpr="campaignRef"
          onRowClick={onRowClick}
          onExporting={onExporting}
          onRowRemoving={onRowRemoving}
          onSelectionChanged={onSelectionChanged}
          onContentReady={handleContentReady}
          noDataText={t("noData")}
          allowColumnReordering
          allowColumnResizing
          showBorders
          ref={gridRef}
          editing={{
            mode: 'row',
            allowDeleting: true,
        }}>
          <Editing texts={{
                        editRow: t("edit"),
                        deleteRow: t("delete"),
                        confirmDeleteMessage: t("confirmDeleteMessage"),
                        confirmDeleteYes: t("yes"),
                        cancelRowChanges: t("no"),
                    }}  />

          <LoadPanel />
          <SearchPanel visible placeholder={t("customerSearch")}  />
          <ColumnChooser title={t("columnChooser")} enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

          <Toolbar>

          <Item location='before' locateInMenu='auto'>
              <DropDownButton
                items={getTranslatedStatusList()}
                stylingMode='text'
                text={t(campaignStatus)}
                dropDownOptions={dropDownOptions}
                useSelectMode
                onSelectionChanged={filterByStatus}
              />
          </Item>
          <Item name='searchPanel' location='before'  />

          <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                hint={t("refresh")}
                stylingMode='text'
                onClick={mainRefresh}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before' />

            <Item location="before">
                <DropDownButton
                    visible={selectedRows.length>0}
                    text={"("+ selectedRows.length+") "+ t("transactions")}
                    icon="menu"
                    width={"175"}
                    items={[
                        { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                      ]}
                    onItemClick={(e) => {
                        if(e.itemData.action=="deleteSelected"){
                            deleteSelected();
                        }
                      }}
                />
            </Item>

            <Item location='center' locateInMenu='auto'>
                {rowCount} {t("records")}
            </Item>

            <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                text={t("new")}
                type='default'
                stylingMode='contained'
                visible={true}
                onClick={addCampaign}
              />
            </Item>

          </Toolbar>
          <Column
            dataField='campaignStatus'
            caption={t("status")}
            width={100}
            cellRender={ContactStatus}
          />

          <Column
            dataField='campaignCode'
            caption={t("campaignCode")}
            width={175}
          />

          <Column dataField='campaignGroupRef' caption={t("campaignGroup")}>
            <Lookup dataSource={campaignGroups} displayExpr="groupName" valueExpr="groupRef" ></Lookup>
          </Column>

            <Column
            dataField='campaignName'
            caption={t("campaignName")}
          />

            <Column
            dataField='beginDate'
            caption={t("beginDate")}
          />

            <Column
            dataField='endDate'
            caption={t("endDate")}
          />

            <Column
            dataField='erpClSpeCode1'
            caption={t("specCode1")}
          />

            <Column
            dataField='erpCostCode'
            caption={t("erpCostCode")}
          />

            <Column
            dataField='erpClSpeCode2'
            caption={t("specCode2")}
            visible={false}
          />

            <Column
            dataField='comment'
            caption={t("comment")}
            visible={false}
          />

        </DataGrid>

        <CampaignPopup openNewRow={openNewRow} isNewRecord={isNewRecord} rowData={rowData}  isPanelOpened={isPanelOpened} changePanelOpened={changePanelOpened} mainRefresh={mainRefresh}/>



      </div>
    </div>
  );
};




type FilterContactStatus = ContactStatusType | 'active';


const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Campaigns.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Campaigns.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto',  };