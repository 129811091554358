
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckBox } from 'devextreme-react/check-box';
import classNames from 'classnames';
import { Button, ButtonTypes } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import Form, { Item as div, GroupItem, ColCountByScreen, Item, Label } from 'devextreme-react/form';
import { formatNumber } from 'devextreme/localization';
import {  CustomerUserModel } from '../../../types/crm-contact';
import { FormTextbox, FormPhoto, ContactStatus } from '../..';
import { useScreenSize } from '../../../utils/media-query';
import ValidationGroup from 'devextreme-react/validation-group';
import { customerUpdateRequest, customerUserUpdateRequest } from '../../../api/requests';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { TextBox } from 'devextreme-react';
import { Eye, EyeOff } from 'react-feather';


export const CustomerUserPanelDetails = (

  {customerUserRefresh, contact, isOpened, changePanelOpened, onDataChanged, changePanelPinned } :
  {customerUserRefresh:() => void, contact: CustomerUserModel, isOpened: boolean, changePanelOpened:(value: boolean)=> void, onDataChanged:(data)=> void, changePanelPinned:() => void }) => {

  const {t} = useTranslation();
  const [isPinned, setIsPinned] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const { isLarge, isMedium } = useScreenSize();
  const [user, setUser] = useState(contact)

  const passwordEditorOptions = { stylingMode: 'filled', placeholder:  t('password'), mode: 'password' };

  useEffect(()=>{
    setUser(contact)
    setPasswordVisible(false)
  },[contact,isOpened])

  const updateField = (key) => (value) => {
    setUser(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const onPinClick = useCallback(() => {
    setIsPinned(!isPinned);
  }, [isPinned]);

  const onClosePanelClick = useCallback(() => {
    setIsPinned(false);
    changePanelOpened(false);
  }, []);


  function onSaveClick () {
     updateCustomer();
     changePanelOpened(false);
  }


  var updateCustomer = async () => {
    try {
    await customerUserUpdateRequest(user).then(function(res){
        if(res.resCode == 200){
          customerUserRefresh();
          notify(t("updateSuccessfull"), "success", 1500);
        }else{
          notify(res.resMessage,"error",3000);
        }

      });
    } catch (error) {
      console.error('API hatası:', error);
    }
  };

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <div id='contact-panel' className={classNames({ 'panel': true, 'open': isOpened, 'pin': isPinned && (isLarge || isMedium) })}>
      <div className='' style={{ margin:"15px"}}>
        <Toolbar className='panel-toolbar'>
          <ToolbarItem location='before'>
            <span className='contact-name value'>{contact.userName}</span>
          </ToolbarItem>

          <ToolbarItem
            location='after'
            visible={isLarge || isMedium}
          >
            <Button
              icon={isPinned ? 'pin' : 'unpin'}
              stylingMode='text'
              onClick={onPinClick}
            />
          </ToolbarItem>
          <ToolbarItem location='after'>
            <Button
              icon='close'
              stylingMode='text'
              onClick={onClosePanelClick}
            />
          </ToolbarItem>
        </Toolbar>
        <ScrollView className='panel-scroll'>

          <ValidationGroup>
            <div className='data-part'>
              <div
                className={classNames({ 'plain-styled-form': true, 'view-mode': !isEditing })}
              >


                <div className='p-2 my-1 w-full flex flex-col gap-4'>

                    <div style={{width:300}}/>

                    <div>
                      <CheckBox
                        value={user.userActive==1}
                        text={t("active")}
                        onValueChanged={(e) => updateField("userActive")(e.value?1:0)}
                      />
                    </div>

                    <div>
                      <TextBox
                        label={t("userName")}
                        value={user.userName}
                        onValueChange={updateField('userName')}
                      />
                    </div>

                    <div>
                      <TextBox
                        label={t("email")}
                        value={user.userEmail}
                        onValueChange={updateField('userEmail')}
                        inputAttr={{ autocomplete: 'off' }}
                      />
                    </div>

                    <div>
                    <div style={{ position: 'relative' }}>
                      <TextBox
                        label={t("password")}
                        value={user.userPassword}
                        mode={isPasswordVisible ? 'text' : 'password'}
                        onValueChange={updateField('userPassword')}
                      />
                       <div
                        onClick={togglePasswordVisibility}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer'
                        }}
                      >
                        {isPasswordVisible ? <EyeOff /> : <Eye />}
                      </div>
                    </div>
                    </div>

                    <div>
                      <TextBox
                        label={t("comment")}
                        value={user.comment}
                        onValueChange={updateField('comment')}
                      />
                    </div>

                    <div className='w-full flex'>
                      <div className='pt-3 ml-auto'>
                        <Button text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
                      </div>
                    </div>

                </div>
              </div>
            </div>

          </ValidationGroup>

        </ScrollView>
      </div>
    </div>
  );
};
