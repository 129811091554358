export function xmlToCustomJson(xml) {
    // Ad alanlarını tanımlayalım
    const nsResolver = xml.createNSResolver(xml.documentElement);

    // querySelector helper fonksiyonu
    const select = (xpath, context = xml) => {
        try {
            return xml.evaluate(xpath, context, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue?.textContent || "";
        } catch (e) {
            console.error('XPath error:', e);
            return "";
        }
    };

    // Genel Fatura Bilgileri
    const general = {
        invoiceID: select("//cbc:ID"),
        issueDate: select("//cbc:IssueDate") + "T00:00:00",
        invoiceTypeCode: parseInt(select("//cbc:InvoiceTypeCode") || "0"),
        invoiceNote: select("//cbc:Note"),
        invoiceCurrency: select("//cbc:DocumentCurrencyCode"),
        buyerReference: select("//cbc:BuyerReference")
    };

    // Tedarikçi Bilgileri
    const supplier = {
        supplierEndpointID: select("//cac:AccountingSupplierParty/cac:Party/cbc:EndpointID"),
        supplierName: select("//cac:AccountingSupplierParty/cac:Party/cac:PartyName/cbc:Name"),
        supplierStreetName: select("//cac:AccountingSupplierParty/cac:Party/cac:PostalAddress/cbc:StreetName"),
        supplierCityName: select("//cac:AccountingSupplierParty/cac:Party/cac:PostalAddress/cbc:CityName"),
        supplierPostalZone: select("//cac:AccountingSupplierParty/cac:Party/cac:PostalAddress/cbc:PostalZone"),
        supplierCountryCode: select("//cac:AccountingSupplierParty/cac:Party/cac:PostalAddress/cac:Country/cbc:IdentificationCode"),
        supplierVATID: select("//cac:AccountingSupplierParty/cac:Party/cac:PartyTaxScheme/cbc:CompanyID"),
        supplierRegistrationName: select("//cac:AccountingSupplierParty/cac:Party/cac:PartyLegalEntity/cbc:RegistrationName"),
        supplierID: select("//cac:AccountingSupplierParty/cac:Party/cac:PartyLegalEntity/cbc:CompanyID"),
        supplierLegalForm: select("//cac:AccountingSupplierParty/cac:Party/cac:PartyLegalEntity/cbc:CompanyLegalForm")
    };

    // Alıcı Bilgileri
    const buyer = {
        buyerEndpointID: select("//cac:AccountingCustomerParty/cac:Party/cbc:EndpointID"),
        buyerIdentificationID: select("//cac:AccountingCustomerParty/cac:Party/cac:PartyIdentification/cbc:ID"),
        buyerStreetName: select("//cac:AccountingCustomerParty/cac:Party/cac:PostalAddress/cbc:StreetName"),
        buyerCityName: select("//cac:AccountingCustomerParty/cac:Party/cac:PostalAddress/cbc:CityName"),
        buyerPostalZone: select("//cac:AccountingCustomerParty/cac:Party/cac:PostalAddress/cbc:PostalZone"),
        buyerCountryCode: select("//cac:AccountingCustomerParty/cac:Party/cac:PostalAddress/cac:Country/cbc:IdentificationCode"),
        buyerRegistrationName: select("//cac:AccountingCustomerParty/cac:Party/cac:PartyLegalEntity/cbc:RegistrationName")
    };

    // Ödeme Bilgileri
    const payment = {
        paymentMeansCode: select("//cac:PaymentMeans/cbc:PaymentMeansCode"),
        supplierIBAN: select("//cac:PaymentMeans/cac:PayeeFinancialAccount/cbc:ID")
    };

    // Fatura Başlığı
    const header = {
        invoiceTaxAmount: parseFloat(select("//cac:TaxTotal/cbc:TaxAmount") || "0.00"),
        invoiceDiscounts: 0.00,
        invoiceTaxableAmount: parseFloat(select("//cac:TaxTotal/cac:TaxSubtotal/cbc:TaxableAmount") || "0.00"),
        invoiceTaxCategory: select("//cac:TaxTotal/cac:TaxSubtotal/cac:TaxCategory/cbc:ID"),
        invoiceTaxPercent: parseFloat(select("//cac:TaxTotal/cac:TaxSubtotal/cac:TaxCategory/cbc:Percent") || "0.00"),
        invoiceTaxScheme: select("//cac:TaxTotal/cac:TaxSubtotal/cac:TaxCategory/cac:TaxScheme/cbc:ID"),
        lineExtensionsAmount: parseFloat(select("//cac:LegalMonetaryTotal/cbc:LineExtensionAmount") || "0.00"),
        taxExclusiveAmount: parseFloat(select("//cac:LegalMonetaryTotal/cbc:TaxExclusiveAmount") || "0.00"),
        taxInclusiveAmount: parseFloat(select("//cac:LegalMonetaryTotal/cbc:TaxInclusiveAmount") || "0.00"),
        payableAmount: parseFloat(select("//cac:LegalMonetaryTotal/cbc:PayableAmount") || "0.00")
    };

    // Fatura Satırları
    const invoiceLines = [];
    const lines = xml.evaluate("//cac:InvoiceLine", xml, nsResolver, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);

    for (let i = 0; i < lines.snapshotLength; i++) {
        const line = lines.snapshotItem(i);
        invoiceLines.push({
            lineNr: parseInt(select(".//cbc:ID", line) || "0"),
            lineQuantity: parseInt(select(".//cbc:InvoicedQuantity", line) || "0"),
            lineExtensionsAmount: parseFloat(select(".//cbc:LineExtensionAmount", line) || "0.00"),
            itemDescription: select(".//cac:Item/cbc:Description", line),
            itemName: select(".//cac:Item/cbc:Name", line),
            itemCode: select(".//cac:Item/cac:SellersItemIdentification/cbc:ID", line),
            itemTaxCategory: select(".//cac:Item/cac:ClassifiedTaxCategory/cbc:ID", line),
            itemTaxPercent: parseFloat(select(".//cac:Item/cac:ClassifiedTaxCategory/cbc:Percent", line) || "0.00"),
            itemTaxScheme: select(".//cac:Item/cac:ClassifiedTaxCategory/cac:TaxScheme/cbc:ID", line),
            itemPrice: parseFloat(select(".//cac:Price/cbc:PriceAmount", line) || "0.00")
        });
    }

    return {
        invoiceGeneral: [general],
        supplierData: [supplier],
        buyerData: [buyer],
        paymentInformation: [payment],
        invoiceHeader: [header],
        InvoiceLines: invoiceLines
    };
}