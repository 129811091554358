import React, { useState, useEffect, useCallback, useRef } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';

import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes,
  DataGridRef,
  Editing
} from 'devextreme-react/data-grid';

import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';

import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';

import { ContactStatus as ContactStatusType, CustomerModel, CustomerUserModel } from "../../../types/crm-contact";

import { FormPopup, ContactNewForm, ContactPanel } from '../../../components';
import { ContactStatus } from '../../../components';

import { CONTACT_STATUS_LIST, newContact, newCustomerUserModel } from '../../../shared/constants';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { b2bUsersListDeleteWithCustomerRequest, customerUsersRequest, customerUserUpdateRequest, usersCustomersListRequest } from '../../../api/requests';
import { useTranslation } from 'react-i18next';
import { appInfo } from '../../../app-info';
import B2BCustomerSearchPopup2 from './searchCustomerPopup';
import { confirm } from 'devextreme/ui/dialog';

export const UsersCustomersList = ({user}) => {
  const {t} = useTranslation();
  const filterStatusList = [t("all"), t("active"), t("passive")];
  const [gridDataSource, setGridDataSource] = useState([]);
  const [isPanelOpened, setPanelOpened] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [formDataDefaults, setFormDataDefaults] = useState<CustomerUserModel>(newCustomerUserModel);
  const gridRef = useRef<DataGridRef>(null);
  const [searchPopupVisible, setSearchPopupVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);


  const [rowData, setRowData] = useState();

  let newContactData: CustomerUserModel;

  function switchSearchPopup(){
    setSearchPopupVisible(!searchPopupVisible);
  }

  const [refreshKey, setRefreshKey] = useState(0);
  function customerUserRefresh(){
    setRefreshKey(prevKey => prevKey + 1);
  }

  useEffect(() => {
    usersCustomersListRequest(user.userRef).then((res)=>{
      if(res.resCode == 200){
        setGridDataSource(res.dataSet)
      }else{
        notify(res.resMessage,"error");
      }
    });

  }, [refreshKey,user.userRef]);

  const changePopupVisibility = useCallback((isVisble) => {
    setPopupVisible(isVisble);
  }, []);

  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
    gridRef.current?.instance().option('focusedRowIndex', -1);
  }, [isPanelOpened]);

  const changePanelPinned = useCallback(() => {
    gridRef.current?.instance().updateDimensions();
  }, []);

  const onAddContactClick = useCallback(() => {
    setPopupVisible(true);
  }, []);

  const onRowClick = useCallback(({ data }: DataGridTypes.RowClickEvent) => {
    // data.customerRef = customerRef;
    // setRowData(data);
    // setPanelOpened(true);
  }, []);

  const [userActive, setuserActive] = useState(filterStatusList[1]);

  const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {
    const { item: userActive }: { item: FilterContactStatus } = e;
    if (userActive === t("all")) {
      gridRef.current?.instance().clearFilter();
    } else {
      gridRef.current?.instance().filter(['userActive', '=', userActive==t("active")?"1":"0"]);
    }

    setuserActive(userActive);
  }, []);

  const refresh = useCallback(() => {
    customerUserRefresh();
  }, []);

  const onDataChanged = useCallback((data) => {
    newContactData = data;
  }, []);

  const onSaveClick = useCallback(() => {

    // newContactData.customerRef = customerRef;

    // newCustomerUserRequest(newContactData).then((res)=>{
    //   if(res.resCode == 200){
    //     customerUserRefresh();
    //     notify({
    //       message: "Kullanıcı başarıyla eklendi",
    //       position: { at: 'bottom center', my: 'bottom center' }
    //     },'success');
    //     setFormDataDefaults({ ...formDataDefaults });
    // setPopupVisible(false);

    //   }else{
    //     notify({
    //       message: res.resMessage,
    //       position: { at: 'bottom center', my: 'bottom center' }
    //     },'error');
    //   }
    // });


  }, []);

  const onRowRemoving = (e) => {
    const row = e.data;

    return b2bUsersListDeleteWithCustomerRequest(user,row)
        .then((response) => {
            if (response.resCode == 200) {
                notify(t("successfull"), "success", 1000);
            } else {
                notify(response.resMessage, "error", 2500);
                throw new Error(response.resMessage);
            }
        })
        .catch((error) => {
            console.error('Error deleting product:', error);
        });
  };

  const onSelectionChanged = (e) => {
    setSelectedRows(e.selectedRowsData);
  };

  async function deleteSelected(){
  const result = await confirm(t("areYouSureDeleting"),t("deleteProcess"));
    if (result) {
      gridRef.current?.instance().beginCustomLoading(t("loading"));
      var i = 0;
      selectedRows.forEach((row, index) => {

        b2bUsersListDeleteWithCustomerRequest(user,row).then((response)=>{
              if(response["resCode"]==200){
              }else{
                  notify(response["resMessage"],"error");
              }
              i++;
              if(i==selectedRows.length){
                  gridRef.current?.instance().endCustomLoading();
                  refresh();
              }
          });
      });
    }
  }

  return (
    <div className=''>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          className='grid theme-dependent'
          focusedRowEnabled
          height='100%'
          keyExpr="customerUserRef"
          dataSource={gridDataSource}
          noDataText={t("noData")}
          onRowClick={onRowClick}
          onRowRemoving={onRowRemoving}
          onSelectionChanged={onSelectionChanged}
          onExporting={onExporting}
          allowColumnReordering
          allowColumnResizing
          showBorders
          ref={gridRef}
          editing={{
            mode: 'row',
            allowDeleting: true,
              }}>
          <Editing texts={{
                        editRow: t("edit"),
                        deleteRow: t("delete"),
                        confirmDeleteMessage: t("confirmDeleteMessage"),
                        confirmDeleteYes: t("yes"),
                        cancelRowChanges: t("no"),
                    }}  />

          <LoadPanel showPane={false} />
          <SearchPanel visible placeholder={t("userSearch")} />
          <ColumnChooser enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <Selection
            selectAllMode='allPages'
            showCheckBoxesMode='always'
            mode='multiple'
          />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Toolbar>

            <Item location='before' locateInMenu='auto'>
              <DropDownButton
                items={filterStatusList}
                stylingMode='text'
                text={userActive}
                dropDownOptions={dropDownOptions}
                useSelectMode
                onSelectionChanged={filterByStatus}
              />
            </Item>
            <Item name='searchPanel' locateInMenu='auto' location='before' />

            <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                text='Refresh'
                stylingMode='text'
                onClick={refresh}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before' />

            <Item location="before">
                <DropDownButton
                    visible={selectedRows.length>0}
                    text={"("+ selectedRows.length+") "+ t("transactions")}
                    icon="menu"
                    width={"175"}
                    items={[
                        { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                      ]}
                    onItemClick={(e) => {
                        if(e.itemData.action=="deleteSelected"){
                            deleteSelected();
                        }
                      }}
                />
            </Item>

            <Item location='after' locateInMenu='auto'>
              <Button
                icon='more'
                type='default'
                stylingMode='contained'
                onClick={switchSearchPopup}
              />
            </Item>


          </Toolbar>
          <Column caption={t("status")} dataField='customerActive' width={120}
                  cellRender={ContactStatus} alignment='left'></Column>
          <Column caption={t("customerCode")} dataField='customerCode' width={200}></Column>
          <Column caption={t("name")} dataField='customerName'></Column>
          <Column caption={t("comment")} dataField='comment'></Column>

        </DataGrid>
        <FormPopup title={t("new")} visible={popupVisible} setVisible={changePopupVisibility} onSave={onSaveClick}>
        </FormPopup>
        <B2BCustomerSearchPopup2 refresh={customerUserRefresh} visible={searchPopupVisible} onHiding={switchSearchPopup} user={user} ></B2BCustomerSearchPopup2>
      </div>
    </div>
  );
};




type FilterContactStatus = ContactStatusType | 'Active';

const cellNameRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
  <div className='name-template'>
    <div>{cell.data.userName}</div>
    <div className='position'>{cell.data.position}</div>
  </div>
);

const editCellStatusRender = () => (
  <SelectBox className='cell-info' dataSource={CONTACT_STATUS_LIST} itemRender={ContactStatus} fieldRender={fieldRender} />
);

const fieldRender = (text: string) => (
  <>
    <ContactStatus text={text} />
    <TextBox readOnly />
  </>
);

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Contacts.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CustomerUsers.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto' };

