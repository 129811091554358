const csv_to_json_func = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const csv = reader.result as string;
            const lines = csv.split('\n').filter(line => line.trim() !== ''); // Boş satırları çıkarıyoruz
            const headers = lines[0].split(';').map(header => header.trim()); // Noktalı virgülle ayırıyoruz

            const jsonArray = lines.slice(1).map(line => {
                const values = line.split(';').map(value => value.trim());
                const jsonObj: { [key: string]: string } = {};

                headers.forEach((header, index) => {
                    jsonObj[header] = values[index] || ""; // Boş değerler için default boş string
                });

                return jsonObj;
            });

            const jsonString = JSON.stringify(jsonArray, null, 2); // JSON string olarak döndür
            resolve(jsonString);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsText(file); // CSV dosyasını metin olarak oku
    });
};

export const csv_to_json = (): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.csv';

        fileInput.onchange = (event) => {
            const target = event.target as HTMLInputElement;
            const file = target.files ? target.files[0] : null;

            if (file) {
                csv_to_json_func(file)
                    .then(jsonString => {
                        resolve(jsonString); // JSON string'i döndür
                    })
                    .catch(error => {
                        reject(error); // Hata durumunda reject et
                    });
            } else {
                reject('No file selected');
            }
        };

        fileInput.click(); // Dosya seçim penceresini aç
    });
};
