import React, { useEffect, useState } from 'react';
import { CustomerUserModel } from '../../../types/crm-contact';
import { Button, CheckBox, SelectBox, TextBox } from 'devextreme-react';
import { b2bUsersListUpdateRequest, customerGroupsRequest } from '../../../api/requests';
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { Eye, EyeOff } from 'react-feather';
import { status_options, status_selectbox } from '../../../shared/status-selectbox';


export const ContactFromDetails = ({mainRefresh,closePopup, data, editing, openNewRow, updateField }) => {
  const {t} = useTranslation();
  const [readOnly, setReadOnly] = useState(true);
  const [user,setUser] = useState(data);
  const [userState,setUserState] = useState(data);

  useEffect(()=>{
    if(data.userRef == 0){
      setReadOnly(false);
    }else{
      setReadOnly(true);
    }

    setUser(data);
    setSelectedStatus(data.userActive);
  },[data.userRef])

  const updateFieldd = (key) => (value) => {
    setUser(prev => ({
      ...prev,
      [key]: value
    }));
  };

  var updateCustomer = async () => {

    b2bUsersListUpdateRequest(user).then((res)=>{
      if(res.resCode==200){
        if(user.userRef == 0){
          openNewRow(res.dataSet[0]["shopUserRef"]);
        }else{
          mainRefresh();
          setUserState(user)
          setReadOnly(true)
        }
        notify(t("successfull"),"success")

      }else{
        notify(res.resMessage,"error")
      }
    })
  };

  const passwordEditorOptions = { stylingMode: 'filled', placeholder:  t('password'), mode: 'password' };
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const [selectedStatus, setSelectedStatus] = useState<number>(0);

  const statusChange = (e: any) => {
    setSelectedStatus(e.value);
    updateFieldd('userActive')(e.value);
  };


  return (
    <div className=''>
      <div >

      <div className='flex flex-row justify-between'>

      <div className='pr-6'>
                <SelectBox
                    dataSource={status_options}
                    value={selectedStatus}
                    defaultValue={0}
                    displayExpr={(item) => t(item.label)}
                    valueExpr="value"
                    onValueChanged={statusChange}
                    itemRender={status_selectbox}
                    searchEnabled={false}
                    readOnly={readOnly}
                    />
            </div>

      <div hidden={readOnly}>
        <div style={{display:"flex",justifyContent:"flex-end"}}>
          <Button text={t("save")} icon='save' stylingMode='contained' type='default' onClick={updateCustomer} />
        <div style={{ marginLeft: '10px' }}>
        <Button
                      text={t("cancel")}
                      icon='close'
                      stylingMode='contained'
                      style={{backgroundColor:"gray"}}
                      type='default'
                      onClick={()=>{
                        if(data.userRef == 0){
                          closePopup();
                        }else{
                          setReadOnly(true)
                          setUser(userState)
                          statusChange({"value":userState.userActive})
                        }

                      }}
                    />
        </div>
        </div>
          </div>

          <div hidden={!readOnly}>
        <div style={{display:"flex",justifyContent:"flex-end"}}>
          <Button text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={()=>{setReadOnly(false)}} />
        <div style={{ marginLeft: '10px' }}>
          <Button text={t("close")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={closePopup} />
        </div>
        </div>
          </div>
      </div>




          <div className='p-5 my-4 w-full rounded-md shadow-sm border border-gray-300'>


          <div className='p-1'>
            <TextBox
              label={t("userName")}
              value={user.userName}
              readOnly={readOnly}
              onValueChange={updateFieldd('userName')}

            />
          </div>

          <div className='p-1'>
            <TextBox
              label={t("email")}
              value={user.userEmail}
              readOnly={readOnly}
              inputAttr={{ autocomplete: 'off' }}
              onValueChange={updateFieldd('userEmail')}

            />
          </div>


          <div className='p-1'>
                    <div style={{ position: 'relative' }}>
                      <TextBox
                        label={t("password")}
                        value={user.userPassword}
                        mode={isPasswordVisible ? 'text' : 'password'}
                        inputAttr={{ autocomplete: 'new-password' }}
                        onValueChange={updateFieldd('userPassword')}
                        readOnly={readOnly}
                      />
                       <div
                        onClick={togglePasswordVisibility}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer'
                        }}
                      >
                        {isPasswordVisible ? <EyeOff /> : <Eye />}
                      </div>
                    </div>
          </div>

          <div className='p-1'>
            <TextBox
              label={t("comment")}
              value={user.comment}
              readOnly={readOnly}
              onValueChange={updateFieldd('comment')}
            />
          </div>

        </div>

      </div>

    </div>
  );
};
