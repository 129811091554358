import { Button, DataGrid, DropDownButton, LoadPanel } from "devextreme-react";
import { salesRouteLineDeleteRequest, salesRouteLineListRequest, salesRouteLineUpdateRequest } from "../../../api/requests";
import { useCallback, useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { Column, ColumnChooser, Export, HeaderFilter, Scrolling, SearchPanel, Selection, Sorting,Toolbar,Item, Editing, RowDragging, Lookup, DataGridTypes, FilterPanel, FilterBuilderPopup, DataGridRef } from "devextreme-react/cjs/data-grid";
import { useTranslation } from "react-i18next";
import AddRouteLinePopup from "./addRouteLine";
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { appInfo } from '../../../app-info';
import { confirm } from "devextreme/ui/dialog";

export const RouteLinesList = ({route,readOnly,expandTable, setExpandTable,}) => {
  interface RouteLine {
    routeLineRef: number;
    routeLineActive: number;
    routeRef: number;
    routeLineType: number;
    routeLineNr: number;
    erpCustomerRef: number;
    erpCustomerCode: string;
    erpCustomerName: string;
    erpCustomerAdr: string;
    erpInvoiceAdr: string;
    erpShippingAdrRef: number;
    erpShippingAdr: string;
    formLineRef: number;
    routeLineStatus: number;
    beginTime: string;
    endTime: string;
    comment: string;
}

const [routeLines, setRouteLines] = useState<RouteLine[]>([]);
    const {t} = useTranslation();
    const gridRef = useRef<DataGridRef>(null);
    const [selectedRows, setSelectedRows] = useState([]);

    function request(){
      if(route.routeRef != 0){
        gridRef.current!.instance().beginCustomLoading(t("loading"));
        salesRouteLineListRequest(route.routeRef).then((response)=>{
          if(response.resCode==200){
              setRouteLines(response.dataSet)

          }else{
              notify(response.resMessage,"error");
          }
      }).finally(()=>{
        gridRef.current!.instance().endCustomLoading();
      });
      }
    }

    useEffect(()=>{
        request();
    },[])


    const processReorder = async (e: any) => {
      const oldData = e.itemData as RouteLine;


      const updatedRouteLines = [...routeLines];


      const [movedItem] = updatedRouteLines.splice(e.fromIndex, 1);


      updatedRouteLines.splice(e.toIndex, 0, movedItem);


      updatedRouteLines.forEach((item, index) => {
          item.routeLineNr = index + 1;
      });


      movedItem["newRouteLineNr"] = e.toIndex + 1;
      const response = await salesRouteLineUpdateRequest(movedItem);

      if (response.resCode == 200) {

          setRouteLines(updatedRouteLines);
      } else {
          alert("olmadı");
      }


      await e.component.refresh();
  };

  const onReorder = (e) => {
      e.promise = processReorder(e);
  };




    const routeLineTypes = [{routeLineType: 1,title: t("inRoute"),}, {routeLineType: 2,title: t("outOfRoute"),},];
    const routeLineStatus = [{routeLineStatus: 0,title: t("waiting"),}, {routeLineStatus: 1,title: t("started"),}, {routeLineStatus: 2,title: t("completed"),},];

    const onCellPrepared = (e) => {
        if (e.rowType == 'data' && e.column.dataField == 'routeLineStatus') {
          const status = e.data.routeLineStatus;

          if (status == 0) { // Bekliyor
            e.cellElement.classList.add('status-waiting');
          } else if (status == 1) { // Devam Ediyor
            e.cellElement.classList.add('status-started');
          } else if (status == 2) { // Tamamlandı
            e.cellElement.classList.add('status-completed');
          }
        }
      };

      const onRowUpdating = (e) => {
        const updatedData = { ...e.oldData, ...e.newData };
        e.newData = updatedData;
        salesRouteLineUpdateRequest(updatedData).then((response) => {
          if (response.resCode === 200) {
              notify(t("updateSuccessfull"), "success", 1000);
              request();
          } else {
              notify(response["resMessage"], "error", 2500);
              request();
          }
      });
      }

      const [isPanelOpened, setPanelOpened] = useState(false);
      const customerSearchPanelOpen = useCallback(() => {
        if(!readOnly){
            notify(t("saveAboveInformation"),"warning");
        }else{
            setPanelOpened(!isPanelOpened);
        }
    }, [isPanelOpened,readOnly]);

    const onRowRemoving = (e) => {
      const line = e.data;

      return salesRouteLineDeleteRequest(line)
          .then((response) => {
              if (response.resCode === 200) {
                  notify(t("successfull"), "success", 1000);
                  request();
              } else {
                  notify(response.resMessage, "error", 2500);
                  request();
                  throw new Error(response.resMessage);
              }
          })
          .catch((error) => {
              console.error('Error deleting product:', error);
          });
  };

  const onExporting = (e: DataGridTypes.ExportingEvent) => {
    if (e.format === 'pdf') {
      const doc = new JsPdf();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
      }).then(() => {
        doc.save('RouteLines.pdf');
      });
    } else {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Page1');

      exportDataGridToXLSX({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'RouteLines.xlsx');
        });
      });
      e.cancel = true;
    }
  };

  const onSelectionChanged = (e) => {
    setSelectedRows(e.selectedRowsData);
};

  async function deleteSelected(){
  const result = await confirm(t("areYouSureDeleting"),t("deleteProcess"));
    if (result) {
      gridRef.current?.instance().beginCustomLoading(t("loading"));
      var i = 0;
      var y = 0;
      selectedRows.forEach((row, index) => {
        y++;
        setTimeout(()=>{
          salesRouteLineDeleteRequest(row).then((response)=>{
            if(response["resCode"]==200){
            }else{
                notify(response["resMessage"],"error");
            }
            i++;
            if(i==selectedRows.length){
                gridRef.current?.instance().endCustomLoading();
                request();
            }
        });
        },y*100)

      });
    }
}

function expandTableFunc(){
  if(expandTable){
    setExpandTable(false)
  }else{
    setExpandTable(true)
  }
}

    return(
      <>
        <DataGrid
        className="custom-grid"
            dataSource={routeLines}
            disabled={!readOnly}
            keyExpr="routeLineRef"
            allowColumnResizing
            onCellPrepared={onCellPrepared}
            onRowUpdating={onRowUpdating}
            onRowRemoving={onRowRemoving}
            onSelectionChanged={onSelectionChanged}
            onExporting={onExporting}
            ref={gridRef}
            allowColumnReordering
            rowAlternationEnabled
            height={expandTable?"calc(90vh - 95px)":"calc(90vh - 320px)"}
            >
          <LoadPanel showPane={false}  />
          <SearchPanel visible  width={"300px"} placeholder={t("search")}  />
          <ColumnChooser  enabled title={t("columnChooser")} />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <RowDragging allowReordering={true} onReorder={onReorder} dropFeedbackMode="push" />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />
          <Editing
                    mode="row"
                    allowDeleting
                    allowUpdating
                    texts={{
                        editRow: t("edit"),
                        deleteRow: t("delete"),
                        confirmDeleteMessage: t("confirmDeleteMessage"),
                        confirmDeleteYes: t("yes"),
                        cancelRowChanges: t("no"),
                    }}
            />

            <Toolbar>
                    <Item name='searchPanel' location='before'  />

                    <Item location='before' locateInMenu='auto'>
                    <Button
                            icon='refresh'
                            stylingMode='text'
                            hint={t("refresh")}
                            onClick={request}
                        />
                    </Item>

                    <Item name='columnChooserButton' location='before' />

                    <Item name='exportButton' location='before' />

                    <Item location="before">
                      <DropDownButton
                      visible={selectedRows.length>0}
                        text={t("transactions")}
                        icon="menu"
                        width={"150"}
                        items={[
                          { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                        ]}
                        onItemClick={(e) => {
                          if(e.itemData.action=="deleteSelected"){
                            deleteSelected();
                          }
                        }}
                      />
                    </Item>


                    <Item location="after">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        icon='plus' // "Add" butonu
                        stylingMode='contained'
                        type="default"
                        hint={t("new")}
                        onClick={() => setPanelOpened(true)} // Yeni satır eklemek için
                        disabled={!readOnly}
                    />
                    <div style={{ marginLeft: '10px' }}>
                            <Button
                                icon={expandTable?"chevrondown":"fullscreen"}
                                type="default"
                                hint={t("expandTable")}
                                stylingMode="contained"
                                onClick={expandTableFunc}
                                disabled={!readOnly}
                            />
                            </div>
                            </div>
                    </Item>

                    </Toolbar>


                <Column dataField="routeLineNr" caption={t("routeLineNr")} width={60} allowEditing={false}/>
                <Column dataField="routeLineStatus" width={140} caption={t("routeLineStatus")}>
                <Lookup dataSource={routeLineStatus} displayExpr="title" valueExpr="routeLineStatus" />
                </Column>
                <Column dataField="routeLineType" width={140} caption={t("routeLineType")}>
                <Lookup dataSource={routeLineTypes} displayExpr="title" valueExpr="routeLineType" />
                </Column>
                <Column dataField="erpCustomerCode" caption={t("customerCode")} width={140} allowEditing={false}/>
                <Column dataField="erpCustomerName"  caption={t("customerName")} allowEditing={false}/>
                <Column dataField="erpInvoiceAdr" caption={t("address")} allowEditing={false}/>
                <Column dataField="erpCustomerAdr" caption={t("erpCustomerAdr")} visible={false} allowEditing={false}/>
                <Column dataField="erpShippingAdr"  caption={t("erpShippingAdr")} visible={false} allowEditing={false}/>

                <Column allowEditing={false} width={140}
                dataType="datetime"
                dataField="beginTime" caption={t("beginDate")}
                    customizeText={(cellInfo) => {
                const date = new Date(cellInfo.value);
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                const seconds = date.getSeconds().toString().padStart(2, '0');
                return `${hours}:${minutes}:${seconds}`;
                }} />

                <Column allowEditing={false} width={140}
                dataType="datetime"
                dataField="endTime" caption={t("endDate")}
                    customizeText={(cellInfo) => {
                        const date = new Date(cellInfo.value);
                        const hours = date.getHours().toString().padStart(2, '0');
                        const minutes = date.getMinutes().toString().padStart(2, '0');
                        const seconds = date.getSeconds().toString().padStart(2, '0');
                        return `${hours}:${minutes}:${seconds}`;
                    }}
                    />
        </DataGrid>
        <AddRouteLinePopup route={route} visible={isPanelOpened} onHiding={customerSearchPanelOpen} refresh={request}></AddRouteLinePopup>
        </>
    );
}