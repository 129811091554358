import { useTranslation } from "react-i18next";

export const status_options= [
    { value: 1, label: "active", color: '#03a9f4' },
    { value: 0, label: "passive", color:'#de8e8c' },
    { value: 3, label: "archived", color: 'black' },
  ];

export const status_selectbox = (data) => {
    const {t} = useTranslation();
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: data.color,
            marginRight: '8px',
          }}
        ></span>
        {t(data.label)}
      </div>
    );
  };

