import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./payments.scss"
import { PaymentsListSell } from './listSell';
import { PaymentsListBuy } from './listBuy';


export const FinancePayments = ({}) => {

    const [selectedTab, setSelectedTab] = useState(0);

    const {t} = useTranslation();

    return (
        <div className='view crm-contact-list'>
            <div className='view-wrapper view-wrapper-contact-list list-page'>
        <TabPanel
        className='grid theme-dependent'
        selectedIndex={selectedTab} // İlk sekme seçili
        onSelectionChanged={(e) => {
            const selectedIndex = e.component.option('selectedIndex');
            if (typeof selectedIndex === 'number') {
                setSelectedTab(selectedIndex); // undefined kontrolü yapıyoruz
                            }
                        }}
                    >
                <TabPanelItem title={t("Sales Invoices")}>
                  <div style={{marginTop:"10px"}}><PaymentsListSell/></div>
                </TabPanelItem>
                <TabPanelItem title={t("Purchase Invoices")}>
                <div style={{marginTop:"10px"}}><PaymentsListBuy/></div>

                </TabPanelItem>

            </TabPanel>
            </div>
            </div>
    );
};
















