import React, { useState, useEffect, useCallback, useRef } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import './b2b-customer-groups.scss';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes,
  FilterPanel,
  FilterBuilderPopup,
  DataGridRef,
  Editing
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { ContactStatus as ContactStatusType } from '../../types/crm-contact';
import { ContactStatus } from '../../components';
import { newCustomerGroupModel } from '../../shared/constants';
import { customerGroupDeleteRequest, customerGroupsRequest} from '../../api/requests';
import { useTranslation } from 'react-i18next';
import { ContactPanel } from '../../components/library/customer-groups/ContactPanel';
import { appInfo } from '../../app-info';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';


export const B2BCustomerGroups = () => {
  const { t } = useTranslation();
  const filterStatusList = [t("all"), t("active"), t("passive")];
  const [gridDataSource, setGridDataSource] = useState([]);
  const [isPanelOpened, setPanelOpened] = useState(false);
  const gridRef = useRef<DataGridRef>(null);
  const [rowCount, setRowCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const [rowData, setRowData] = useState(newCustomerGroupModel);

  function mainRefresh(){

    customerGroupsRequest().then((res)=>{
        setGridDataSource(res.data.dataSet);
      gridRef.current?.instance().endCustomLoading();
    })

  }


  useEffect(() => {

    customerGroupsRequest().then((e)=>{
        setGridDataSource(e.data.dataSet);
    setTimeout(() => {
      if(customerActive == t("passive")){
        gridRef.current?.instance().filter(['customerActive', '=', "0"]);
      }else if(customerActive == t("active")){
        gridRef.current?.instance().filter(['customerActive', '=', "1"]);
      }else{
        gridRef.current?.instance().clearFilter()
      }
      setTimeout(()=>{
     //   gridRef.current?.instance().navigateToRow(customerListSelectFromSession());
      },100)

    }, 100);
  })



    setTimeout(() => {
      if(customerActive == t("passive")){
        gridRef.current?.instance().filter(['customerActive', '=', "0"]);
      }else if(customerActive == t("active")){
        gridRef.current?.instance().filter(['customerActive', '=', "1"]);
      }else{
        gridRef.current?.instance().clearFilter()
      }
      setTimeout(()=>{
       // gridRef.current?.instance().navigateToRow(customerListSelectFromSession());
      },100)

    }, 100);


  }, []);


  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
  }, [isPanelOpened]);

  const changePanelPinned = useCallback(() => {
    gridRef.current?.instance().updateDimensions();
  }, []);

  const onAddContactClick = useCallback(() => {
    setRowData(newCustomerGroupModel)
    setPanelOpened(true);
  }, []);

  const onRowClick = useCallback(({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
    setRowData(data);
    setPanelOpened(true);

  }, []);

  const [customerActive, setCustomerActive] = useState(t("active"));

  const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {
    const { item: customerActive }: { item: FilterContactStatus } = e;

    if (customerActive === t("all")) {
      gridRef.current?.instance().clearFilter();
    } else {
      gridRef.current?.instance().filter(['customerActive', '=', customerActive==t("active")?"1":"0"]);
    }

    setCustomerActive(customerActive);

  }, []);

  const refresh = useCallback(() => {
    mainRefresh();
    gridRef.current?.instance().beginCustomLoading(t("loading"))

  }, []);


  const handleContentReady = (e) => {
    setRowCount(e.component.totalCount());
  };

  const onRowRemoving = (e) => {
    const cg = e.data;

    return customerGroupDeleteRequest(cg)
        .then((response) => {
            if (response.resCode == 200) {
                notify(t("successfull"), "success", 1000);
            } else {
                notify(response.resMessage, "error", 2500);
                mainRefresh();
                throw new Error(response.resMessage);
            }
        })
        .catch((error) => {
            console.error('Error deleting product:', error);
        });
  };
  const onSelectionChanged = (e) => {
    setSelectedRows(e.selectedRowsData);
  };

  async function deleteSelected(){
    const result = await confirm(t("areYouSureDeleting"),t("deleteProcess"));
      if (result) {
        gridRef.current?.instance().beginCustomLoading(t("loading"));
        var i = 0;
        selectedRows.forEach((row, index) => {

          customerGroupDeleteRequest(row).then((response)=>{
                if(response["resCode"]==200){
                }else{
                    notify(response["resMessage"],"error");
                }
                i++;
                if(i==selectedRows.length){
                    gridRef.current?.instance().endCustomLoading();
                    mainRefresh();
                }
            });
        });
      }
    }


  return (
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          className='grid theme-dependent'
          height='100%'
          keyExpr="customerGroupRef"
          focusedRowEnabled
          dataSource={gridDataSource}
          noDataText={t("noData")}
          onRowClick={onRowClick}
          onExporting={onExporting}
          onRowRemoving={onRowRemoving}
          onContentReady={handleContentReady}
          onSelectionChanged={onSelectionChanged}
          allowColumnReordering
          allowColumnResizing
          showBorders
          editing={{
            mode: 'row',
            allowDeleting: true,
        }}
          ref={gridRef}>
          <Editing texts={{
                        editRow: t("edit"),
                        deleteRow: t("delete"),
                        confirmDeleteMessage: t("confirmDeleteMessage"),
                        confirmDeleteYes: t("yes"),
                        cancelRowChanges: t("no"),
                    }}  />
          <LoadPanel showPane={false}  />
          <SearchPanel visible placeholder={t("customerSearch")}  />
          <ColumnChooser enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />

          <Toolbar>

            <Item location='before' locateInMenu='auto'>
              <DropDownButton
                items={filterStatusList}
                stylingMode='text'
                text={customerActive}
                dropDownOptions={dropDownOptions}
                useSelectMode
                onSelectionChanged={filterByStatus}
              />
            </Item>

            <Item name='searchPanel' location='before' />

            <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                text='Refresh'
                stylingMode='text'
                onClick={refresh}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before'/>

            <Item location="before">
                <DropDownButton
                    visible={selectedRows.length>0}
                    text={"("+ selectedRows.length+") "+ t("transactions")}
                    icon="menu"
                    width={"175"}
                    items={[
                        { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                      ]}
                    onItemClick={(e) => {
                        if(e.itemData.action=="deleteSelected"){
                            deleteSelected();
                        }
                      }}
                />
            </Item>

            <Item location='center' locateInMenu='auto'>
                {rowCount} {t("records")}
            </Item>

            <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                type='default'
                stylingMode='contained'
                visible={true}
                onClick={onAddContactClick}
              />
            </Item>
          </Toolbar>
          <Column
            dataField='customerActive'
            caption={t("status")}
            width={100}
            cellRender={ContactStatus}
          />

            <Column
            dataField='customerGroupName'
            caption={t("groupName")}
          />

          <Column
            dataField='erpSalesManCode'
            caption={t("salesmanCode")}
          />

          <Column
            dataField='customerGroupCode'
            caption={t("groupCode")}
          />

          <Column
            dataField='erpWarehouse'
            caption="ERP Warehouse"
          />

          <Column
            dataField='erpBranch'
            caption="ERP Branch"
          />

          <Column
            dataField='erpFirmNr'
            caption="ERP Firm Nr"
          />


          <Column
            dataField='comment'
            caption={t("comment")}
            visible={false}
          />


        </DataGrid>
        <ContactPanel mainRefresh={mainRefresh}  rowData={rowData} isOpened={isPanelOpened} changePanelOpened={changePanelOpened} changePanelPinned={changePanelPinned} />

      </div>
    </div>
  );
};




type FilterContactStatus = ContactStatusType | 'Active';




const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('CustomerGroups.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CustomerGroups.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto' };