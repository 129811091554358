import { js2xml } from 'xml-js';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


// ISO 8601 formatına dönüştürme yardımcı fonksiyonu
function formatToISODate(dateString) {
    try {
        // Eğer tarih zaten ISO formatındaysa direkt döndür
        if (/^\d{4}-\d{2}-\d{2}/.test(dateString)) {
            return dateString.split('T')[0];
        }

        // DD.MM.YYYY formatındaki tarihi parse et
        const [day, month, year] = dateString.split('.');
        // YYYY-MM-DD formatına dönüştür
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    } catch (error) {
        console.error('Date format error:', error);
        throw new Error('Invalid date format');
    }
}

// Vade tarihi hesaplama fonksiyonu (ISO 8601 formatında)
function calculateIsoDueDate(invoiceDate) {
    try {
        const date = new Date(invoiceDate);
        date.setDate(date.getDate() + 30);
        return date.toISOString().split('T')[0]; // YYYY-MM-DD formatı
    } catch (error) {
        console.error('Due date calculation error:', error);
        throw new Error('Due date calculation failed');
    }
}

function validateCountryCode(code) {
    // ISO 3166-1 alpha-2 için Almanya geçerli kodları
    const validGermanyCodes = ['DE', 'DEU', '276'];
    const normalizedCode = code ? code.toUpperCase() : 'DE';

    // Eğer kod geçerli değilse varsayılan olarak 'DE' döndür
    return validGermanyCodes.includes(normalizedCode) ? 'DE' : 'DE';
}

// Değerleri kontrol eden ve varsayılan değer atayan yardımcı fonksiyon
function sanitizeValue(value, defaultValue = 'Bilinmiyor') {
    if (value === undefined || value === null || value === '') return defaultValue;
    if (typeof value === 'number') return value.toString();
    if (typeof value === 'string' && value.trim() === '') return defaultValue;
    if (typeof value === 'string') return value.trim();
    return defaultValue;
}

export const convertJSONtoXML = (invoiceData) => {
    try {
        const root = {
            _declaration: {
                _attributes: { version: '1.0', encoding: 'UTF-8' }
            },
            'ubl:Invoice': {
                _attributes: {
                    'xmlns:ubl': 'urn:oasis:names:specification:ubl:schema:xsd:Invoice-2',
                    'xmlns:cac': 'urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2',
                    'xmlns:cbc': 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2'
                },
                'cbc:CustomizationID': {
                    _text: 'urn:cen.eu:en16931:2017#compliant#urn:xeinkauf.de:kosit:xrechnung_3.0'
                },
                'cbc:ProfileID': {
                    _text: 'urn:fdc:peppol.eu:2017:poacc:billing:01:1.0'
                },
                'cbc:ID': { _text: sanitizeValue(invoiceData.invoiceGeneral[0].invoiceID) },
                'cbc:IssueDate': { _text: formatToISODate(invoiceData.invoiceGeneral[0].issueDate) },
                'cbc:InvoiceTypeCode': { _text: sanitizeValue(invoiceData.invoiceGeneral[0].invoiceTypeCode, '380') },
                'cbc:Note': { _text: sanitizeValue(invoiceData.invoiceGeneral[0].invoiceNote, 'Fatura notu bulunmuyor') },
                'cbc:DocumentCurrencyCode': { _text: sanitizeValue(invoiceData.invoiceGeneral[0].invoiceCurrency, 'EUR') },
                'cbc:BuyerReference': { _text: sanitizeValue(invoiceData.invoiceGeneral[0].buyerReference, 'Referans bilinmiyor') },

                // Satıcı Bilgileri
                'cac:AccountingSupplierParty': {
                    'cac:Party': {
                        'cbc:EndpointID': {
                            _attributes: { schemeID: 'EM' },
                            _text: sanitizeValue(invoiceData.supplierData[0].supplierEndpointID, 'supplier@bilinmiyor.de')
                        },
                        'cac:PartyName': {
                            'cbc:Name': { _text: sanitizeValue(invoiceData.supplierData[0].supplierName, 'Satıcı firma bilinmiyor') }
                        },
                        'cac:PostalAddress': {
                            'cbc:StreetName': { _text: sanitizeValue(invoiceData.supplierData[0].supplierStreetName, 'Adres bilinmiyor') },
                            'cbc:CityName': { _text: sanitizeValue(invoiceData.supplierData[0].supplierCityName, 'Şehir bilinmiyor') },
                            'cbc:PostalZone': { _text: sanitizeValue(invoiceData.supplierData[0].supplierPostalZone, '00000') },
                            'cac:Country': {
                                'cbc:IdentificationCode': { _text: sanitizeValue(invoiceData.supplierData[0].supplierCountryCode, 'DE') }
                            }
                        },
                        'cac:PartyTaxScheme': {
                            'cbc:CompanyID': { _text: sanitizeValue(invoiceData.supplierData[0].supplierVATID, 'DE000000000') },
                            'cac:TaxScheme': { 'cbc:ID': { _text: 'VAT' } }
                        },
                        'cac:PartyLegalEntity': {
                            'cbc:RegistrationName': { _text: sanitizeValue(invoiceData.supplierData[0].supplierRegistrationName, 'Firma adı bilinmiyor') },
                            'cbc:CompanyID': { _text: sanitizeValue(invoiceData.supplierData[0].supplierID, 'HRB 000000') },
                            'cbc:CompanyLegalForm': { _text: sanitizeValue(invoiceData.supplierData[0].supplierLegalForm, 'GmbH') }
                        },
                        'cac:Contact': {
                            'cbc:Name': { _text: sanitizeValue(invoiceData.supplierData[0].supplierContactPerson, 'İletişim kişisi bilinmiyor') },
                            'cbc:Telephone': { _text: sanitizeValue(invoiceData.supplierData[0].supplierPhone, '+49 000-00000') },
                            'cbc:ElectronicMail': { _text: sanitizeValue(invoiceData.supplierData[0].supplierEmail, 'contact@bilinmiyor.de') }
                        }
                    }
                },

                // Alıcı Bilgileri
                'cac:AccountingCustomerParty': {
                    'cac:Party': {
                        'cbc:EndpointID': {
                            _attributes: { schemeID: 'EM' },
                            _text: sanitizeValue(invoiceData.buyerData[0].buyerEndpointID, 'buyer@bilinmiyor.de')
                        },
                        'cac:PartyIdentification': {
                            'cbc:ID': { _text: sanitizeValue(invoiceData.buyerData[0].buyerIdentificationID, 'Alıcı ID bilinmiyor') }
                        },
                        'cac:PostalAddress': {
                            'cbc:StreetName': { _text: sanitizeValue(invoiceData.buyerData[0].buyerStreetName, 'Adres bilinmiyor') },
                            'cbc:CityName': { _text: sanitizeValue(invoiceData.buyerData[0].buyerCityName, 'Şehir bilinmiyor') },
                            'cbc:PostalZone': { _text: sanitizeValue(invoiceData.buyerData[0].buyerPostalZone, '00000') },
                            'cac:Country': {
                                'cbc:IdentificationCode': { _text: sanitizeValue(invoiceData.buyerData[0].buyerCountryCode, 'DE') }
                            }
                        },
                        'cac:PartyLegalEntity': {
                            'cbc:RegistrationName': { _text: sanitizeValue(invoiceData.buyerData[0].buyerRegistrationName, 'Alıcı firma bilinmiyor') }
                        }
                    }
                },

                // Ödeme Bilgileri
                'cac:PaymentMeans': {
                    'cbc:PaymentMeansCode': { _text: sanitizeValue(invoiceData.paymentInformation[0].paymentMeansCode, '58') },
                    'cac:PayeeFinancialAccount': {
                        'cbc:ID': { _text: cleanIBAN(invoiceData.paymentInformation[0].supplierIBAN) }
                    }
                },

                // Ödeme Koşulları
                'cac:PaymentTerms': {
                    'cbc:Note': { _text: 'Ödeme 30 gün içerisinde yapılmalıdır.' }
                },

                // Vergi Toplamları
                'cac:TaxTotal': {
                    'cbc:TaxAmount': {
                        _attributes: { currencyID: invoiceData.invoiceGeneral[0].invoiceCurrency },
                        _text: formatAmount(invoiceData.invoiceHeader[0].invoiceTaxAmount)
                    },
                    'cac:TaxSubtotal': {
                        'cbc:TaxableAmount': {
                            _attributes: { currencyID: invoiceData.invoiceGeneral[0].invoiceCurrency },
                            _text: formatAmount(invoiceData.invoiceHeader[0].invoiceTaxableAmount)
                        },
                        'cbc:TaxAmount': {
                            _attributes: { currencyID: invoiceData.invoiceGeneral[0].invoiceCurrency },
                            _text: formatAmount(invoiceData.invoiceHeader[0].invoiceTaxAmount)
                        },
                        'cac:TaxCategory': {
                            'cbc:ID': { _text: invoiceData.invoiceHeader[0].invoiceTaxCategory },
                            'cbc:Percent': { _text: formatAmount(invoiceData.invoiceHeader[0].invoiceTaxPercent) },
                            'cac:TaxScheme': { 'cbc:ID': { _text: 'VAT' } }
                        }
                    }
                },

                // Parasal Toplamlar
                'cac:LegalMonetaryTotal': {
                    'cbc:LineExtensionAmount': {
                        _attributes: { currencyID: invoiceData.invoiceGeneral[0].invoiceCurrency },
                        _text: formatAmount(invoiceData.invoiceHeader[0].lineExtensionsAmount)
                    },
                    'cbc:TaxExclusiveAmount': {
                        _attributes: { currencyID: invoiceData.invoiceGeneral[0].invoiceCurrency },
                        _text: formatAmount(invoiceData.invoiceHeader[0].taxExclusiveAmount)
                    },
                    'cbc:TaxInclusiveAmount': {
                        _attributes: { currencyID: invoiceData.invoiceGeneral[0].invoiceCurrency },
                        _text: formatAmount(invoiceData.invoiceHeader[0].taxInclusiveAmount)
                    },
                    'cbc:PayableAmount': {
                        _attributes: { currencyID: invoiceData.invoiceGeneral[0].invoiceCurrency },
                        _text: formatAmount(invoiceData.invoiceHeader[0].payableAmount)
                    }
                },

                // Fatura Kalemleri
                'cac:InvoiceLine': invoiceData.InvoiceLines.map(line => ({
                    'cbc:ID': { _text: line.lineNr.toString() },
                    'cbc:InvoicedQuantity': {
                        _attributes: { unitCode: 'EA' },
                        _text: line.lineQuantity.toString()
                    },
                    'cbc:LineExtensionAmount': {
                        _attributes: { currencyID: invoiceData.invoiceGeneral[0].invoiceCurrency },
                        _text: formatAmount(line.lineExtensionsAmount)
                    },
                    'cac:Item': {
                        'cbc:Description': { _text: line.itemDescription },
                        'cbc:Name': { _text: line.itemName },
                        'cac:SellersItemIdentification': {
                            'cbc:ID': { _text: line.itemCode }
                        },
                        'cac:ClassifiedTaxCategory': {
                            'cbc:ID': { _text: line.itemTaxCategory },
                            'cbc:Percent': { _text: formatAmount(line.itemTaxPercent) },
                            'cac:TaxScheme': { 'cbc:ID': { _text: 'VAT' } }
                        }
                    },
                    'cac:Price': {
                        'cbc:PriceAmount': {
                            _attributes: { currencyID: invoiceData.invoiceGeneral[0].invoiceCurrency },
                            _text: formatAmount(line.itemPrice)
                        }
                    }
                }))
            }
        };

        return js2xml(root, { compact: true, spaces: 4 });
    } catch (error) {
        console.error('XML conversion error:', error);
        throw new Error(`Failed to convert invoice to XML: ${error}`);
    }
};

// VAT ID formatını kontrol eden ve düzenleyen fonksiyon
export function ensureVATIDFormat(vatId) {
    if (!vatId) return 'DE123456789';

    let cleanVatId = vatId.replace(/[^A-Z0-9]/gi, '').toUpperCase();

    if (!cleanVatId.startsWith('DE')) {
        cleanVatId = 'DE' + cleanVatId;
    }

    return cleanVatId.length >= 11 ? cleanVatId : 'DE123456789';
}

export async function generatePDF(invoice) {
    try {
        // PDF dokümanı oluştur
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4',
            compress: true
        });

        // Font ayarları
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);

        // Başlık
        doc.setFontSize(20);
        doc.text('RECHNUNG', 105, 20, { align: 'center' });

        // Fatura bilgileri
        doc.setFontSize(10);
        doc.text([
            `Rechnungsnummer: ${invoice.invoiceGeneral[0].invoiceID || 'Bilinmiyor'}`,
            `Rechnungsdatum: ${formatDate(invoice.invoiceGeneral[0].issueDate)}`,
            `Währung: ${invoice.invoiceGeneral[0].invoiceCurrency || 'EUR'}`
        ], 15, 40);

        // Satıcı bilgileri
        doc.text('Verkäufer:', 15, 60);
        doc.text([
            invoice.supplierData[0].supplierName || 'Bilinmiyor',
            invoice.supplierData[0].supplierStreetName || 'Bilinmiyor',
            `${invoice.supplierData[0].supplierPostalZone || ''} ${invoice.supplierData[0].supplierCityName || ''}`,
            `USt-IdNr.: ${invoice.supplierData[0].supplierVATID || 'Bilinmiyor'}`
        ], 15, 65);

        // Alıcı bilgileri
        doc.text('Käufer:', 15, 90);
        doc.text([
            invoice.buyerData[0].buyerRegistrationName || 'Bilinmiyor',
            invoice.buyerData[0].buyerStreetName || 'Bilinmiyor',
            `${invoice.buyerData[0].buyerPostalZone || ''} ${invoice.buyerData[0].buyerCityName || ''}`,
            `USt-IdNr.: ${invoice.buyerData[0].buyerVATID || 'Bilinmiyor'}`
        ], 15, 95);

        // Tablo başlıkları
        const headers = [['Pos', 'Beschreibung', 'Menge', 'Einzelpreis', 'MwSt.', 'Gesamt']];

        // Tablo verileri
        const tableData = invoice.InvoiceLines.map((line, index) => [
            (index + 1).toString(),
            line.itemName,
            line.lineQuantity.toString(),
            formatCurrency(line.itemPrice),
            `${line.itemTaxPercent}%`,
            formatCurrency(line.lineExtensionsAmount)
        ]);

        // Tablo oluştur
        doc.autoTable({
            startY: 120,
            head: headers,
            body: tableData,
            theme: 'grid',
            styles: {
                fontSize: 8,
                cellPadding: 2
            },
            headStyles: {
                fillColor: [60, 60, 60],
                textColor: 255
            }
        });

        // Toplam tutarlar
        const finalY = doc.lastAutoTable.finalY + 10;
        doc.text([
            `Nettobetrag: ${formatCurrency(invoice.invoiceHeader[0].taxExclusiveAmount)}`,
            `MwSt.: ${formatCurrency(invoice.invoiceHeader[0].invoiceTaxAmount)}`,
            `Gesamtbetrag: ${formatCurrency(invoice.invoiceHeader[0].taxInclusiveAmount)}`
        ], 150, finalY, { align: 'right' });

        // Ödeme bilgileri
        doc.text([
            'Bankverbindung:',
            `IBAN: ${invoice.paymentInformation[0].supplierIBAN || 'Bilinmiyor'}`,
            'Zahlungsziel: 30 Tage'
        ], 15, finalY + 20);

        return doc;
    } catch (error) {
        console.error('PDF generation error:', error);
        throw new Error('PDF oluşturma hatası: ' + error);
    }
}

// Para birimi formatı
function formatCurrency(amount) {
    return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
    }).format(amount);
}

// Tarih formatı
function formatDate(dateString) {
    return new Date(dateString).toLocaleDateString('de-DE');
}

// PDF yeni sekmede açma fonksiyonu
export async function openPDFInNewTab(invoice) {
    try {
        const doc = await generatePDF(invoice);
        const pdfOutput = doc.output('blob');

        // PDF'yi yeni bir sekmede aç
        const url = URL.createObjectURL(pdfOutput);
        window.open(url, '_blank');

        return true;
    } catch (error) {
        console.error('PDF open error:', error);
        throw new Error('PDF açma hatası: ' + error);
    }
}

// PDF indirme fonksiyonu
export async function downloadPDF(invoice) {
    try {
        const doc = await generatePDF(invoice);
        const pdfOutput = doc.output('blob');

        // PDF'i indir
        const url = URL.createObjectURL(pdfOutput);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Rechnung_${invoice.invoiceGeneral[0].invoiceID || 'unknown'}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        return true;
    } catch (error) {
        console.error('PDF download error:', error);
        throw new Error('PDF indirme hatası: ' + error);
    }
}


// Yardımcı fonksiyonlar
function cleanIBAN(iban) {
    return (iban || '').replace(/\s+/g, '');
}

function formatAmount(amount) {
    if (typeof amount !== 'number') {
        amount = parseFloat(amount) || 0;
    }
    return amount.toFixed(2);
}