
import React, { useEffect, useState } from 'react';
import { Button, Popup, SelectBox, TextBox } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { parameterRequest } from '../../../../api/requests';

export const SupplierParametersRowUpdatePopup = ({visible, onHiding, refresh, supplierParameter}) => {
  const [parameter, setParameter] = useState(supplierParameter);
  const {t} = useTranslation();

  useEffect(() => {
    setParameter(supplierParameter);
  }, [visible]);

  const updateField = (key) => (value) => {
    setParameter(prev => ({
      ...prev,
      [key]: value
    }));
  };

  function onSaveClick(){

    parameterRequest(parameter,2).then((res)=>{
      if(res.resCode == 200){
        refresh();
        notify(t("successfull"),'success');
        onHiding();

      }else{
        notify(res.resMessage,'error');
      }
    });

  }

  function onCancelClick(){
    onHiding();
  }



  return (
    <Popup
    visible={visible}
    onHiding={onHiding}
    width={800}
    height={"auto"}
    maxHeight={"95%"}
    titleRender={()=>(
      <div className="flex flex-row justify-between py-2">

        <div className="flex flex-row">
            <Button icon="close" stylingMode="text" onClick={onHiding}></Button>
            <p className="ml-2 mt-0.5 justify-center font-bold text-lg">{supplierParameter.groupRef == 0 ? t("new") : supplierParameter.groupName }</p>
        </div>

        <div className='flex flex-row gap-x-2'>
            <Button text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
            <Button text={t("cancel")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={onCancelClick}/>
        </div>

      </div>
    )}
    contentRender={()=>
    (
      <div className='flex flex-col gap-y-3 p-2'>

        <div className='flex justify-between'>


        </div>
<div className='flex gap-4'>
            <div className='flex flex-col w-full gap-3'>
            <TextBox
            value={parameter?.arpCode}
            onValueChange={updateField('arpCode')}
            label={t("arpCode")}
          />

          <TextBox
            label={t("arpStreet")}
            value={parameter?.arpStreet}
            onValueChange={updateField('arpStreet')}
          />

          <TextBox
            value={parameter?.arpCity}
            onValueChange={updateField('arpCity')}
            label={t("arpCity")}
          />

          <TextBox
            value={parameter?.arpPostCode}
            onValueChange={updateField('arpPostCode')}
            label={t("arpPostCode")}
          />

          <TextBox
            value={parameter?.arpCountryCode}
            onValueChange={updateField('arpCountryCode')}
            label={t("arpCountryCode")}
          />

          <TextBox
            value={parameter?.arpCountry}
            onValueChange={updateField('arpCountry')}
            label={t("arpCountry")}
          />

          <TextBox
            value={parameter?.arpVatId}
            onValueChange={updateField('arpVatId')}
            label={t("arpVatId")}
          />
            </div>

            <div className='flex flex-col w-full gap-3'>
            <TextBox
            value={parameter?.arpIban}
            onValueChange={updateField('arpIban')}
            label={t("arpIban")}
          />

          <TextBox
            value={parameter?.leitwegId}
            onValueChange={updateField('leitwegId')}
            label={t("leitwegId")}
          />

          <TextBox
            value={parameter?.endpointId}
            onValueChange={updateField('endpointId')}
            label={t("endpointId")}
          />

            <TextBox
            value={parameter?.arpTaxNr}
            onValueChange={updateField('arpTaxNr')}
            label={t("arpTaxNr")}
          />

          <TextBox
            value={parameter?.tradingGroup}
            onValueChange={updateField('tradingGroup')}
            label={t("tradingGroup")}
          />

          <TextBox
            value={parameter?.arpEMail}
            onValueChange={updateField('arpEMail')}
            label={t("arpEMail")}
          />
            </div>

            <div className='flex flex-col w-full gap-3'>

          <TextBox
            value={parameter?.contactName}
            onValueChange={updateField('contactName')}
            label={t("contactName")}
          />

          <TextBox
            value={parameter?.contactPhone}
            onValueChange={updateField('contactPhone')}
            label={t("contactPhone")}
          />

          <TextBox
            value={parameter?.contactEMail}
            onValueChange={updateField('contactEMail')}
            label={t("contactEMail")}
          />

          <TextBox
            value={parameter?.registrationName}
            onValueChange={updateField('registrationName')}
            label={t("registrationName")}
          />

          <TextBox
            value={parameter?.registerNumber}
            onValueChange={updateField('registerNumber')}
            label={t("registerNumber")}
          />

          <TextBox
            value={parameter?.legalForm}
            onValueChange={updateField('legalForm')}
            label={t("legalForm")}
          />
          </div>


            </div>

    </div>
    )}>

    </Popup>
  );
};
