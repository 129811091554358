import React, { useState, useEffect, useCallback, useRef } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import './sales-user.scss';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes,
  FilterPanel,
  FilterBuilderPopup,
  DataGridRef
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { ContactStatus as ContactStatusType, CustomerModel, SalesManModel } from '../../types/crm-contact';
import { ContactStatus } from '../../components';
import { salesUserRequest} from '../../api/requests';
import { useTranslation } from 'react-i18next';
import { salesmanListStatusFromSession, salesmanListStatusToSession } from '../../api/sessions';
import { SalesmanEditPopup } from '../../components/library/sales-salesman/popup';
import { newSalesManModel } from '../../shared/constants';
import { appInfo } from '../../app-info';


export const SalesUsers = () => {
  const { t } = useTranslation();
  const filterStatusList = ["all", "active", "passive"];
  const [salesManActive, setsalesManActive] = useState("active");

  const [salesmanList, setSalesmanList] = useState([]);
  const [isPanelOpened, setPanelOpened] = useState(false);
  const gridRef = useRef<DataGridRef>(null);
  const [rowCount, setRowCount] = useState(0);

  const [salesman, setSalesman] = useState<SalesManModel>(newSalesManModel);

  useEffect(()=>{
    mainRefresh();
  },[])

  function mainRefresh(){

    salesUserRequest().then((res)=>{
      setSalesmanList(res.dataSet || []);
      gridRef.current?.instance().endCustomLoading();
      setTimeout(() => {
        updateFilter();
      }, 50);
    })
  }

  function updateFilter(){
    if(salesmanListStatusFromSession()=="0"){
      gridRef.current?.instance().filter(['salesManActive', '=', "0"]);
    }else if(salesmanListStatusFromSession()=="1"){
      gridRef.current?.instance().filter(['salesManActive', '=', "1"]);
    }else{
      gridRef.current?.instance().clearFilter()
    }

    if(salesmanListStatusFromSession()=="0"){
      setsalesManActive("passive")
    }else if(salesmanListStatusFromSession()=="1"){
      setsalesManActive("active")
    }else{
      setsalesManActive("all")
    }
  }

  const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {
    const { item: selected }: { item: FilterContactStatus } = e;
    if (selected == t("all")) {
      salesmanListStatusToSession("2")
      setsalesManActive("all");
      gridRef.current?.instance().clearFilter();
    } else if(selected==t('active')){
      gridRef.current?.instance().filter(['salesManActive', '=', "1"]);
      salesmanListStatusToSession("1")
      setsalesManActive("active");
    }else if(selected == t('passive')){
      gridRef.current?.instance().filter(['salesManActive', '=', "0"]);
      salesmanListStatusToSession("0")
      setsalesManActive("passive");
    }

  }, []);

  function getTranslatedStatusList() {
    return filterStatusList.map((status) => t(status));
  }

  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
  }, [isPanelOpened]);


  const onAddContactClick = useCallback(() => {
    setSalesman(newSalesManModel)
    setPanelOpened(true);

  }, []);

  const onRowClick = useCallback(({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
    setSalesman(data);
    setPanelOpened(true);

  }, []);

  const handleContentReady = (e) => {
    setRowCount(e.component.totalCount());
  };

  return (
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          className='grid theme-dependent'
          height='100%'
          focusedRowEnabled
          dataSource={salesmanList}
          keyExpr="salesManRef"
          onRowClick={onRowClick}
          onExporting={onExporting}
          noDataText={t("noData")}
          onContentReady={handleContentReady}
          allowColumnReordering
          showBorders
          ref={gridRef}>

          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />
          <LoadPanel showPane={false}  />
          <SearchPanel visible placeholder={t("customerSearch")}  />
          <ColumnChooser enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <Selection
            selectAllMode='allPages'
            showCheckBoxesMode='always'
            mode='multiple'
          />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Toolbar>
            <Item location='before' locateInMenu='auto'>
              <DropDownButton
                items={getTranslatedStatusList()}
                stylingMode='text'
                text={t(salesManActive)}
                dropDownOptions={dropDownOptions}
                useSelectMode
                onSelectionChanged={filterByStatus}
              />
            </Item>

            <Item name='searchPanel' location='before' />


            <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                text={t("refresh")}
                stylingMode='text'
                onClick={mainRefresh}
              />
            </Item>

            <Item name='columnChooserButton'  location='before' />

            <Item name='exportButton' location='before' />

            <Item location='center' locateInMenu='auto'>
            {rowCount} {t("records")}
            </Item>

            <Item location='after'>
              <Button
                icon='plus'
                text={t("new")}
                type='default'
                stylingMode='contained'
                visible={true}
                onClick={onAddContactClick}
              />
            </Item>


          </Toolbar>
          <Column
            dataField='salesManActive'
            caption={t("status")}
            hidingPriority={18}
            width={100}
            cellRender={ContactStatus}
          />

          <Column
            caption={t('salesManName')}
            dataField="salesManName"
            hidingPriority={19}
          />

          <Column
            caption={t('erpSlsManName1')}
            dataField="erpSlsManName1"
            visible={false}
            hidingPriority={17}
          />

          <Column
            caption={t('erpSlsManCode')}
            dataField="erpSlsManCode"
            hidingPriority={16}
          />

          <Column
            caption={t('erpFirmNr')}
            dataField="erpFirmNr"
            hidingPriority={15}
          />

          <Column
            caption={t('erpCashBoxCode')}
            dataField="erpCashBoxCode"
            visible={false}
          />

          <Column
            caption={t('salesManEMail')}
            dataField="salesManEMail"
            hidingPriority={13}
          />

          <Column
            caption={t('erpWHCentral')}
            dataField="erpWHCentral"
            visible={false}
          />

          <Column
            caption={t('erpWHReturn')}
            dataField="erpWHReturn"
            visible={false}
          />

          <Column
            caption={t('erpWHScrap')}
            dataField="erpWHScrap"
            visible={false}
          />

          <Column
            caption={t('erpDiscLimit')}
            dataField="erpDiscLimit"
            visible={false}
          />

          <Column
            caption={t('erpSalesOrderStatus')}
            dataField="erpSalesOrderStatus"
            visible={false}
          />

          <Column
            caption={t('rightSalesOrder')}
            dataField="rightSalesOrder"
            visible={false}
          />

          <Column
            caption={t('rightSalesDispatch')}
            dataField="rightSalesDispatch"
            visible={false}
          />

          <Column
            caption={t('rightSalesDispatchRt')}
            dataField="rightSalesDispatchRt"
            visible={false}
          />

          <Column
            caption={t('rightSalesInvoice')}
            dataField="rightSalesInvoice"
            visible={false}
          />

          <Column
            caption={t('rightSalesInvoiceRt')}
            dataField="rightSalesInvoiceRt"
            visible={false}
          />

          <Column
            caption={t('rightPaymentCash')}
            dataField="rightPaymentCash"
            visible={false}
          />

          <Column
            caption={t('rightGetPdf')}
            dataField="rightGetPdf"
            visible={false}
          />

          <Column
            caption={t('rightGetAllProducts')}
            dataField="rightGetAllProducts"
            visible={false}
          />

          <Column
            caption={t('routeActive')}
            dataField="routeActive"
            visible={false}

          />

          <Column
            caption={t('routeChangeDataAfterSave')}
            dataField="routeChangeDataAfterSave"
            visible={false}


          />

          <Column
            caption={t('routeSendDataAfterSave')}
            dataField="routeSendDataAfterSave"
            visible={false}
          />

        </DataGrid>
        <SalesmanEditPopup mainRefresh={mainRefresh} rowData={salesman} isPanelOpened={isPanelOpened} changePanelOpened={changePanelOpened} />

      </div>
    </div>
  );
};



type FilterContactStatus = ContactStatusType | 'active';


const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Customers.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Customers.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto' };
