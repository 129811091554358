import React, { useCallback, useEffect, useRef, useState } from "react";
import { getInvoice, OutgoingInvoicesRequest } from "../../../api/requests";
import notify from "devextreme/ui/notify";
import { Button, DataGrid, DropDownButton, LoadPanel, Popup,  } from "devextreme-react";
import { useTranslation } from "react-i18next";
import { Column, ColumnChooser, DataGridRef, DataGridTypes, Export,Toolbar, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, Scrolling, SearchPanel, Selection, Sorting } from "devextreme-react/cjs/data-grid";
import { appInfo } from "../../../app-info";
import "../../../theme/grid.scss";
import { convertJSONtoXML, downloadPDF, openPDFInNewTab } from "../../../utils/xmlConverter";
import axios from "axios";
import invoiceEmailBody from "../../../utils/invoiceMailDesign";
import ReactDOMServer from "react-dom/server";
import { formatDateDMY, formatPriceAmount } from "../../../utils/formats";

export const OutgoingInvoices = () => {
    const gridRef = useRef<DataGridRef>(null);
    const {t} = useTranslation();
    const [invoices, setInvoices] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [rowData, setRowData] = useState();

    function getList(){
        OutgoingInvoicesRequest().then((res)=>{
          if(res.resCode == 200){
            setInvoices(res.dataset || []);
          }else{
            notify(res.resMessage);
          }
        })
      }

      useEffect(()=>{
        getList();
      },[])

      const handleContentReady = (e) => {
        setRowCount(e.component.totalCount());
      };

      const handleXMLDownload = async (iRef) => {
        try {

            const response = await getInvoice(iRef);

            if (response && response.resCode == 200) {

            const xmlContent = await convertJSONtoXML(response); // XML'i oluştur
            const blob = new Blob([xmlContent], { type: 'text/xml' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `invoice_${response.invoiceGeneral[0].invoiceID}.xml`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
            } else {
                console.error(response.resMessage);
                notify(response.resMessage,"error")
            }


        } catch (error) {
            console.error('XML indirme hatası:', error);
            notify('XML indirme hatası',"error")
        }
    };

    const openPDFnewTab = async (iRef) => {
        try {
            const response = await getInvoice(iRef);

            if (response && response.resCode == 200) {
                await openPDFInNewTab(response);
            } else {
                console.error(response.resMessage);
                notify(response.resMessage,"error")
            }


        } catch (error) {
            console.error('PDF indirme hatası:', error);
            notify('PDF indirme hatası',"error")
        }
    };

    const downloadPDFfunc = async (iRef) => {
        try {
            const response = await getInvoice(iRef);

            if (response && response.resCode == 200) {
                await downloadPDF(response);
            } else {
                console.error(response.resMessage);
                notify(response.resMessage,"error")
            }


        } catch (error) {
            console.error('PDF indirme hatası:', error);
            notify('PDF indirme hatası',"error")
        }
    };

    function downloadSelectedPDF(){
      gridRef.current?.instance().beginCustomLoading(t("loading"));
      var i = 0;
      selectedRows.forEach((row, index) => {

        downloadPDFfunc(row["invoiceRef"]).then(()=>{
            i++;
            if(i==selectedRows.length){
                gridRef.current?.instance().endCustomLoading();
            }
        });
    });
    }

    function downloadSelectedXML(){
      gridRef.current?.instance().beginCustomLoading(t("loading"));
      var i = 0;
      selectedRows.forEach((row, index) => {

        handleXMLDownload(row["invoiceRef"]).then(()=>{
            i++;
            if(i==selectedRows.length){
                gridRef.current?.instance().endCustomLoading();
            }
        });
    });
    }

    function sendEmailSelected(){
      gridRef.current?.instance().beginCustomLoading(t("loading"));
      var i = 0;
      selectedRows.forEach((row, index) => {

        handleEmailSend(row["invoiceRef"]).then(()=>{
            i++;
            if(i==selectedRows.length){
                gridRef.current?.instance().endCustomLoading();
            }
        });
    });
    }

    const handleEmailSend = async (iRef) => {
      const response = await getInvoice(iRef);
      if (response && response.resCode == 200) {
        const emailBodyHTML = ReactDOMServer.renderToStaticMarkup(invoiceEmailBody(response));
        try {
            await axios.post("https://pay.mobiserver.de:4241/send-email", {
            to: "test@test.com",
            subject: response["invoiceGeneral"][0]["invoiceID"] +" Nolu Faturanız Hazır (TEST)",
            body: emailBodyHTML,
          });
          notify("Mail gönderimi başarılı!","success")
        } catch (error) {
          notify("Mail gönderim hatası: " + error, "error");
        }

    } else {
        console.error(response.resMessage);
        notify(response.resMessage,"error")
    }

      };

      const onSelectionChanged = (e) => {
        setSelectedRows(e.selectedRowsData);
    };



    return(<>
    <div className="view crm-contact-list">
    <div className="view-wrapper view-wrapper-contact-list list-page">
    <DataGrid
          className='grid theme-dependent'
          focusedRowEnabled
          dataSource={invoices}
          keyExpr="invoiceRef"
          noDataText={t("noData")}
          onContentReady={handleContentReady}
          onSelectionChanged={onSelectionChanged}
          allowColumnReordering
          allowColumnResizing
          showBorders
          ref={gridRef}
          >

          <LoadPanel />
          <SearchPanel width={250} visible placeholder={t("customerSearch")}  />
          <ColumnChooser title={t("columnChooser")} enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

          <Toolbar>

          <Item name='searchPanel' location='before'  />

          <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                hint={t("refresh")}
                stylingMode='text'
                onClick={getList}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before' />

            <Item location="before">
                <DropDownButton
                    visible={selectedRows.length>0}
                    text={"("+ selectedRows.length+") "+ t("transactions")}
                    icon="menu"
                    width={"175"}
                    items={[
                  //  { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                    { text: t("Download XML"), icon: 'codeblock', action: 'downloadXML' },
                    { text: t("Download PDF"), icon: 'pdffile', action: 'downloadPDF' },
                    { text: t("Send Email"), icon: 'email', action: 'sendEmail' },
                    ]}
                    onItemClick={(e) => {
                    if(e.itemData.action=="deleteSelected"){
                    //    deleteSelected();
                    }
                    if(e.itemData.action=="downloadPDF"){
                      downloadSelectedPDF();
                    }
                    if(e.itemData.action=="downloadXML"){
                      downloadSelectedXML();
                    }
                    if(e.itemData.action=="sendEmail"){
                      sendEmailSelected();
                    }
                    }}
                />
            </Item>

            <Item location='center' locateInMenu='auto'>
            {rowCount} {t("records")}
            </Item>



          </Toolbar>

          <Column dataField="eInvoiceStatus" width={170} hidingPriority={8} caption={t("status")} alignment="left"
                cellRender={(cell)=><>{ cell.value == 0 ? <div className="text-yellow-500">Gönderilmedi</div> : (cell.value == 1 ? <div className="text-blue-400">Cevap Bekleniyor</div> : <div className="text-green-600">Onaylandı</div>) }</>}/>
          <Column dataField="invoiceDate" hidingPriority={7} width={150} cellRender={(cell)=> formatDateDMY(cell.value)}/>
          <Column dataField="invoiceNo" hidingPriority={6} width={180} />
          <Column dataField="invoiceType" hidingPriority={5}/>
          <Column dataField="clientName" hidingPriority={11}/>
          <Column dataField="invoiceNetTotal" hidingPriority={3} cellRender={(cell)=>formatPriceAmount(cell.value)}/>
          <Column dataField="invoiceVatTotal"  hidingPriority={2} cellRender={(cell)=>formatPriceAmount(cell.value)}/>
          <Column dataField="invoiceGrossTotal" hidingPriority={1} cellRender={(cell)=>formatPriceAmount(cell.value)}/>
          <Column caption={t("actions")} hidingPriority={9} alignment='center' width={150} cellRender={({ data }) => (
                  <div className="flex gap-2 items-center">
                  <Button
                    hint="XML İndir"
                    icon='codeblock'
                    onClick={() => handleXMLDownload(data.invoiceRef)}
                  />
                  <Button
                    hint="PDF Görüntüle"
                    icon='pdffile'
                    onClick={() => openPDFnewTab(data.invoiceRef)}
                  />
                  <Button
                    hint="Eposta Gönder"

                    icon='email'
                    onClick={() => handleEmailSend(data.invoiceRef)}
                  />

                </div>
                )} />

        </DataGrid>



        </div>
        </div>
    </>);
}