import React from 'react';

import { TickerCard } from '../../library/ticker-card/TickerCard';
import { TickerProps } from '../../../types/analytics';
import { euroFormat, } from '../../../utils/formats';

export const LeadsTicker = ({title, value,subtitle,percent }: TickerProps) =>
  <TickerCard
    subtitle={subtitle}
    icon='datapie'
    tone='info'
    title={title}
    formatValue={euroFormat}
    value={value}
    percentage={percent}
    showPercentage
  />;
