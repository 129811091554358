import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';
import { Button, Popup } from 'devextreme-react';
import { CampaignInfoUpdate } from './infoUpdate';
import CampaignProductList from './productList';
import CampaignCustomerList from './customerList';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


const CampaignPopup = ({ rowData,isPanelOpened,changePanelOpened,mainRefresh,isNewRecord ,openNewRow}) => {

    const [campaignProductListRefresh, setCampaignProductListRefresh] = useState(0);
    const [readOnly, setReadOnly] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);
    const[expandTable, setExpandTable] = useState(false);



    useEffect(()=>{
        if(isPanelOpened){
            setSelectedTab(0);
            setExpandTable(false)
            if(isNewRecord){
                setReadOnly(false)
            }else{
                setReadOnly(true)
            }
        }
        if(rowData?.customerSelectionType == 1){
            setCustomersTab(false)
        }else{
            setCustomersTab(true)}
    },[isPanelOpened]);

    const readOnlyTrue = useCallback(() => {
        setReadOnly(true);
    }, [readOnly]);

    const readOnlyFalse = useCallback(() => {
        setReadOnly(false);
    }, [readOnly]);

    const {t} = useTranslation();

    const [customersTab, setCustomersTab] = useState(true)


    return (

                    <Popup
                        key={rowData.campaignRef}
                        visible={isPanelOpened}
                        hideOnOutsideClick={false}
                        title={rowData.campaignName}
                        showCloseButton={true}
                        width={"95%"}
                        maxWidth={"1800px"}
                        height={"95%"}
                        onHiding={changePanelOpened}
                        titleRender={()=>(
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row">
                      <Button icon="close" stylingMode="text" onClick={changePanelOpened}></Button>
                      <p className="ml-2 mt-0 justify-center font-bold text-lg">{`${rowData?.campaignName}`}</p>
                      </div>

                    </div>
                  )}
                contentRender={() => (
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {!expandTable && (
                        <div style={{position: 'sticky', top: 0,zIndex: 1,paddingBottom: '10px'}}>
                        <CampaignInfoUpdate setCustomersTab={setCustomersTab} openNewRow={openNewRow} isPanelOpened={isPanelOpened} isNewRecord={isNewRecord} changePanelOpened={changePanelOpened} readOnly={readOnly} readOnlyTrue={readOnlyTrue} readOnlyFalse={readOnlyFalse} mainRefresh={mainRefresh} campaign={rowData} setCampaignProductListRefresh={setCampaignProductListRefresh}></CampaignInfoUpdate>
                        </div>
                    )}

                    <div style={{ flex: 1, overflowY: 'auto',marginTop: '-10px' }}>
                        <TabPanel
                            selectedIndex={selectedTab} // İlk sekme seçili
                            onSelectionChanged={(e) => {
                                const selectedIndex = e.component.option('selectedIndex');
                                if (typeof selectedIndex === 'number') {
                                    setSelectedTab(selectedIndex); // undefined kontrolü yapıyoruz
                                }
                            }}
                        >
                        <TabPanelItem title={t("products")} disabled={!readOnly}>
                            <CampaignProductList isPopupOpened={isPanelOpened}  expandTable={expandTable} setExpandTable={setExpandTable} readOnly={readOnly}  campaignRef={rowData.campaignRef} campaignProductListRefresh={campaignProductListRefresh}></CampaignProductList>
                        </TabPanelItem>
                        <TabPanelItem title={t("customers")} disabled={!readOnly || !customersTab}>
                            <CampaignCustomerList isPopupOpened={isPanelOpened} expandTable={expandTable} setExpandTable={setExpandTable} readOnly={readOnly}  campaignRef={rowData.campaignRef}/>
                        </TabPanelItem>

                    </TabPanel>
                    </div>
                </div>
                )}
                />
    );
};

export default CampaignPopup;















